.card-preview {
	background-color: var(--color-light-gray);
	padding: 1rem;
	box-sizing: content-box;
}

@media (width >= 992px) {
	.card-preview {
		padding: 2.5rem;
	}
}

.card-preview__card {
	background-color: #fff;
	box-shadow: 0 1.65rem 3.3rem rgba(0, 0, 0, 0.16);
	overflow: hidden;
	position: relative;
	margin-left: auto;
	margin-right: auto;
}

.card-preview__card::before {
	content: '';
	display: block;
	height: 0;
	padding-top: 141.43%;
}

.card-preview__card > iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 210mm;
	height: 297mm;
	transform-origin: 0 0;
	/* transform is applied with Javascript */
}

.card-preview__card--landscape::before {
	padding-top: 70.707%;
}

.card-preview__card--landscape > iframe {
	width: 297mm;
	height: 210mm;
}

.card-preview__card--fold-mid::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;

	background: url('../../assets/fold-light-gray.svg') center -1px no-repeat,
		var(--card-fold-mid-gradient);

	background-size: 18% 1.75%, auto;
}

.card-preview--large .card-preview__card--fold-mid::after {
	background-image: url('../../assets/fold-dark-gray.svg'),
		var(--card-fold-mid-gradient);
}

.card-preview__card--christmas-card::before {
	padding-top: 62.5%;
}

.card-preview__card--christmas-card > iframe {
	width: 800px;
	height: 500px;
}

.card-preview__button {
	margin: 2rem auto 0;
	display: block;
}

@media (width < 992px) {
	.card-preview__card {
		max-width: 10rem;
	}

	.card-preview__card--landscape,
	.card-preview__card--christmas-card {
		max-width: 14.14rem;
	}

	.card-preview__button {
		margin-top: 1rem;
	}
}

.card-preview--large {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	min-width: 100%;
	height: 100vh;
	box-sizing: border-box;
	overflow-x: scroll;
	background-color: rgba(0, 0, 0, 0.75);
}

.card-preview--large .card-preview__button {
	background-color: transparent;
	color: #fff;
}

.card-preview--large .card-preview__card {
	max-width: 40rem;
}
