.footer-menu {
	padding-bottom: 2.5rem;
	transition: border-color 100ms ease-out;
	border-bottom: 1px solid var(--color-thin-line);
}

.footer-menu a {
	text-decoration: none;
}

.footer-menu__list {
	list-style: none;
	padding-left: 0;
	display: flex;
}

.footer-menu__item {
	margin-right: 2.3rem;
}

.footer-menu__item a {
	font-size: 1.2rem;
	font-weight: 500;
	color: var(--color-blue);
}

@media (max-width: 730px) {
	.footer-menu__item {
		margin-right: 1.2rem;
	}
}

@media (max-width: 620px) {
	.footer-menu {
		padding-bottom: 1.6rem;
	}

	.footer-menu__list {
		flex-direction: column;
	}

	.footer-menu__item {
		margin-right: 0;
	}

	.footer-menu__item:not(:last-child) {
		margin-bottom: 0.8rem;
	}
}
