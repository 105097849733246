.video {
	background-color: #fff;
	position: relative;
	width: 100%;
	flex-shrink: 0;
}

.video::before {
	content: '';
	display: block;
	height: 0;
	padding-top: 75%;
}

.video--16-9::before {
	padding-top: 56.25%;
}

.video--4-5::before {
	padding-top: calc(5 / 4 * 100%);
}

.video iframe,
.video video,
.video__placeholder {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video video,
.video__placeholder {
	object-fit: cover;
}

.video__action {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

label.video__action,
label.video__action button {
	cursor: pointer;
}

.video__play {
	border: 0;
	width: 5.3rem;
	height: 3.4rem;
	overflow: hidden;
	text-indent: 150%;
	white-space: nowrap;
	background: url(../../assets/video-play.svg) no-repeat center;
	background-size: 5.3rem 3.4rem;
	transition: transform 1000ms cubic-bezier(0, 1, 0.2, 1);
}

label.video__action:hover .video__play {
	transform: scale(1.1);
}
