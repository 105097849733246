.page-intro {
	display: grid;
	background: var(--color-pale-blue)
		linear-gradient(to top, rgba(0, 67, 214, 0.03), rgba(0, 67, 214, 0) 5rem);
	min-height: 4rem;
}

.page-intro__breadcrumbs {
	grid-column: 1 / 3;
	grid-row: 1;
	z-index: 2;
	padding: 0.5rem var(--page-margin);
	background-color: var(--color-pale-blue);

	white-space: nowrap;
}

.page-intro__image {
	width: 100%;
	height: auto;
	display: block;
	position: relative;
}

@supports (object-fit: cover) {
	.page-intro--image-only > img,
	.page-intro--image-only > picture > img {
		max-height: 45vh;
		min-height: 8rem;
		object-fit: cover;
	}
}

.page-intro--christmas {
	color: var(--color-beige);
	background-color: var(--color-christmas-2023);
	position: relative;
}

.page-intro--christmas a {
	color: var(--color-beige);
}

.page-intro__image > img,
.page-intro__image > picture > img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@supports (object-fit: contain) {
	.page-intro__image--contain > img,
	.page-intro__image--contain > picture > img {
		object-fit: contain;
	}
}

.page-intro--christmas {
}

.page-intro--christmas .page-intro__breadcrumbs {
	background-color: transparent;
}

@media (width < 768px) {
	.page-intro {
		grid-template-columns: var(--page-margin) minmax(0, 1fr) var(--page-margin);
		grid-template-rows: min-content minmax(0, 1fr) auto;
	}

	.page-intro__image {
		grid-column: 1 / 4;
		grid-row: 2;
		height: 66vw;
	}

	.page-intro--small .page-intro__image {
		height: 30vw;
	}

	.page-intro__image--christmas {
		height: auto;
	}

	.page-intro__text {
		grid-column: 2;
		grid-row: 3;
		margin-top: 2rem;
		margin-bottom: 2rem;
		text-align: center;
	}

	.page-intro--small .page-intro__image--contain + .page-intro__text {
		margin-top: 0;
	}

	.page-intro--full-image .page-intro__breadcrumbs {
		grid-column: 1 / 4;
	}
}

@media (width < 575px) {
	.page-intro__image {
		height: 50vw;
	}

	.page-intro__text {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}

	.page-intro__image--christmas {
		height: auto;
	}
}

@media (width >= 768px) {
	.page-intro {
		--calc-margin: max(
			var(--page-margin),
			calc((100vw - var(--page-width)) / 2 + var(--page-margin))
		);

		grid-template-columns:
			var(--calc-margin)
			minmax(0, 1fr)
			minmax(0, 1fr)
			var(--calc-margin);
		grid-template-rows: auto minmax(0, 1fr) calc(var(--page-overlap-y) * -1);
	}

	.page-intro:not(:empty):not(.page-intro--empty),
	.page-intro:not(.page-intro--empty) {
		min-height: 40vmax;
	}

	.page-intro--small:not(:empty):not(.page-intro--empty),
	.page-intro--small:not(.page-intro--empty) {
		min-height: 25vmax;
	}

	.page-intro__text {
		grid-column: 2 / 3;
		grid-row: 2;
		align-self: center;
		margin-right: var(--page-margin);
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.page-intro__breadcrumbs {
		padding-right: 0.5rem;
	}

	.page-intro__image {
		grid-row: 1 / 4;
		grid-column: 3 / 5;
	}

	/**
	 * Full image
	 */

	.page-intro--full-image {
	}

	.page-intro--full-image .page-intro__image {
		background-color: #000;
		grid-column: 1 / 5;
		grid-row: 2 / 4;
	}

	.page-intro--full-image .page-intro__image > img,
	.page-intro--full-image .page-intro__image > picture > img {
		opacity: 0.7;
	}

	.page-intro--full-image .page-intro__text {
		z-index: 2;
		color: #fff;
	}

	.page-intro--full-image .page-intro__breadcrumbs {
		grid-column: 1 / 5;
	}

	/**
	 * Only image
	 */

	.page-intro--image-only {
	}

	.page-intro--image-only .page-intro__image {
		grid-column: 1 / 5;
		grid-row: 2 / 4;
	}

	/**
	 * Text only
	 */

	.page-intro--text-only,
	.page-intro--text-only:not(:empty):not(.page-intro--empty) {
		/* More specific selector to overrice preceding :not(:empty) */
		height: 22vmax;
		min-height: 18rem;
	}

	.page-intro--text-only .page-intro__image {
		display: none;
	}

	.page-intro--text-only .page-intro__text {
		grid-column: 2 / 4;
		max-width: 38rem;
		justify-self: center;
		text-align: center;
		margin-right: 0;
	}
}
