.press-mention {
	display: flex;
	align-items: center;
	border: 1px solid var(--color-thin-line);
}

.press-mention--image-first {
	flex-direction: row-reverse;
}

.press-mention:not(:last-child) {
	margin-bottom: 1rem;
}

a.press-mention {
	color: inherit;
	text-decoration: none;
}

.press-mention__content {
	flex: 1;
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.press-mention__heading {
	flex: 1;
	padding: 1rem;
}

.press-mention__content .press-mention__heading:not(:last-child) {
	margin-bottom: 0.75rem;
}

.press-mention__image {
	width: 100%;
	max-width: 6rem;
}

.press-mention__image > img {
	display: block;
	max-width: 100%;
}

.press-mention--large {
	padding: var(--page-margin);
}

@media (width > 768px) {
	.press-mention--large {
		padding: 1rem;
	}
}

.press-mention--large .press-mention__content,
.press-mention--large .press-mention__heading {
	padding: 0;
	margin-right: 1rem;
}

.press-mention--large
	.press-mention__content
	> .press-mention--large
	.press-mention__heading {
	margin-right: 0;
}
