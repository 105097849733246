.icon-list {
	list-style: none;
	--font-size: 0.85rem;
	font-size: var(--font-size);
}

.icon-list .icon-list__item {
	display: grid;
	--grid-gutter: 0.5rem;
	column-gap: var(--grid-gutter);
	grid-template-columns: auto minmax(0, 1fr);
	margin-left: 0;
}

.icon-list__heading {
	font-size: var(--font-size);
}

.icon-list__heading:not(:last-child) {
	margin-bottom: 0;
}

.icon-list svg {
	margin-top: 0.05rem;
}

@media (max-width: 575px) {
	.icon-list {
		--font-size: 0.75rem;
	}

	.icon-list svg {
		margin-top: -0.05rem;
	}
}
