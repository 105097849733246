.step {
	border: 1px solid var(--color-thin-line);
	border-radius: var(--card-border-radius);
}

.step__indicator {
	margin-left: 1rem;
}

.step__header {
	display: grid;
	grid-template-columns: auto minmax(0, 1fr) auto;
	align-items: center;
	padding-right: 1rem;
	min-height: 4.5rem;
}

.step__heading {
	font-size: 1.1rem;
	padding: 1rem;
	font-weight: 600;
}

.step__actions {
	color: var(--input-placeholder-color);
	font-size: 0.85rem;
	display: grid;
}

.step__actions > * {
	grid-area: 1 / 1;
	text-align: right;
}

.step__body {
	padding: 1rem;
}

.step__body:not(:first-child) {
	border-top: 1px solid var(--color-thin-line);
}

.step__body--flush {
	padding: 0;
}

.step__buttons {
	padding: 1rem;
	border-top: 1px solid var(--color-thin-line);
	text-align: center;
	background-color: var(--color-light-blue);
}

.step__buttons-message {
	text-align: center;
}
