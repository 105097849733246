.campaign-header {
	padding-top: 1.1rem;
	margin-bottom: 1.6rem;
}

.campaign-header__container {
	display: flex;
	align-items: center;
	column-gap: 0.25rem;
}

.campaign-header__logo {
	display: flex;
	justify-content: center;
}

.campaign-header__campaign-logo {
	width: 3rem;
	height: 3rem;
}

@media (max-width: 767px) {
	.campaign-header {
		padding-top: 1.1rem;
		margin-bottom: 0.4rem;
	}

	.campaign-header__logo {
		margin-left: 0;
		margin-right: 0;
	}
}
