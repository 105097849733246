.key-figure {
	padding: 1rem 1.3rem 1.1rem;
	border-left: 1px solid rgba(0, 0, 0, 0.16);
}

.key-figure h2 {
	font-size: 2rem;
}

.key-figure h2:not(:last-child) {
	margin-bottom: 0.15rem;
}

.key-figure p {
	font-size: 0.8rem;
}
