/**
 * Downloadable assets that the user can get as part of a package.
 */

.package-asset {
}

.package-asset__content {
	padding: calc(var(--page-margin) * 1.5);
}

.package-asset__image {
	padding: calc(var(--page-margin) * 1.5);
	background-color: var(--color-light-gray);

	display: flex;
	align-items: center;
	justify-content: center;
}

.package-asset__video {
	background-color: #333;
	position: relative;
}

@media (width >= 768px) {
	.package-asset {
		display: flex;
	}

	.package-asset__content {
		width: 55%;
	}

	.package-asset__image {
		order: 2;
		width: 45%;
	}

	.package-asset__video {
		width: 66%;
		order: 2;
	}
}

/**
 * Gallery
 */

.package-asset--gallery {
	display: block;
}

.package-asset__gallery {
	padding: calc(var(--page-margin) * 1.5);
	padding-top: 0;

	column-count: 3;
	column-gap: 1.5rem;
}

.package-asset__gallery-image {
	margin-bottom: 1.5rem;
}

.package-asset__gallery-image > img {
	display: block;
	width: 100%;
	height: auto;
}

@media (width < 576px) {
	.package-asset__gallery {
		column-count: 2;
		column-gap: 1rem;
	}

	.package-asset__gallery-image {
		margin-bottom: 1rem;
	}
}

@media (width < 400px) {
	.package-asset__gallery {
		column-count: 2;
		column-gap: 0.75rem;
	}

	.package-asset__gallery-image {
		margin-bottom: 0.75rem;
	}
}
