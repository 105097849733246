/**
 * A number of buttons next to each other, for spacing purposes.
 */

.button-set {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: -0.3rem;
}

.button-set--vertical {
	flex-direction: column;
}

.button-set--center {
	justify-content: center;
}

.button-set--spaced {
	justify-content: space-between;
}

.button-set__button {
	margin-bottom: 0.3rem;
	margin-right: 0.3rem;
}

.button-set__description {
	color: var(--color-gray);
	line-height: 1.25;
	align-self: center;
	margin-bottom: 0.3rem;
	margin-right: 0.3rem;
}

.button-set--increased-space {
	margin-bottom: -0.75rem;
}

.button-set--increased-space .button-set__button,
.button-set--increased-space .button-set__description {
	margin-bottom: 0.75rem;
	margin-right: 0.75rem;
}

@media (width < 375px) {
	.button-set--small-on-mobile .button {
		font-size: 0.8rem;
		padding: 0.35rem 0.8rem 0.4rem;
	}
}
