.progress-bar {
	margin-bottom: 2.2rem;
}

.progress-bar__numbers {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	flex-wrap: wrap;
	column-gap: 1rem;
}

.progress-bar__numbers-current {
	white-space: nowrap;
}

.progress-bar__numbers-current:not(:last-child) {
	margin: 0;
}

.progress-bar__numbers-target {
	font-size: 1rem;
	white-space: nowrap;
}

.progress-bar__bar {
	width: 100%;
	height: 1.2rem;
	background-color: var(--color-white);
	margin-top: 0.5rem;
}

.progress-bar__progress {
	height: 100%;
	background-color: var(--color-blue);
}
