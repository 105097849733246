/**
 * Header
 *
 * The page header, with menu button, logo and shortcut menu.
 * For the styles of the full menu, see navigation.css and footer-menu.css.
 */

.header {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;

	background-color: #fff;
	box-shadow: 0 1px 0 var(--color-thin-line);

	font-size: 0.85rem;

	--scale-transition-duration: 250ms;
	--scale-transition-timing-function: ease-out;
}

.js .header:not(.header--tab-activated) :focus {
	outline: 0;
}

/* Make room for admin bar */
body.admin-bar .header {
	top: 32px;
}
@media screen and (width <= 782px) {
	body.admin-bar .header {
		top: 46px;
	}
}

.header > .page-wrap {
	position: relative;
}

.header__wrap {
	position: relative;
	z-index: 50;
	height: var(--header-height);
	background-color: #fff;

	padding-left: var(--page-margin);
	padding-right: var(--page-margin);

	display: grid;
	grid-template-columns: minmax(0, 1fr) auto auto;
	align-items: center;

	transition-property: margin-top, height;
	transition-duration: var(--scale-transition-duration);
	transition-timing-function: var(--scale-transition-timing-function);
}

.header__logo {
	display: inline-block;
	width: var(--header-logo-width);
	height: var(--header-logo-height);
	position: relative;

	transition-property: transform, width, height;
	transition-duration: var(--scale-transition-duration);
	transition-timing-function: var(--scale-transition-timing-function);
	transform: scale(1);
}

.header__logo > img {
	width: 100%;
	height: 100%;
}

@media (width < 768px) {
	body {
		--header-height: 2.6rem;
		--header-logo-width: 8.9rem; /* 178px */
		--header-logo-height: 1.7rem; /* 34px */
	}
}

@media (width < 576px) {
	body {
		--header-height: 2.3rem;
		--header-logo-width: 6.65rem; /* 133px */
		--header-logo-height: 1.25rem; /* 25px */
	}
}

.header__menu-button {
	appearance: none;
	border: 0;
	padding: 0;
	margin: 0;
	background: transparent;
	margin-left: 1.5rem;
	cursor: pointer;
}

.header__menu-button svg {
	display: block;
}

.header__shortcuts {
	justify-content: flex-end;
	display: flex;
	align-items: baseline;
	gap: 1.5rem;
}

.header__shortcut {
	color: var(--color-blue);
	text-decoration: none;
	white-space: nowrap;
	line-height: 1.2;
}

.header__shortcut:not(:last-child) {
	/* Desired margin, minus padding on both sides */
	margin-left: calc(1.7rem - (0.8 * 2));
}

.header__shortcut--emphasized {
	/* Mirrors style of .button--filled */
	color: var(--color-background);
	background-color: var(--color-blue);
	border: 1px solid var(--color-blue);
	border-radius: 10em;
	padding: 0.4rem 0.8rem 0.3rem;

	transition-property: color, border-color, background-color;
	transition-duration: 100ms, 100ms, 150ms;
	transition-timing-function: ease-out;
}

a.header__shortcut--emphasized:hover,
a.header__shortcut--emphasized:active {
	color: var(--color-background);
	border-color: #164dad;
	background-color: #164dad;
}

.js.tabbed a.header__shortcut--emphasized:focus {
	outline: 0;
	box-shadow: var(--input-prominent-focus-inner) 0 0 0 0.05rem,
		var(--input-prominent-focus) 0 0 0 0.2rem;
}

/**
 * Mobile adjustents
 */

@media (width < 576px) {
	.header__menu-button {
		margin-left: 1rem;
	}
}

@media (width < 1024px) {
	.header__shortcut--hide-mobile {
		display: none;
	}
}

/**
 * Hover and active effects
 */

.header__breadcrumb:not(.header__breadcrumb--active):hover,
.header__shortcut:not(.header__shortcut--emphasized):hover {
	text-decoration: underline;
}

.header__breadcrumb:not(.header__breadcrumb--active):active,
.header__shortcut:active {
	color: var(--color-link--active);
}

/**
 * Modifier for disabling transitions
 */

.header--no-transitions,
.header--no-transitions * {
	transition-duration: 0ms !important;
}

@media (width < 576px) {
	.header__wrap {
		/*height: 1.75rem;*/
	}

	.header__logo {
		margin-top: 0.1rem;
	}

	.header__shortcut {
		font-size: 0.7rem;
		padding: 0.2rem 0.5rem;
	}

	.header__menu-button.symbol-button svg {
		margin-right: 0;
	}
}
