.section-navigation {
	color: #fff;
	background-color: #163f88;
	font-size: 0.85rem;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	position: relative;
	z-index: 50;
	padding-left: var(--page-margin);
	padding-right: var(--page-margin);
}

.section-navigation a {
	text-decoration: none;
}

.section-navigation__list {
	border-left: 1px solid rgba(255, 255, 255, 0.17);
	display: flex;
}

.section-navigation__item {
	color: inherit;
	display: inline-block;
	padding: 0.65rem 1.1rem;
	margin-right: 1px;
	box-shadow: 1px 0 0 rgba(255, 255, 255, 0.17);
	white-space: nowrap;
}

.section-navigation__item:hover {
	background-color: #003798;
}

.section-navigation__item--active,
.section-navigation__item--active:hover,
.section-navigation__item:active {
	color: rgba(255, 255, 255, 0.6);
	background-color: #123471;
}

@media (width < 576px) {
	.section-navigation {
		overflow-x: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
	}

	.section-navigation__list {
		display: flex;
		flex-wrap: nowrap;
		width: fit-content;
		padding-right: var(--page-margin);
	}
}
