.product {
	display: flex;
}

.product__image img {
	display: block;
	max-width: 100%;
	height: auto;
}

@media (width >= 992px) {
	.product__details,
	.product__image {
		width: 50%;
	}

	.product--wide-details .product__details {
		width: 63%;
	}

	.product--wide-details .product__image {
		width: 37%;
	}

	.product--wide-details .product__details {
		min-height: 25rem;
	}
}

@media (width < 992px) {
	.product {
		flex-direction: column;
	}

	.product__image {
		margin: var(--page-margin);
		margin-bottom: 0;
	}

	.product__details {
		order: 2;
	}
}
