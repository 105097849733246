.image-grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 0.2rem;
	padding: 0.2rem;
	padding-bottom: 0;
}

.image-grid:not(:last-child) {
	margin-bottom: 1rem;
}

.image-grid__image {
	max-width: 100%;
}
