html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.qs-datepicker-container {
  color: #000;
  width: 15.625em;
  z-index: 9001;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fff;
  border: 1px solid gray;
  border-radius: .263922em;
  flex-direction: column;
  font-family: sans-serif;
  font-size: 1rem;
  display: flex;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 1.25em 1.25em -.9375em rgba(0, 0, 0, .3);
}

.qs-datepicker-container * {
  box-sizing: border-box;
}

.qs-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qs-hidden {
  display: none;
}

.qs-overlay {
  color: #fff;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  background: rgba(0, 0, 0, .75);
  flex-direction: column;
  padding: .5em;
  transition: opacity .3s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.qs-overlay.qs-hidden {
  opacity: 0;
  z-index: -1;
}

.qs-overlay .qs-overlay-year {
  color: #fff;
  width: 80%;
  text-align: center;
  background: none;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  margin: 0 auto;
  padding: .25em 0;
  font-size: .875em;
  display: block;
}

.qs-overlay .qs-overlay-year::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.qs-overlay .qs-close {
  cursor: pointer;
  padding: .5em;
  position: absolute;
  top: 0;
  right: 0;
}

.qs-overlay .qs-submit {
  cursor: pointer;
  background: rgba(128, 128, 128, .4);
  border: 1px solid #fff;
  border-radius: .263922em;
  margin: 0 auto auto;
  padding: .5em;
}

.qs-overlay .qs-submit.qs-disabled {
  color: gray;
  cursor: not-allowed;
  border-color: gray;
}

.qs-overlay .qs-overlay-month-container {
  flex-wrap: wrap;
  flex-grow: 1;
  display: flex;
}

.qs-overlay .qs-overlay-month {
  width: 33.3333%;
  cursor: pointer;
  opacity: .5;
  justify-content: center;
  align-items: center;
  transition: opacity .15s;
  display: flex;
}

.qs-overlay .qs-overlay-month.active, .qs-overlay .qs-overlay-month:hover {
  opacity: 1;
}

.qs-controls {
  width: 100%;
  -webkit-filter: blur();
  filter: blur();
  background: #d3d3d3;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  transition: filter .3s, -webkit-filter .3s;
  display: flex;
}

.qs-controls.qs-blur {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.qs-arrow {
  height: 1.5625em;
  width: 1.5625em;
  cursor: pointer;
  border-radius: .263922em;
  transition: background .15s;
  position: relative;
}

.qs-arrow:hover {
  background: rgba(0, 0, 0, .1);
}

.qs-arrow:hover.qs-left:after {
  border-right-color: #000;
}

.qs-arrow:hover.qs-right:after {
  border-left-color: #000;
}

.qs-arrow:after {
  content: "";
  border: .390625em solid rgba(0, 0, 0, 0);
  transition: border .2s;
  position: absolute;
  top: 50%;
}

.qs-arrow.qs-left:after {
  border-right-color: gray;
  right: 50%;
  transform: translate(25%, -50%);
}

.qs-arrow.qs-right:after {
  border-left-color: gray;
  left: 50%;
  transform: translate(-25%, -50%);
}

.qs-month-year {
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  font-weight: 700;
  transition: border .2s;
}

.qs-month-year:hover {
  border-bottom: 1px solid gray;
}

.qs-month-year:active:focus, .qs-month-year:focus {
  outline: none;
}

.qs-month {
  padding-right: .5ex;
}

.qs-year {
  padding-left: .5ex;
}

.qs-squares {
  -webkit-filter: blur();
  filter: blur();
  flex-wrap: wrap;
  padding: .3125em;
  transition: filter .3s, -webkit-filter .3s;
  display: flex;
}

.qs-squares.qs-blur {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.qs-square {
  width: 14.2857%;
  height: 1.5625em;
  cursor: pointer;
  border-radius: .263922em;
  justify-content: center;
  align-items: center;
  transition: background .1s;
  display: flex;
}

.qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
  background: orange;
}

.qs-current {
  font-weight: 700;
  text-decoration: underline;
}

.qs-active, .qs-range-end, .qs-range-start {
  background: #add8e6;
}

.qs-range-start:not(.qs-range-6) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.qs-range-middle {
  background: #d4ebf2;
}

.qs-range-middle:not(.qs-range-0):not(.qs-range-6) {
  border-radius: 0;
}

.qs-range-middle.qs-range-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.qs-range-end:not(.qs-range-0), .qs-range-middle.qs-range-6 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.qs-disabled, .qs-outside-current-month {
  opacity: .2;
}

.qs-disabled {
  cursor: not-allowed;
}

.qs-day, .qs-empty {
  cursor: default;
}

.qs-day {
  color: gray;
  font-weight: 700;
}

.qs-event {
  position: relative;
}

.qs-event:after {
  content: "";
  width: .46875em;
  height: .46875em;
  background: #07f;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
}

:root {
  --color-white: #fff;
  --color-black: #222;
  --color-blue: #1c3f8c;
  --color-red: #b31634;
  --color-light-green: #009375;
  --color-green: #178972;
  --color-pale-red: #fcebee;
  --color-beige: #f5ecdf;
  --color-darker-green: #178972;
  --color-pale-green: #f0f8f0;
  --color-gray: #989898;
  --color-active-blue: #007cd8;
  --color-pale-blue: #edf3fd;
  --color-light-blue: #edf3fd;
  --color-light-gray: #fcfcfc;
  --color-christmas: #006134;
  --color-beige: #f5ecdf;
  --color-legacy-red: #ac1a24;
  --color-orange: #ff523c;
  --color-christmas-2023: #d52822;
  --shadow-medium: 0 .15rem .8rem rgba(98, 108, 104, .08);
  --shadow-large: 0 .7rem 2.9rem rgba(20, 0, 0, .3);
  --color-text: var(--color-black);
  --color-background: #fff;
  --color-thin-line: rgba(0, 0, 0, .13);
  --color-thin-line-solid: #dedede;
  --color-link: var(--color-blue);
  --color-link--active: #014ac9;
  --header-height: 3.8rem;
  --header-logo-width: 10.05rem;
  --header-logo-height: 1.9rem;
  --page-width: 66rem;
  --page-margin: 2rem;
  --page-content-max-width: 34rem;
  --page-content-width: var(--page-content-max-width);
  --page-overlap-y: -1rem;
  --page-overlap-x: -1.5rem;
  --form-spacing-x: 1rem;
  --form-spacing-y: .9rem;
  --grid-gutter: 1rem;
  --grid-row-margin: .9rem;
  --paragraph-margin: 1rem;
  --card-border-radius: .25rem;
  --input-background-color: #fff;
  --input-background-color--hover: #fafcff;
  --input-border-radius: .25rem;
  --input-padding-x: .5rem;
  --input-padding-y: .5rem;
  --input-placeholder-color: #888;
  --input-border-color: var(--color-thin-line-solid);
  --input-border-color--focus: var(--color-thin-line-solid);
  --input-border-color--hover: var(--input-border-color--focus);
  --input-outline--focus: rgba(165, 217, 255, .4);
  --input-radio-checked: #014ac9;
  --input-prominent-focus: rgba(0, 124, 216, .533);
  --input-prominent-focus-inner: #fff;
  --input-border-color--invalid: var(--color-red);
  --input-border-color--invalid-hover: var(--color-red);
  --input-background-color--invalid: #fff;
  --input-background-color--invalid-hover: #fcebee;
  --input-border-color--valid: #a7c7bf;
  --input-border-color--valid-hover: var(--color-green);
  --input-background-color--valid: #fcfffc;
  --input-background-color--valid-hover: #fcfffc;
  --button-border-color: var(--color-blue);
  --image-align-center-extra-width: 4rem;
  --card-fold-mid-gradient: linear-gradient(90deg, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, .09) 49.95%, rgba(0, 0, 0, .12) 50%, rgba(0, 0, 0, .07) 50.25%, rgba(0, 0, 0, 0) 65%);
}

@media (max-width: 370px) {
  :root {
    --page-margin: .4rem;
    --page-overlap-x: -.3rem;
  }
}

@media (min-width: 370px) and (max-width: 575px) {
  :root {
    --page-margin: .6rem;
    --page-overlap-x: -.5rem;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  :root {
    --page-margin: 1rem;
    --page-overlap-x: -.75rem;
  }
}

@media (max-width: 1199px) {
  :root {
    --page-content-width: 28rem;
    --image-align-center-extra-width: 2rem;
  }
}

@media (max-width: 991px) {
  :root {
    --page-content-width: 24rem;
    --image-align-center-extra-width: 1rem;
  }
}

* {
  box-sizing: border-box;
}

:before, :after {
  box-sizing: inherit;
}

html {
  font-size: 20px;
}

html:not(.acceptance-test) {
  scroll-padding-top: 4.8rem;
  scroll-padding-top: calc(var(--header-height)  + 1rem);
  scroll-behavior: smooth;
}

@media screen and (min-width: 1800px) {
  html {
    font-size: 1.111vw;
  }
}

@media screen and (min-width: 2071px) {
  html {
    font-size: 23px;
  }
}

body {
  width: 100vw;
  margin: 0;
  overflow-x: hidden;
}

a {
  color: #1c3f8c;
  color: var(--color-link);
}

a:active {
  color: #014ac9;
  color: var(--color-link--active);
}

.no-js [data-hide-noscript] {
  display: none;
}

.js :focus {
  outline: 0;
}

.js.tabbed :focus {
  outline: .15rem solid rgba(0, 124, 216, .533);
  outline: .15rem solid var(--input-prominent-focus);
  outline-offset: .05rem;
  transition: none;
}

.u-block {
  display: block;
}

body {
  margin: 0;
  font-family: Franklin Gothic URW, sans-serif;
  line-height: 1.4;
}

h1, h2, h3, h4, h5, strong, b, th {
  font-weight: 600;
}

p, ul, ol, table, figure, blockquote, dl, h1, h2, h3, h4 {
  margin-top: 0;
  margin-bottom: 0;
}

p:not(:last-child), ul:not(:last-child), ol:not(:last-child), table:not(:last-child), figure:not(:last-child), blockquote:not(:last-child), dl:not(:last-child), .u-vertical-flow:not(:last-child) {
  margin-bottom: 1rem;
  margin-bottom: var(--paragraph-margin);
}

.u-vertical-flow--top:not(:first-child) {
  margin-top: 1rem;
  margin-top: var(--paragraph-margin);
}

.content .radio-set:not(:last-child) {
  margin-bottom: 1rem;
  margin-bottom: var(--paragraph-margin);
}

.content .page-section:not(:last-child) {
  margin-bottom: 2rem;
}

h1, .h1 {
  letter-spacing: -.02em;
  text-transform: none;
  font-size: 3.2rem;
  line-height: 1.05;
}

h1:not(:last-child), .h1:not(:last-child) {
  margin-bottom: .75rem;
}

h2, .h2 {
  letter-spacing: 0;
  text-transform: none;
  font-size: 2.5rem;
  line-height: 1.1;
}

h2:not(:first-child), .h2:not(:first-child) {
  margin-top: 2rem;
}

h2:not(:last-child), .h2:not(:last-child) {
  margin-bottom: .75rem;
}

h3, .h3 {
  letter-spacing: 0;
  text-transform: none;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.1;
}

h3:not(:first-child), .h3:not(:first-child) {
  margin-top: 2rem;
}

h3:not(:last-child), .h3:not(:last-child) {
  margin-bottom: .75rem;
}

h4, .h4 {
  letter-spacing: .03rem;
  text-transform: uppercase;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  font-size: .75rem;
  font-weight: 600;
}

h3 + h4, .h3 + .h4, h3 + .h4, .h3 + h4, .h3 + h3 {
  margin-top: 0;
}

.heading + h3, .heading + .h3 {
  margin-top: .75rem;
}

h1 a, h2 a, h3 a, .h1 a, .h2 a, .h3 a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

:is(h1, .h1, h2, .h2 h3, .h3, h4, .h4, h5, .h5, h6, .h6, .heading) + :is(h1, .h1, h2, .h2 h3, .h3, h4, .h4, h5, .h5, h6, .h6) {
  margin-top: 0;
}

@media (max-width: 575px) {
  h1, .h1 {
    font-size: 2rem;
  }

  h2, .h2 {
    font-size: 1.65rem;
  }

  h3, .h3 {
    font-size: 1.35rem;
  }
}

@media (max-width: 399px) {
  h1, .h1 {
    font-size: 1.6rem;
  }

  h2, .h2 {
    font-size: 1.35rem;
  }

  h3, .h3 {
    font-size: 1.2rem;
  }

  body {
    font-size: .9rem;
  }
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .13);
  border-top: 1px solid var(--color-thin-line);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.content a:not(:hover) {
  text-decoration: none;
}

.content ul:not(:first-child), .content ol:not(:first-child) {
  margin-top: 1rem;
  margin-top: var(--paragraph-margin);
}

.content li {
  margin-left: 1.5rem;
}

.content li + li {
  margin-top: 1rem;
  margin-top: var(--paragraph-margin);
}

.content ul ul {
  padding-left: 0;
}

blockquote {
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 1.5rem;
}

blockquote:before {
  content: "”";
  margin-bottom: -1.6rem;
  margin-left: -.15rem;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1;
  display: block;
}

blockquote.blockquote--mark-left {
  padding-left: 3.5rem;
  position: relative;
}

blockquote.blockquote--mark-left:before {
  position: absolute;
  left: 1.4rem;
}

blockquote footer {
  text-align: right;
  font-size: .8rem;
}

blockquote p + footer {
  margin-top: -.5rem;
}

blockquote.blockquote--large {
  font-size: 1.3rem;
}

figure {
  margin-left: 0;
  margin-right: 0;
}

figure > img, figure > noscript > img {
  max-width: 100%;
  height: auto;
  display: block;
}

figure > blockquote {
  margin-bottom: 0;
}

figcaption {
  color: #7e7e7e;
  font-size: .8rem;
}

figure figcaption {
  padding-top: .8rem;
}

@media (max-width: 575px) {
  figure figcaption {
    padding-top: .4rem;
  }
}

.content:after {
  content: "";
  clear: both;
  display: table;
}

.alignright {
  float: right;
}

.content .alignright {
  margin-right: -1.5rem;
  margin-right: var(--page-overlap-x);
  margin-top: 0;
  margin-bottom: 1rem;
  margin-left: 1rem;
  padding-left: 0;
  padding-right: 0;
}

figure.alignright img {
  width: 100%;
}

figure.alignright {
  max-width: 18rem;
}

.content figure.alignright figcaption {
  padding-right: 3rem;
  padding-right: calc(var(--page-overlap-x) * -2);
}

@media (max-width: 1199px) {
  figure.alignright {
    max-width: 11rem;
  }
}

@media (max-width: 575px) {
  figure.alignright {
    max-width: 40vw;
  }

  .content figure.alignright {
    margin-right: 0;
  }
}

.content > figure:not(:first-child), .content > .wp-block-image:not(:first-child), .content > .wp-block-embed:not(:first-child) {
  margin-top: 2rem;
}

.content > figure:not(:last-child), .content > .wp-block-image:not(:last-child), .content > .wp-block-embed:not(:last-child) {
  margin-bottom: 2rem;
}

@supports (max-width: unset) {
  .content > .wp-block-image {
    max-width: unset;
    padding-left: unset;
    padding-right: unset;
  }

  .content > .wp-block-image figure:not(.alignleft):not(.alignright) {
    margin-left: auto;
    margin-right: auto;
  }

  .content > .wp-block-image figure:not(.alignleft):not(.alignright):not(.aligncenter) {
    max-width: 34rem;
    max-width: var(--page-content-width);
  }
}

.content figure.aligncenter, .content .fullwidth {
  max-width: 100%;
  z-index: 30;
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.content figure.aligncenter:before, .content figure.aligncenter:after, .content .fullwidth:before, .content .fullwidth:after {
  content: "";
  height: 1.8rem;
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
}

.content figure.aligncenter:before, .content .fullwidth:before {
  background: linear-gradient(rgba(255, 255, 255, 0), #fff);
  top: -1.8rem;
}

.content figure.aligncenter:after, .content .fullwidth:after {
  background: linear-gradient(#fff, rgba(255, 255, 255, 0));
  bottom: -1.8rem;
}

.content figure.aligncenter figcaption {
  padding-bottom: .2rem;
}

.content figure.aligncenter img {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .content figure.aligncenter {
    max-width: 38rem;
    max-width: calc(var(--page-content-width)  + var(--image-align-center-extra-width));
  }
}

@media (max-width: 575px) {
  .content figure.aligncenter figcaption {
    padding-left: 2rem;
    padding-left: var(--page-margin);
  }
}

.content .fullwidth {
  max-width: 100%;
}

.content .fullwidth figcaption {
  padding-left: 2rem;
  padding-left: var(--page-margin);
}

@media (min-width: 768px) {
  .page-card--offset .content .fullwidth {
    max-width: none;
    width: calc(100% + 8rem);
    margin-left: -8rem;
  }

  .page-card--offset .content .fullwidth img {
    width: 100%;
  }
}

.content figure.figure--overlap > blockquote {
  width: 95%;
  margin-top: -5%;
  margin-left: auto;
}

@media (min-width: 576px) {
  .content figure.figure--overlap > img, .content figure.figure--overlap > noscript > img {
    width: 95%;
    margin-left: 0;
  }

  .content figure.figure--overlap > blockquote {
    width: 80%;
  }
}

@media (max-width: 575px) {
  .content figure.figure--overlap > blockquote {
    margin-right: -2rem;
    margin-right: calc(var(--page-margin) * -1);
  }
}

.wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper, .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper, .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper, .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper, .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper, .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper, .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper, .wp-block-embed.wp-embed-aspect-4-5 .wp-block-embed__wrapper, .wp-block-embed.is-type-video:not(.wp-has-aspect-ratio) .wp-block-embed__wrapper {
  position: relative;
}

.wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper:before, .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper:before, .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper:before, .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper:before, .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper:before, .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper:before, .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper:before, .wp-block-embed.wp-embed-aspect-4-5 .wp-block-embed__wrapper:before, .wp-block-embed.is-type-video:not(.wp-has-aspect-ratio) .wp-block-embed__wrapper:before {
  content: "";
  padding-top: 50%;
  display: block;
}

.wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper iframe, .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper iframe, .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper iframe, .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper iframe, .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper iframe, .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper iframe, .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper iframe, .wp-block-embed.wp-embed-aspect-4-5 .wp-block-embed__wrapper iframe, .wp-block-embed.is-type-video:not(.wp-has-aspect-ratio) .wp-block-embed__wrapper iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper:before {
  padding-top: 42.8571%;
}

.wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper:before {
  padding-top: 50%;
}

.wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper:before, .wp-block-embed.is-type-video:not(.wp-has-aspect-ratio) .wp-block-embed__wrapper:before {
  padding-top: 56.25%;
}

.wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper:before {
  padding-top: 75%;
}

.wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper:before {
  padding-top: 100%;
}

.wp-block-embed.wp-embed-aspect-9-6 .wp-block-embed__wrapper:before {
  padding-top: 66.6667%;
}

.wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper:before {
  padding-top: 200%;
}

.wp-block-embed.wp-embed-aspect-4-5 .wp-block-embed__wrapper:before {
  padding-top: 125%;
}

.wp-block-gallery {
  --columns-count: 1;
}

@media (min-width: 575px) {
  .wp-block-gallery {
    --columns-count: 3;
  }

  .wp-block-gallery.columns-2 {
    --columns-count: 2;
  }

  .wp-block-gallery.columns-3 {
    --columns-count: 3;
  }
}

.wp-block-gallery .blocks-gallery-grid {
  grid-template-columns: repeat(var(--columns-count), minmax(0, 1fr));
  column-gap: 1rem;
  padding-left: 0;
  display: grid;
}

.wp-block-gallery .blocks-gallery-grid li {
  margin-left: 0;
  display: block;
}

.wp-block-gallery .blocks-gallery-grid li + li {
  margin-top: 0;
}

.wp-block-gallery .blocks-gallery-item img {
  display: block;
}

@supports (object-fit: cover) {
  .wp-block-gallery .blocks-gallery-item img {
    object-fit: cover;
  }
}

@supports (aspect-ratio: 4 / 3) {
  .wp-block-gallery .blocks-gallery-item img {
    aspect-ratio: 4 / 3;
  }
}

@supports not (aspect-ratio: 4 / 3) {
  .wp-block-gallery .blocks-gallery-item img {
    height: 75%;
  }
}

form:not(.u-no-style) {
  font-size: .85rem;
}

input, select, textarea, .button {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fff;
  background-color: var(--input-background-color);
  border: 1px solid #dedede;
  border: 1px solid var(--input-border-color);
  vertical-align: middle;
  line-height: inherit;
  margin: 0;
  transition-property: color, box-shadow, border-color, background-color;
  transition-duration: .1s, .1s, .1s, .15s;
  transition-timing-function: ease-out;
  display: inline-block;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0);
}

input:focus, select:focus, textarea:focus, .button:focus {
  border-color: #dedede;
  border-color: var(--input-border-color--focus);
  outline: 0;
  box-shadow: 0 0 .3rem rgba(0, 0, 0, .04);
}

.js.tabbed input:focus, .js.tabbed select:focus, .js.tabbed textarea:focus, .js.tabbed .button:focus {
  box-shadow: 0 0 0 .05rem #fff, 0 0 0 .2rem rgba(0, 124, 216, .533);
  box-shadow: var(--input-prominent-focus-inner) 0 0 0 .05rem, var(--input-prominent-focus) 0 0 0 .2rem;
  outline: 0;
}

.js.tabbed input:focus, .js.tabbed select:focus, .js.tabbed textarea:focus {
  border-color: #dedede;
  border-color: var(--input-border-color);
}

.js.tabbed .button:focus, .js.tabbed .button--small:focus, .js.tabbed .button--outlined:focus {
  border-color: #1c3f8c;
  border-color: var(--button-border-color);
}

input:not(:disabled):hover, select:not(:disabled):hover, textarea:not(:disabled):hover, .button:not(:disabled):hover {
  background-color: #fafcff;
  background-color: var(--input-background-color--hover);
  border-color: #dedede;
  border-color: var(--input-border-color--hover);
}

.button {
  color: #1c3f8c;
  color: var(--button-border-color);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border-color: currentColor;
  border-radius: 5rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1.3;
  text-decoration: none;
  display: inline-block;
}

.button:disabled {
  cursor: default;
}

.button:not(:disabled):hover {
  color: #fff;
  background-color: #164dad;
  border-color: #164dad;
  box-shadow: 0 3px 16px rgba(0, 0, 0, .3);
}

.button:active {
  color: #fff;
  background-color: #123471;
  border-color: #123471;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0) !important;
}

a.button:hover, a.button:active {
  text-decoration: none;
}

button:focus {
  border-color: #123471;
}

.button--small {
  padding: .35rem .8rem;
  font-size: .85rem;
}

.button.button--filled, .button.button--primary, .button.button--primary-blue, .button.button--primary-color {
  color: #fff;
  box-shadow: 0 .15rem .8rem rgba(0, 0, 0, .16);
}

.button.button--filled:hover, .button.button--primary:hover, .button.button--primary-blue:hover, .button.button--primary-color:hover {
  color: #fff;
  box-shadow: 0 .15rem .8rem rgba(0, 0, 0, .2);
}

.button.button--filled, .button.button--primary-blue {
  border-color: #1c3f8c;
  border-color: var(--color-blue);
  background-color: #1c3f8c;
  background-color: var(--color-blue);
}

.button.button--filled:hover, .button.button--primary-blue:hover {
  background-color: #164dad;
  border-color: #164dad;
}

.button.button--filled:disabled, .button.button--filled:disabled:hover, .button.button--primary-blue:disabled, .button.button--primary-blue:disabled:hover {
  box-shadow: none;
  background-color: #c6c6c6;
  border-color: #c6c6c6;
}

.button.button--primary, .button.button--primary-blue {
  padding: .7rem 1.25rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.button.button--primary, .button.button--primary-color {
  border-color: #1c3f8c;
  border-color: var(--color-blue);
  background-color: #1c3f8c;
  background-color: var(--color-blue);
}

.button.button--primary:hover, .button.button--primary-color:hover {
  background-color: #164dad;
  border-color: #164dad;
}

.button.button--primary:active, .button.button--primary-color:active {
  background-color: #123471;
  border-color: #123471;
}

.button.button--filled:focus, .button.button--primary:focus, .button.button--primary-color:focus {
  border-color: rgba(0, 0, 0, .25);
  box-shadow: 0 .15rem .8rem rgba(0, 0, 0, .16);
}

.button.button--centered {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.button__symbol {
  vertical-align: -.1em;
  width: auto;
  height: .9rem;
}

.button__symbol:first-child {
  margin-right: .1rem;
}

.button__symbol:last-child {
  margin-left: .1rem;
}

.button.button--outlined {
  --button-border-color: currentColor;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
}

.button.button--outlined:hover {
  box-shadow: none;
  border-color: currentColor;
}

.button.button--outlined:focus {
  box-shadow: inset 0 0 0 1px;
}

.link {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font: inherit;
  text-align: inherit;
  color: #1c3f8c;
  color: var(--color-link);
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  display: inline-block;
}

.link:not([disabled]):hover {
  text-decoration: underline;
}

.link:not([disabled]):active {
  color: #014ac9;
  color: var(--color-link--active);
}

.link--edit:before {
  content: "";
  width: .9rem;
  height: .9rem;
  vertical-align: -.1rem;
  background-image: url("data:image/svg+xml;utf8,<svg width=\"32\" height=\"32\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 32 32\" style=\"width: 1.4rem; height: 1.4rem;\"><path d=\"M20.612 8.982a2.39 2.39 0 00-1.695.71l-9.225 9.224-.036.183-.638 3.209-.182.856.857-.182 3.208-.638.182-.037 9.225-9.224a2.379 2.379 0 000-3.39 2.39 2.39 0 00-1.696-.711zm0 1.112c.294 0 .59.134.875.419.567.567.567 1.182 0 1.75l-.42.4-1.732-1.731.402-.419c.285-.285.58-.42.875-.42zm-2.096 1.659l1.731 1.732-7.054 7.053a3.976 3.976 0 00-1.733-1.731l7.056-7.054zm-7.802 8.002a2.76 2.76 0 011.53 1.532l-1.914.382.384-1.914z\" fill=\"%231c3f8c\"></path></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.4rem 1.4rem;
  display: inline-block;
}

.link--danger, .link--danger:not([disabled]):active {
  color: #b31634;
  color: var(--color-red);
}

[type="color"], [type="date"], [type="datetime"], [type="datetime-local"], [type="email"], [type="month"], [type="number"], [type="password"], [type="search"], [type="tel"], [type="text"], [type="time"], [type="url"], [type="week"], input:not([type]), select, textarea {
  border-radius: .25rem;
  border-radius: var(--input-border-radius);
  padding: .5rem;
  padding: var(--input-padding-y) var(--input-padding-x);
  min-height: 2.1rem;
  width: 8rem;
}

textarea {
  resize: vertical;
}

select {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 46 128\"><path d=\"M16.744 61.121l5.986 5.987 5.988-5.987\" stroke=\"%23707070\" stroke-width=\"2\" fill=\"none\"/><path stroke=\"%23e5e5e5\" fill=\"none\" d=\"M.5-.5v129\"/></svg>");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 2.3rem 6.4rem;
  padding-right: 3rem;
}

select:hover, select:focus {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 46 128\"><path d=\"M16.744 61.121l5.986 5.987 5.988-5.987\" stroke=\"%23707070\" stroke-width=\"2\" fill=\"none\"/><path stroke=\"%23cedcf4\" fill=\"none\" d=\"M.5-.5v129\"/></svg>");
}

select::-ms-expand {
  display: none;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="checkbox"], input[type="radio"] {
  width: .85rem;
  height: .85rem;
  vertical-align: -.15rem;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: .25rem;
}

input[type="checkbox"]:checked, input[type="radio"]:checked {
  border-color: #1c3f8c;
  border-color: var(--color-blue);
}

input[type="radio"] {
  background-position: center;
  background-size: .35rem .35rem;
  border-radius: .85rem;
}

input[type="checkbox"] {
  border-radius: .15rem;
}

input[type="radio"]:not(:checked):hover {
  background-image: url("radio-circle-blue.6607852f.svg");
  background-position: center;
}

input[type="radio"]:checked, input[type="radio"]:checked:valid, input[type="radio"]:checked:invalid, input[type="radio"]:checked:valid:hover, input[type="radio"]:checked:invalid:hover {
  background-color: #014ac9;
  background-color: var(--input-radio-checked);
  background-image: url("radio-circle.9ffb5705.svg");
  background-position: center;
}

input[type="checkbox"]:checked {
  background-color: #fff;
  background-color: var(--input-background-color);
  background-image: url("checkmark.76557bfd.svg");
}

input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type="date"] {
  padding-right: .5rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  margin-left: 0;
  padding-left: 0;
}

input.u-show-validation, select.u-show-validation, textarea.u-show-validation {
  background-position: 100%;
  background-repeat: no-repeat;
}

input.u-show-validation::-ms-clear, select.u-show-validation::-ms-clear, textarea.u-show-validation::-ms-clear {
  display: none;
}

input.u-show-validation:invalid, select.u-show-validation:invalid, textarea.u-show-validation:invalid {
  background-color: #fff;
  background-color: var(--input-background-color--invalid);
  border-color: #b31634;
  border-color: var(--input-border-color--invalid);
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0);
}

input.u-show-validation:invalid:hover, select.u-show-validation:invalid:hover, textarea.u-show-validation:invalid:hover {
  background-color: #fcebee;
  background-color: var(--input-background-color--invalid-hover);
  border-color: #b31634;
  border-color: var(--input-border-color--invalid-hover);
}

input[type="color"].u-show-validation:invalid, input[type="datetime"].u-show-validation:invalid, input[type="datetime-local"].u-show-validation:invalid, input[type="email"].u-show-validation:invalid, input[type="month"].u-show-validation:invalid, input[type="number"].u-show-validation:invalid, input[type="password"].u-show-validation:invalid, input[type="search"].u-show-validation:invalid, input[type="tel"].u-show-validation:invalid, input[type="text"].u-show-validation:invalid, input[type="time"].u-show-validation:invalid, input[type="url"].u-show-validation:invalid, input[type="week"].u-show-validation:invalid, input:not([type]).u-show-validation:invalid {
  background-image: url("invalid.0a193052.svg");
  padding-right: 1.8rem;
}

input[type="checkbox"].u-show-validation:invalid {
  background-color: #fcebee;
  background-color: var(--input-background-color--invalid-hover);
  border-width: 2px;
}

input.u-show-validation:valid, select.u-show-validation:valid, textarea.u-show-validation:valid {
  background-color: #fcfffc;
  background-color: var(--input-background-color--valid);
  border-color: #a7c7bf;
  border-color: var(--input-border-color--valid);
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0);
}

input.u-show-validation:valid:hover, select.u-show-validation:valid:hover, textarea.u-show-validation:valid:hover {
  background-color: #fcfffc;
  background-color: var(--input-background-color--valid-hover);
  border-color: #178972;
  border-color: var(--input-border-color--valid-hover);
}

input[type="color"].u-show-validation:valid, input[type="datetime"].u-show-validation:valid, input[type="datetime-local"].u-show-validation:valid, input[type="email"].u-show-validation:valid, input[type="month"].u-show-validation:valid, input[type="number"].u-show-validation:valid, input[type="password"].u-show-validation:valid, input[type="search"].u-show-validation:valid, input[type="tel"].u-show-validation:valid, input[type="text"].u-show-validation:valid, input[type="time"].u-show-validation:valid, input[type="url"].u-show-validation:valid, input[type="week"].u-show-validation:valid, input:not([type]).u-show-validation:valid {
  background-image: url("valid.4d14cf02.svg");
  padding-right: 1.8rem;
}

fieldset {
  min-width: auto;
  border: 0;
  margin: 0;
  padding: 0;
}

fieldset:not(.u-no-style):not(:first-child) {
  margin-top: 1rem;
  margin-top: var(--fieldset-margin, 1rem);
}

fieldset:not(.u-no-style):not(:last-child) {
  margin-bottom: 1rem;
  margin-bottom: var(--fieldset-margin, 1rem);
}

@media (min-width: 768px) {
  fieldset:not(.u-no-style):not(:first-child) {
    margin-top: 2rem;
    margin-top: var(--fieldset-margin, 2rem);
  }

  fieldset:not(.u-no-style):not(:last-child) {
    margin-bottom: 2rem;
    margin-bottom: var(--fieldset-margin, 2rem);
  }
}

fieldset legend {
  margin: 0 0 1rem;
}

fieldset:not(.u-no-style) + fieldset:not(.u-no-style) {
  margin-top: 2rem;
  margin-top: var(--fieldset-margin, 2rem);
}

#order_review {
  margin-top: 2rem;
}

.grid__col > .input, .grid__col > [type="color"], .grid__col > [type="date"], .grid__col > [type="datetime"], .grid__col > [type="datetime-local"], .grid__col > [type="email"], .grid__col > [type="month"], .grid__col > [type="number"], .grid__col > [type="password"], .grid__col > [type="search"], .grid__col > [type="tel"], .grid__col > [type="text"], .grid__col > [type="time"], .grid__col > [type="url"], .grid__col > [type="week"], .grid__col > input:not([type]), .grid__col > select, .grid__col > textarea, .grid__col > .input > [type="color"], .grid__col > .input > [type="date"], .grid__col > .input > [type="datetime"], .grid__col > .input > [type="datetime-local"], .grid__col > .input > [type="email"], .grid__col > .input > [type="month"], .grid__col > .input > [type="number"], .grid__col > .input > [type="password"], .grid__col > .input > [type="search"], .grid__col > .input > [type="tel"], .grid__col > .input > [type="text"], .grid__col > .input > [type="time"], .grid__col > .input > [type="url"], .grid__col > .input > [type="week"], .grid__col > .input > input:not([type]) {
  width: 100%;
}

.header {
  z-index: 100;
  box-shadow: 0 1px rgba(0, 0, 0, .13);
  box-shadow: 0 1px 0 var(--color-thin-line);
  --scale-transition-duration: .25s;
  --scale-transition-timing-function: ease-out;
  background-color: #fff;
  font-size: .85rem;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

.js .header:not(.header--tab-activated) :focus {
  outline: 0;
}

body.admin-bar .header {
  top: 32px;
}

@media screen and (max-width: 782px) {
  body.admin-bar .header {
    top: 46px;
  }
}

.header > .page-wrap {
  position: relative;
}

.header__wrap {
  z-index: 50;
  height: 3.8rem;
  height: var(--header-height);
  padding-left: 2rem;
  padding-left: var(--page-margin);
  padding-right: 2rem;
  padding-right: var(--page-margin);
  transition-property: margin-top, height;
  transition-duration: var(--scale-transition-duration);
  transition-timing-function: var(--scale-transition-timing-function);
  background-color: #fff;
  grid-template-columns: minmax(0, 1fr) auto auto;
  align-items: center;
  display: grid;
  position: relative;
}

.header__logo {
  width: 10.05rem;
  width: var(--header-logo-width);
  height: 1.9rem;
  height: var(--header-logo-height);
  transition-property: transform, width, height;
  transition-duration: var(--scale-transition-duration);
  transition-timing-function: var(--scale-transition-timing-function);
  display: inline-block;
  position: relative;
  transform: scale(1);
}

.header__logo > img {
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  body {
    --header-height: 2.6rem;
    --header-logo-width: 8.9rem;
    --header-logo-height: 1.7rem;
  }
}

@media (max-width: 575px) {
  body {
    --header-height: 2.3rem;
    --header-logo-width: 6.65rem;
    --header-logo-height: 1.25rem;
  }
}

.header__menu-button {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0 0 0 1.5rem;
  padding: 0;
}

.header__menu-button svg {
  display: block;
}

.header__shortcuts {
  justify-content: flex-end;
  align-items: baseline;
  gap: 1.5rem;
  display: flex;
}

.header__shortcut {
  color: #1c3f8c;
  color: var(--color-blue);
  white-space: nowrap;
  line-height: 1.2;
  text-decoration: none;
}

.header__shortcut:not(:last-child) {
  margin-left: calc(1.7rem - 1.6);
}

.header__shortcut--emphasized {
  color: #fff;
  color: var(--color-background);
  background-color: #1c3f8c;
  background-color: var(--color-blue);
  border: 1px solid #1c3f8c;
  border: 1px solid var(--color-blue);
  border-radius: 10em;
  padding: .4rem .8rem .3rem;
  transition-property: color, border-color, background-color;
  transition-duration: .1s, .1s, .15s;
  transition-timing-function: ease-out;
}

a.header__shortcut--emphasized:hover, a.header__shortcut--emphasized:active {
  color: #fff;
  color: var(--color-background);
  background-color: #164dad;
  border-color: #164dad;
}

.js.tabbed a.header__shortcut--emphasized:focus {
  box-shadow: 0 0 0 .05rem #fff, 0 0 0 .2rem rgba(0, 124, 216, .533);
  box-shadow: var(--input-prominent-focus-inner) 0 0 0 .05rem, var(--input-prominent-focus) 0 0 0 .2rem;
  outline: 0;
}

@media (max-width: 575px) {
  .header__menu-button {
    margin-left: 1rem;
  }
}

@media (max-width: 1023px) {
  .header__shortcut--hide-mobile {
    display: none;
  }
}

.header__breadcrumb:not(.header__breadcrumb--active):hover, .header__shortcut:not(.header__shortcut--emphasized):hover {
  text-decoration: underline;
}

.header__breadcrumb:not(.header__breadcrumb--active):active, .header__shortcut:active {
  color: #014ac9;
  color: var(--color-link--active);
}

.header--no-transitions, .header--no-transitions * {
  transition-duration: 0s !important;
}

@media (max-width: 575px) {
  .header__logo {
    margin-top: .1rem;
  }

  .header__shortcut {
    padding: .2rem .5rem;
    font-size: .7rem;
  }

  .header__menu-button.symbol-button svg {
    margin-right: 0;
  }
}

.footer {
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  font-size: .75rem;
}

.footer form {
  font-size: inherit;
}

.footer__content {
  padding-top: 2.5rem;
  padding-bottom: 2.2rem;
}

.footer__content, .footer__container {
  border-bottom: 1px solid rgba(0, 0, 0, .13);
  border-bottom: 1px solid var(--color-thin-line);
  transition: border-color .1s ease-out;
}

.footer__content a {
  color: #222;
  color: var(--color-black);
  text-decoration: none;
}

.footer__content p {
  margin-bottom: .1rem;
}

.footer__heading {
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 500;
}

.footer__heading:not(:last-child) {
  margin-bottom: .4rem;
}

.footer__details {
  margin-bottom: 1rem;
}

.footer__social-media svg {
  vertical-align: -4px;
  display: inline-block;
}

.footer__newsletter {
  column-gap: 2rem;
  column-gap: var(--page-margin);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding-top: 2.8rem;
  padding-bottom: 2.5rem;
  display: grid;
}

.footer__logos {
  justify-content: space-between;
  align-items: center;
  padding-top: 2.6rem;
  display: flex;
}

.footer__input {
  margin-bottom: .5rem;
  display: flex;
}

.footer__input input {
  width: 100%;
  max-width: 19rem;
  margin-right: .65rem;
  font-size: .85rem;
}

@media (max-width: 971px) {
  .footer {
    padding-top: 1.6rem;
    padding-bottom: 2.1rem;
  }

  .footer__newsletter {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 1.1rem;
  }

  .footer__newsletter p {
    max-width: 30rem;
  }
}

@media (max-width: 575px) {
  .footer__content {
    padding-top: 1.9rem;
  }

  .footer__newsletter {
    padding-top: 1.8rem;
    padding-bottom: 1.6rem;
  }

  .footer__input input {
    margin-right: 8px;
  }

  .footer .button {
    font-size: .75rem;
  }

  .footer__logos {
    padding-top: 1.7rem;
  }

  .footer__logo-90-konto {
    width: 80px;
    height: auto;
  }

  .footer__logo-operation-smile {
    width: 150px;
    height: auto;
  }
}

@media (max-width: 375px) {
  .footer__input {
    flex-direction: column;
    row-gap: .5rem;
  }
}

.footer__quick-nav {
  border-bottom: 1px solid rgba(0, 0, 0, .13);
  border-bottom: 1px solid var(--color-thin-line);
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.2;
  display: flex;
}

.footer__quick-nav a {
  text-decoration: none;
}

.grid {
  margin-bottom: -.9rem;
  margin-bottom: calc(var(--grid-row-margin) * -1);
  flex-flow: wrap;
  justify-content: space-between;
  display: flex;
}

.grid--increase-row-spacing {
  --grid-row-margin: 2rem;
}

.grid--debug {
  background-color: #eee;
}

.grid--debug .grid__col {
  background-color: #ffc;
}

.grid--debug .grid__col .grid__col {
  background-color: #fcc;
}

.grid--debug .grid__col .grid__col .grid__col {
  background-color: #cff;
}

.grid__col {
  margin-bottom: .9rem;
  margin-bottom: var(--grid-row-margin);
  width: 100%;
}

.grid--flush:not(.grid__col) {
  margin-bottom: unset;
}

.grid--flush > * {
  --grid-row-margin: 0;
}

.grid--align-top {
  align-items: flex-start;
}

.grid--align-center {
  align-items: center;
}

.grid--align-bottom {
  align-items: flex-end;
}

.grid__col--align-top {
  align-self: flex-start;
}

.grid__col--align-center {
  align-self: center;
}

.grid__col--align-bottom {
  align-self: flex-end;
}

.grid__col--padded {
  padding-left: 1rem;
  padding-right: 1rem;
}

.grid__col--1-2 {
  width: calc(50% - .5rem);
  width: calc(50% - var(--grid-gutter) * .5);
}

.grid__col--2-2 {
  width: 100%;
}

.grid__col--1-3 {
  width: calc(33.3333% - .66667rem);
  width: calc(33.3333% - var(--grid-gutter) * .66667);
}

.grid__col--2-3 {
  width: calc(66.6667% - .33333rem);
  width: calc(66.6667% - var(--grid-gutter) * .33333);
}

.grid__col--3-3 {
  width: 100%;
}

.grid__col--1-4 {
  width: calc(25% - .75rem);
  width: calc(25% - var(--grid-gutter) * .75);
}

.grid__col--2-4 {
  width: calc(50% - .5rem);
  width: calc(50% - var(--grid-gutter) * .5);
}

.grid__col--3-4 {
  width: calc(75% - .25rem);
  width: calc(75% - var(--grid-gutter) * .25);
}

.grid__col--4-4 {
  width: 100%;
}

.grid__col--1-5 {
  width: calc(20% - .8rem);
  width: calc(20% - var(--grid-gutter) * .8);
}

.grid__col--2-5 {
  width: calc(40% - .6rem);
  width: calc(40% - var(--grid-gutter) * .6);
}

.grid__col--3-5 {
  width: calc(60% - .4rem);
  width: calc(60% - var(--grid-gutter) * .4);
}

.grid__col--4-5 {
  width: calc(80% - .2rem);
  width: calc(80% - var(--grid-gutter) * .2);
}

.grid__col--5-5 {
  width: 100%;
}

@media (min-width: 576px) {
  .grid__col--sm-1-2 {
    width: calc(50% - .5rem);
    width: calc(50% - var(--grid-gutter) * .5);
  }

  .grid__col--sm-2-2, .grid__col--sm-1-1 {
    width: 100%;
  }

  .grid__col--sm-1-3 {
    width: calc(33.3333% - .66667rem);
    width: calc(33.3333% - var(--grid-gutter) * .66667);
  }

  .grid__col--sm-2-3 {
    width: calc(66.6667% - .33333rem);
    width: calc(66.6667% - var(--grid-gutter) * .33333);
  }

  .grid__col--sm-3-3, .grid__col--sm-1-1 {
    width: 100%;
  }

  .grid__col--sm-1-4 {
    width: calc(25% - .75rem);
    width: calc(25% - var(--grid-gutter) * .75);
  }

  .grid__col--sm-2-4 {
    width: calc(50% - .5rem);
    width: calc(50% - var(--grid-gutter) * .5);
  }

  .grid__col--sm-3-4 {
    width: calc(75% - .25rem);
    width: calc(75% - var(--grid-gutter) * .25);
  }

  .grid__col--sm-4-4, .grid__col--sm-1-1 {
    width: 100%;
  }

  .grid__col--sm-1-5 {
    width: calc(20% - .8rem);
    width: calc(20% - var(--grid-gutter) * .8);
  }

  .grid__col--sm-2-5 {
    width: calc(40% - .6rem);
    width: calc(40% - var(--grid-gutter) * .6);
  }

  .grid__col--sm-3-5 {
    width: calc(60% - .4rem);
    width: calc(60% - var(--grid-gutter) * .4);
  }

  .grid__col--sm-4-5 {
    width: calc(80% - .2rem);
    width: calc(80% - var(--grid-gutter) * .2);
  }

  .grid__col--sm-5-5, .grid__col--sm-1-1 {
    width: 100%;
  }

  .grid__col--sm-align-top {
    align-self: flex-start;
  }

  .grid__col--sm-align-center {
    align-self: center;
  }

  .grid__col--sm-align-bottom {
    align-self: flex-end;
  }

  .grid__col--sm-padded {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid__col--sm-order-1 {
    order: 1;
  }

  .grid__col--sm-order-2 {
    order: 2;
  }

  .grid__col--sm-order-3 {
    order: 3;
  }

  .grid__col--sm-order-4 {
    order: 4;
  }

  .grid__col--sm-order-5 {
    order: 5;
  }

  .grid__col--sm-order-6 {
    order: 6;
  }

  .grid__col--sm-order-7 {
    order: 7;
  }

  .grid__col--sm-order-8 {
    order: 8;
  }

  .grid__col--sm-order-9 {
    order: 9;
  }

  .grid__col--sm-order-10 {
    order: 10;
  }
}

@media (min-width: 768px) {
  .grid__col--md-1-2 {
    width: calc(50% - .5rem);
    width: calc(50% - var(--grid-gutter) * .5);
  }

  .grid__col--md-2-2, .grid__col--md-1-1 {
    width: 100%;
  }

  .grid__col--md-1-3 {
    width: calc(33.3333% - .66667rem);
    width: calc(33.3333% - var(--grid-gutter) * .66667);
  }

  .grid__col--md-2-3 {
    width: calc(66.6667% - .33333rem);
    width: calc(66.6667% - var(--grid-gutter) * .33333);
  }

  .grid__col--md-3-3, .grid__col--md-1-1 {
    width: 100%;
  }

  .grid__col--md-1-4 {
    width: calc(25% - .75rem);
    width: calc(25% - var(--grid-gutter) * .75);
  }

  .grid__col--md-2-4 {
    width: calc(50% - .5rem);
    width: calc(50% - var(--grid-gutter) * .5);
  }

  .grid__col--md-3-4 {
    width: calc(75% - .25rem);
    width: calc(75% - var(--grid-gutter) * .25);
  }

  .grid__col--md-4-4, .grid__col--md-1-1 {
    width: 100%;
  }

  .grid__col--md-1-5 {
    width: calc(20% - .8rem);
    width: calc(20% - var(--grid-gutter) * .8);
  }

  .grid__col--md-2-5 {
    width: calc(40% - .6rem);
    width: calc(40% - var(--grid-gutter) * .6);
  }

  .grid__col--md-3-5 {
    width: calc(60% - .4rem);
    width: calc(60% - var(--grid-gutter) * .4);
  }

  .grid__col--md-4-5 {
    width: calc(80% - .2rem);
    width: calc(80% - var(--grid-gutter) * .2);
  }

  .grid__col--md-5-5, .grid__col--md-1-1 {
    width: 100%;
  }

  .grid__col--md-align-top {
    align-self: flex-start;
  }

  .grid__col--md-align-center {
    align-self: center;
  }

  .grid__col--md-align-bottom {
    align-self: flex-end;
  }

  .grid__col--md-padded {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid__col--md-order-1 {
    order: 1;
  }

  .grid__col--md-order-2 {
    order: 2;
  }

  .grid__col--md-order-3 {
    order: 3;
  }

  .grid__col--md-order-4 {
    order: 4;
  }

  .grid__col--md-order-5 {
    order: 5;
  }

  .grid__col--md-order-6 {
    order: 6;
  }

  .grid__col--md-order-7 {
    order: 7;
  }

  .grid__col--md-order-8 {
    order: 8;
  }

  .grid__col--md-order-9 {
    order: 9;
  }

  .grid__col--md-order-10 {
    order: 10;
  }
}

@media (min-width: 992px) {
  .grid__col--lg-1-2 {
    width: calc(50% - .5rem);
    width: calc(50% - var(--grid-gutter) * .5);
  }

  .grid__col--lg-2-2, .grid__col--lg-1-1 {
    width: 100%;
  }

  .grid__col--lg-1-3 {
    width: calc(33.3333% - .66667rem);
    width: calc(33.3333% - var(--grid-gutter) * .66667);
  }

  .grid__col--lg-2-3 {
    width: calc(66.6667% - .33333rem);
    width: calc(66.6667% - var(--grid-gutter) * .33333);
  }

  .grid__col--lg-3-3, .grid__col--lg-1-1 {
    width: 100%;
  }

  .grid__col--lg-1-4 {
    width: calc(25% - .75rem);
    width: calc(25% - var(--grid-gutter) * .75);
  }

  .grid__col--lg-2-4 {
    width: calc(50% - .5rem);
    width: calc(50% - var(--grid-gutter) * .5);
  }

  .grid__col--lg-3-4 {
    width: calc(75% - .25rem);
    width: calc(75% - var(--grid-gutter) * .25);
  }

  .grid__col--lg-4-4, .grid__col--lg-1-1 {
    width: 100%;
  }

  .grid__col--lg-1-5 {
    width: calc(20% - .8rem);
    width: calc(20% - var(--grid-gutter) * .8);
  }

  .grid__col--lg-2-5 {
    width: calc(40% - .6rem);
    width: calc(40% - var(--grid-gutter) * .6);
  }

  .grid__col--lg-3-5 {
    width: calc(60% - .4rem);
    width: calc(60% - var(--grid-gutter) * .4);
  }

  .grid__col--lg-4-5 {
    width: calc(80% - .2rem);
    width: calc(80% - var(--grid-gutter) * .2);
  }

  .grid__col--lg-5-5, .grid__col--lg-1-1 {
    width: 100%;
  }

  .grid__col--lg-align-top {
    align-self: flex-start;
  }

  .grid__col--lg-align-center {
    align-self: center;
  }

  .grid__col--lg-align-bottom {
    align-self: flex-end;
  }

  .grid__col--lg-padded {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid__col--lg-order-1 {
    order: 1;
  }

  .grid__col--lg-order-2 {
    order: 2;
  }

  .grid__col--lg-order-3 {
    order: 3;
  }

  .grid__col--lg-order-4 {
    order: 4;
  }

  .grid__col--lg-order-5 {
    order: 5;
  }

  .grid__col--lg-order-6 {
    order: 6;
  }

  .grid__col--lg-order-7 {
    order: 7;
  }

  .grid__col--lg-order-8 {
    order: 8;
  }

  .grid__col--lg-order-9 {
    order: 9;
  }

  .grid__col--lg-order-10 {
    order: 10;
  }
}

@media (min-width: 1200px) {
  .grid__col--xl-1-2 {
    width: calc(50% - .5rem);
    width: calc(50% - var(--grid-gutter) * .5);
  }

  .grid__col--xl-2-2, .grid__col--xl-1-1 {
    width: 100%;
  }

  .grid__col--xl-1-3 {
    width: calc(33.3333% - .66667rem);
    width: calc(33.3333% - var(--grid-gutter) * .66667);
  }

  .grid__col--xl-2-3 {
    width: calc(66.6667% - .33333rem);
    width: calc(66.6667% - var(--grid-gutter) * .33333);
  }

  .grid__col--xl-3-3, .grid__col--xl-1-1 {
    width: 100%;
  }

  .grid__col--xl-1-4 {
    width: calc(25% - .75rem);
    width: calc(25% - var(--grid-gutter) * .75);
  }

  .grid__col--xl-2-4 {
    width: calc(50% - .5rem);
    width: calc(50% - var(--grid-gutter) * .5);
  }

  .grid__col--xl-3-4 {
    width: calc(75% - .25rem);
    width: calc(75% - var(--grid-gutter) * .25);
  }

  .grid__col--xl-4-4, .grid__col--xl-1-1 {
    width: 100%;
  }

  .grid__col--xl-1-5 {
    width: calc(20% - .8rem);
    width: calc(20% - var(--grid-gutter) * .8);
  }

  .grid__col--xl-2-5 {
    width: calc(40% - .6rem);
    width: calc(40% - var(--grid-gutter) * .6);
  }

  .grid__col--xl-3-5 {
    width: calc(60% - .4rem);
    width: calc(60% - var(--grid-gutter) * .4);
  }

  .grid__col--xl-4-5 {
    width: calc(80% - .2rem);
    width: calc(80% - var(--grid-gutter) * .2);
  }

  .grid__col--xl-5-5, .grid__col--xl-1-1 {
    width: 100%;
  }

  .grid__col--xl-align-top {
    align-self: flex-start;
  }

  .grid__col--xl-align-center {
    align-self: center;
  }

  .grid__col--xl-align-bottom {
    align-self: flex-end;
  }

  .grid__col--xl-padded {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid__col--xl-order-1 {
    order: 1;
  }

  .grid__col--xl-order-2 {
    order: 2;
  }

  .grid__col--xl-order-3 {
    order: 3;
  }

  .grid__col--xl-order-4 {
    order: 4;
  }

  .grid__col--xl-order-5 {
    order: 5;
  }

  .grid__col--xl-order-6 {
    order: 6;
  }

  .grid__col--xl-order-7 {
    order: 7;
  }

  .grid__col--xl-order-8 {
    order: 8;
  }

  .grid__col--xl-order-9 {
    order: 9;
  }

  .grid__col--xl-order-10 {
    order: 10;
  }
}

@media (max-width: 575px) {
  .scrollable {
    margin-left: -2rem;
    margin-left: calc(var(--page-margin) * -1);
    margin-right: -2rem;
    margin-right: calc(var(--page-margin) * -1);
    padding-left: 2rem;
    padding-left: var(--page-margin);
    padding-right: 2rem;
    padding-right: var(--page-margin);
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-padding: 2rem;
    scroll-padding: var(--page-margin);
    padding-bottom: .65rem;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .scrollable__slider {
    width: -moz-fit-content;
    width: fit-content;
    flex-wrap: nowrap;
    display: flex;
  }

  .scrollable__item {
    vertical-align: top;
    margin-right: 1rem;
    margin-right: var(--grid-gutter);
    width: 66vw;
    min-width: 10.5rem;
    scroll-snap-align: start;
    flex-shrink: 0;
  }

  .scrollable__item--small {
    width: 50vw;
  }

  .scrollable__item--large {
    width: 82vw;
  }

  .scrollable__item > * {
    white-space: normal;
  }
}

.navigation {
  height: 100vh;
  z-index: 200;
  opacity: 0;
  background-color: rgba(0, 0, 0, .25);
  font-size: 1rem;
  transition: opacity .2s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.navigation__controls, .navigation__block {
  width: 26rem;
  max-width: calc(100vw - 2rem);
  max-width: calc(100vw - var(--page-margin));
}

.navigation__controls {
  z-index: 10;
  height: 3.8rem;
  height: var(--header-height);
  box-shadow: 0 1px rgba(0, 0, 0, .13);
  box-shadow: 0 1px 0 var(--color-thin-line);
  padding: 0 2rem;
  padding: 0 var(--page-margin);
  transition: background-color 0 linear;
  background-color: rgba(255, 255, 255, 0);
  justify-content: flex-end;
  align-items: center;
  font-size: .85rem;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.navigation--open .navigation__controls {
  background-color: #fff;
  transition-delay: .5s;
}

.navigation__block {
  padding-top: 3.85rem;
  padding-top: calc(var(--header-height)  + .05rem);
  background-color: #fff;
  transition: transform .5s cubic-bezier(0, .66, 0, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
}

.navigation--open .navigation__block {
  transform: translateX(0);
}

.navigation__wrap {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}

.navigation__menu-button {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0 0 0 1.5rem;
  padding: 0;
}

.navigation__menu-button svg {
  display: block;
}

.navigation:not(.navigation--open) {
  pointer-events: none;
}

@media (min-width: 992px) {
  .navigation {
    --grid-gutter: 2rem;
  }
}

.navigation--open {
  opacity: 1;
}

@media (max-width: 575px) {
  .navigation__wrap {
    --page-margin: 1rem;
    padding-bottom: 100px;
  }

  .navigation__menu-button {
    margin-left: 1rem;
  }
}

.section-navigation {
  color: #fff;
  z-index: 50;
  padding-left: 2rem;
  padding-left: var(--page-margin);
  padding-right: 2rem;
  padding-right: var(--page-margin);
  background-color: #163f88;
  font-size: .85rem;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.section-navigation a {
  text-decoration: none;
}

.section-navigation__list {
  border-left: 1px solid rgba(255, 255, 255, .17);
  display: flex;
}

.section-navigation__item {
  color: inherit;
  white-space: nowrap;
  margin-right: 1px;
  padding: .65rem 1.1rem;
  display: inline-block;
  box-shadow: 1px 0 rgba(255, 255, 255, .17);
}

.section-navigation__item:hover {
  background-color: #003798;
}

.section-navigation__item--active, .section-navigation__item--active:hover, .section-navigation__item:active {
  color: rgba(255, 255, 255, .6);
  background-color: #123471;
}

@media (max-width: 575px) {
  .section-navigation {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .section-navigation__list {
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 2rem;
    padding-right: var(--page-margin);
    flex-wrap: nowrap;
    display: flex;
  }
}

.page-intro {
  background: #edf3fd linear-gradient(to top, rgba(0, 67, 214, .03), rgba(0, 67, 214, 0) 5rem);
  background: var(--color-pale-blue) linear-gradient(to top, rgba(0, 67, 214, .03), rgba(0, 67, 214, 0) 5rem);
  min-height: 4rem;
  display: grid;
}

.page-intro__breadcrumbs {
  z-index: 2;
  padding: .5rem 2rem;
  padding: .5rem var(--page-margin);
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
  white-space: nowrap;
  grid-area: 1 / 1 / auto / 3;
}

.page-intro__image {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}

@supports (object-fit: cover) {
  .page-intro--image-only > img, .page-intro--image-only > picture > img {
    max-height: 45vh;
    min-height: 8rem;
    object-fit: cover;
  }
}

.page-intro--christmas {
  color: #f5ecdf;
  color: var(--color-beige);
  background-color: #d52822;
  background-color: var(--color-christmas-2023);
  position: relative;
}

.page-intro--christmas a {
  color: #f5ecdf;
  color: var(--color-beige);
}

.page-intro__image > img, .page-intro__image > picture > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

@supports (object-fit: contain) {
  .page-intro__image--contain > img, .page-intro__image--contain > picture > img {
    object-fit: contain;
  }
}

.page-intro--christmas .page-intro__breadcrumbs {
  background-color: rgba(0, 0, 0, 0);
}

@media (max-width: 767px) {
  .page-intro {
    grid-template-columns: 2rem minmax(0, 1fr) 2rem;
    grid-template-columns: var(--page-margin) minmax(0, 1fr) var(--page-margin);
    grid-template-rows: min-content minmax(0, 1fr) auto;
  }

  .page-intro__image {
    height: 66vw;
    grid-area: 2 / 1 / auto / 4;
  }

  .page-intro--small .page-intro__image {
    height: 30vw;
  }

  .page-intro__image--christmas {
    height: auto;
  }

  .page-intro__text {
    text-align: center;
    grid-area: 3 / 2;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .page-intro--small .page-intro__image--contain + .page-intro__text {
    margin-top: 0;
  }

  .page-intro--full-image .page-intro__breadcrumbs {
    grid-column: 1 / 4;
  }
}

@media (max-width: 574px) {
  .page-intro__image {
    height: 50vw;
  }

  .page-intro__text {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .page-intro__image--christmas {
    height: auto;
  }
}

@media (min-width: 768px) {
  .page-intro {
    --calc-margin: max(var(--page-margin), calc((100vw - var(--page-width)) / 2 + var(--page-margin)));
    grid-template-columns: var(--calc-margin) minmax(0, 1fr) minmax(0, 1fr) var(--calc-margin);
    grid-template-rows: auto minmax(0, 1fr) 1rem;
    grid-template-rows: auto minmax(0, 1fr) calc(var(--page-overlap-y) * -1);
  }

  .page-intro:not(:empty):not(.page-intro--empty), .page-intro:not(.page-intro--empty) {
    min-height: 40vmax;
  }

  .page-intro--small:not(:empty):not(.page-intro--empty), .page-intro--small:not(.page-intro--empty) {
    min-height: 25vmax;
  }

  .page-intro__text {
    margin-right: 2rem;
    margin-right: var(--page-margin);
    grid-area: 2 / 2 / auto / 3;
    align-self: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .page-intro__breadcrumbs {
    padding-right: .5rem;
  }

  .page-intro__image {
    grid-area: 1 / 3 / 4 / 5;
  }

  .page-intro--full-image .page-intro__image {
    background-color: #000;
    grid-area: 2 / 1 / 4 / 5;
  }

  .page-intro--full-image .page-intro__image > img, .page-intro--full-image .page-intro__image > picture > img {
    opacity: .7;
  }

  .page-intro--full-image .page-intro__text {
    z-index: 2;
    color: #fff;
  }

  .page-intro--full-image .page-intro__breadcrumbs {
    grid-column: 1 / 5;
  }

  .page-intro--image-only .page-intro__image {
    grid-area: 2 / 1 / 4 / 5;
  }

  .page-intro--text-only, .page-intro--text-only:not(:empty):not(.page-intro--empty) {
    height: 22vmax;
    min-height: 18rem;
  }

  .page-intro--text-only .page-intro__image {
    display: none;
  }

  .page-intro--text-only .page-intro__text {
    max-width: 38rem;
    text-align: center;
    grid-column: 2 / 4;
    justify-self: center;
    margin-right: 0;
  }
}

.page-navigation:not(.page-navigation--no-background) {
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
  padding-top: 2rem;
  padding-bottom: 3rem;
  padding-bottom: calc(2rem + var(--page-overlap-y) * -1);
}

.page-navigation__inner {
  gap: 1rem;
  gap: var(--grid-gutter);
  display: flex;
}

.page-navigation__inner > * {
  flex: 1 0;
}

@media (max-width: 767px) {
  .page-navigation__inner {
    flex-direction: column;
  }
}

.page-navigation__heading {
  text-align: center;
}

.page-navigation__heading:not(:last-child) {
  margin-bottom: 1.5rem;
}

.page-navigation__header {
  text-align: center;
  max-width: 46rem;
}

.page-navigation__header:not(:last-child) {
  margin-bottom: 1.5rem;
}

.page-navigation__actions {
  text-align: center;
}

.page-navigation__actions:not(:first-child) {
  margin-top: 1.75rem;
}

.card {
  background-color: #fff;
  background-color: var(--color-background);
  box-shadow: 0 .15rem .8rem rgba(98, 108, 104, .08);
  box-shadow: var(--shadow-medium);
  border-radius: .25rem;
  border-radius: var(--card-border-radius);
  color: inherit;
  grid-template-rows: auto minmax(0, 1fr) auto;
  grid-template-columns: auto minmax(0, 1fr);
  padding: 1rem 1.25rem;
  font-size: .85rem;
  text-decoration: none;
  display: grid;
  overflow: hidden;
}

a.card:active {
  color: inherit;
}

.card__image {
  grid-area: 1 / 1 / auto / 3;
  margin: -1rem -1.25rem 1rem;
}

.card__image > img {
  width: 100%;
  aspect-ratio: 16 / 9;
  height: auto;
  object-fit: cover;
  display: block;
}

.card svg {
  grid-area: 2 / 1;
  margin: -.25rem .5rem 0 -.25rem;
}

.card__content {
  grid-area: 2 / 2;
}

.card__content--extra-spacing {
  padding: 1rem;
}

.card__heading {
  color: #1c3f8c;
  color: var(--color-blue);
  font-size: 1.25rem;
  font-weight: bold;
}

.card__heading:not(:last-child) {
  margin-bottom: .35rem;
}

.card__heading--black {
  color: #222;
  color: var(--color-black);
}

.card__intro {
  font-weight: 600;
}

.card__read-more {
  color: #1c3f8c;
  color: var(--color-blue);
  grid-area: 3 / 2;
  justify-self: left;
  margin-top: .45rem;
}

@media (max-width: 575px) {
  .card__read-more:not(.card__read-more--with-cta-mobile) {
    display: none;
  }
}

.breadcrumbs {
  font-size: .8rem;
  display: flex;
}

.breadcrumbs a:not(:last-child) {
  flex-shrink: 0;
}

.breadcrumbs a {
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
}

.breadcrumbs a:not(:first-child):before {
  content: "";
  width: .4rem;
  height: .6rem;
  vertical-align: -.05rem;
  background-color: currentColor;
  margin-left: .5rem;
  margin-right: .4rem;
  display: inline-block;
  -webkit-mask-image: url("arrow-small.3a6d0f9e.svg");
  mask-image: url("arrow-small.3a6d0f9e.svg");
  -webkit-mask-size: cover;
  mask-size: cover;
}

.header + .overlap-intro {
  margin-top: -1.4rem;
}

.overlap-intro {
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  padding-top: 3rem;
  display: flex;
  overflow: visible !important;
}

.overlap-intro__image {
  max-width: 100%;
  width: 38rem;
  height: auto;
  margin: 0 0 -4rem;
  display: block;
}

.page-wrap {
  width: 100%;
  max-width: 66rem;
  max-width: var(--page-width);
  padding-left: 2rem;
  padding-left: var(--page-margin);
  padding-right: 2rem;
  padding-right: var(--page-margin);
  margin-left: auto;
  margin-right: auto;
}

.page-wrap--wide {
  max-width: 100%;
}

.page-wrap--narrow {
  max-width: 48rem;
}

@media (min-width: 576px) and (max-width: 991px) {
  .page-wrap--md-narrow {
    max-width: 40rem;
  }
}

.page-wrap--center {
  max-width: 40rem;
  text-align: center;
}

.page-wrap__expand {
  margin-left: -2rem;
  margin-left: calc(var(--page-margin) * -1);
  width: 100vw;
  margin-top: 2rem;
  display: block;
}

img.page-wrap__expand {
  height: auto;
}

.page-wrap__expand--flush {
  margin-top: 0;
}

.page-card__content > .page-wrap__expand {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.page-intro--blank + .page-wrap > .page-cardwrapper img.page-wrap__expand:first-child {
  margin-top: 1rem;
}

@media (min-width: 1320px) {
  .page-wrap__expand {
    margin-left: calc(31rem - 50vw);
    margin-left: calc(var(--page-width) / 2 - var(--page-margin) * 2 / 2 - 50vw);
  }
}

@media (max-width: 575px) {
  .page-wrap--full-mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

.page-section {
  margin-top: 2rem;
}

@media (max-width: 767px) {
  .header + .page-section {
    margin-top: 2rem;
    margin-top: var(--page-margin);
  }
}

.page-section.page-section--flush {
  margin-top: 0;
}

.page-section--inner-spacing {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 992px) {
  .page-section--extra-spacing {
    margin-top: 4rem;
  }

  .page-section--inner-spacing {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.page-section--pink {
  background-color: #fcebee;
  background-color: var(--color-pale-red);
}

.page-section--blue {
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
}

.page-section--green {
  background-color: #f0f8f0;
  background-color: var(--color-pale-green);
}

.page-section__image {
  box-shadow: 0 .7rem 2.9rem rgba(20, 0, 0, .3);
  box-shadow: var(--shadow-large);
  top: -1rem;
}

@media (max-width: 575px) {
  .page-section__image {
    box-shadow: 0 .15rem .8rem rgba(98, 108, 104, .08);
    box-shadow: var(--shadow-medium);
  }
}

.page-section > .page-section {
  margin-bottom: 2rem;
}

.page-section > .page-section.page-navigation:first-child, .page-intro + .page-section:has( > .page-navigation:first-child) {
  margin-top: 0;
}

.page-card {
  position: relative;
}

.page-card + .page-card, .category-selector + .page-card, .page-cardwrapper + .page-cardwrapper {
  margin-top: 1rem;
}

@media (min-width: 1200px) {
  .page-card__corner-heading, .page-card__content > .page-card__corner-heading {
    top: 2.5rem;
    top: calc(var(--page-margin) * 1.25);
    width: calc(50% - 17rem);
    width: calc((100% - var(--page-content-width)) / 2);
    max-width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    padding-right: 1rem;
    position: absolute;
    left: 0;
  }

  .page-card__corner-heading + * {
    margin-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .page-card__corner-heading {
    text-align: center;
  }
}

.page-card__meta {
  font-size: .85rem;
  display: flex;
}

.page-card__label {
  color: #fff;
  background-color: #1c3f8c;
  background-color: var(--color-blue);
  text-transform: uppercase;
  letter-spacing: .05rem;
  -webkit-font-smooth: antialiased;
  border-radius: .1rem;
  padding: .2rem .45rem .1rem;
  font-size: .7rem;
  font-weight: 600;
}

@media (max-width: 767px) {
  .page-card__meta {
    flex-wrap: wrap;
    padding: 1rem 1rem .5rem;
    font-size: .7rem;
  }

  .page-card__meta > * {
    margin-bottom: .5rem;
  }

  .page-card__meta > :not(:last-child) {
    margin-right: .75rem;
  }
}

@media (max-width: 575px) {
  .page-card__meta {
    padding: 4rem;
    padding: calc(var(--page-margin) * 2);
  }
}

@media (min-width: 768px) {
  .page-card__meta {
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }

  .page-card__meta > :not(:last-child) {
    margin-bottom: .75rem;
  }
}

.page-card__heading {
  text-align: center;
  margin-top: 2.5rem;
  margin-top: calc(var(--page-margin) * 1.25);
  margin-bottom: 2.5rem;
  margin-bottom: calc(var(--page-margin) * 1.25);
  padding-left: 3rem;
  padding-left: calc(var(--page-margin) * 1.5);
  padding-right: 3rem;
  padding-right: calc(var(--page-margin) * 1.5);
}

.page-card__intro {
  text-align: center;
  margin-bottom: 1rem;
}

.page-card__intro:not(:last-child) {
  margin-bottom: 2rem;
}

@media (max-width: 575px) {
  .page-card__intro {
    margin-top: .7rem;
  }
}

.page-card__wide-content {
  margin-top: 2.5rem;
  margin-top: calc(var(--page-margin) * 1.25);
  margin-bottom: 3rem;
  margin-bottom: calc(var(--page-margin) * 1.5);
  padding-left: 3rem;
  padding-left: calc(var(--page-margin) * 1.5);
  padding-right: 3rem;
  padding-right: calc(var(--page-margin) * 1.5);
}

.page-card__content {
  margin-top: 2.5rem;
  margin-top: calc(var(--page-margin) * 1.25);
  margin-bottom: 3rem;
  margin-bottom: calc(var(--page-margin) * 1.5);
}

.page-card__content > * {
  max-width: 34rem;
  max-width: var(--page-content-width);
  padding-left: 3rem;
  padding-left: calc(var(--page-margin) * 1.5);
  padding-right: 3rem;
  padding-right: calc(var(--page-margin) * 1.5);
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
}

.page-section--inner-spacing > .page-card .page-card__content:first-child {
  margin-top: 0;
}

.page-section--inner-spacing > .page-card .page-card__content:last-child {
  margin-bottom: 0;
}

.page-card__content--left > * {
  margin-left: 0;
}

@media (max-width: 575px) {
  .page-card__content {
    margin-top: 4rem;
    margin-top: calc(var(--page-margin) * 2);
    margin-bottom: 4rem;
    margin-bottom: calc(var(--page-margin) * 2);
  }

  .page-card__content > * {
    padding-left: 4rem;
    padding-left: calc(var(--page-margin) * 2);
    padding-right: 4rem;
    padding-right: calc(var(--page-margin) * 2);
  }

  .page-card__wide-content {
    margin-top: 4rem;
    margin-top: calc(var(--page-margin) * 2);
    margin-bottom: 4rem;
    margin-bottom: calc(var(--page-margin) * 2);
    padding-left: 4rem;
    padding-left: calc(var(--page-margin) * 2);
    padding-right: 4rem;
    padding-right: calc(var(--page-margin) * 2);
  }
}

@media (max-width: 439px) {
  .page-wrap .page-card__content > :not(.box) {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .page-card--offset .page-card__content {
    box-sizing: content-box;
    padding-left: 8rem;
  }
}

.page-card__content--form > * {
  max-width: 34rem;
}

.page-card__content--center {
  text-align: center;
}

.nav-menu {
  line-height: 1.1;
}

.nav-menu a {
  text-decoration: none;
}

.nav-menu__group {
  border-bottom: 1px solid rgba(0, 0, 0, .13);
  border-bottom: 1px solid var(--color-thin-line);
  z-index: 2;
  background-color: rgba(237, 243, 253, 0);
  transition: background-color .25s linear;
  position: relative;
  overflow: hidden;
}

.nav-menu__group > .nav-menu__group {
  border-bottom: 0;
}

.nav-menu__group--open {
  background-color: #edf3fd;
  background-color: var(--color-light-blue);
  z-index: 1;
  transition-duration: .1s;
}

.nav-menu__group ul {
  padding-left: 0;
}

.nav-menu__group .nav-menu__group {
  margin-top: -.5rem;
  padding-bottom: 1.25rem;
}

.nav-menu__group .nav-menu__group .nav-menu__item > a {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 4rem;
  padding-left: calc(var(--page-margin) * 2);
}

.nav-menu__item {
  display: flex;
}

.nav-menu__item > a {
  padding: 1.5rem 2rem;
  padding: 1.5rem var(--page-margin);
  flex: 1;
}

.nav-menu__button {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 1.2rem 2rem;
  padding: 1.2rem var(--page-margin);
  width: 4.75rem;
  white-space: nowrap;
  text-indent: 10rem;
  background: none;
  border: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.nav-menu__button:after {
  content: "";
  width: .5rem;
  height: 1.1rem;
  transform-origin: 50% 45%;
  background: url("arrow.0f92edb9.svg") center / .5rem 1.1rem no-repeat;
  transition: transform .2s ease-out;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%)rotate(90deg);
}

.nav-menu__group--open > .nav-menu__item > .nav-menu__button:after {
  transform: translate(-50%, -50%)rotate(-90deg);
}

@media (max-width: 767px) {
  .nav-menu__button {
    width: 1.25rem;
    box-sizing: content-box;
  }

  .nav-menu__button:after {
    right: 45%;
  }
}

.js.tabbed .nav-menu__item > a:focus, .js.tabbed .nav-menu__button:focus {
  box-shadow: inset 0 0 0 .05rem #fff, inset 0 0 0 .2rem rgba(0, 124, 216, .533);
  box-shadow: inset 0 0 0 .05rem var(--input-prominent-focus-inner), inset 0 0 0 .2rem var(--input-prominent-focus);
  outline: 0;
}

.shortcuts {
  gap: .5rem;
  display: flex;
}

.shortcuts__shortcut {
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  text-align: center;
  white-space: nowrap;
  border-radius: .3rem;
  flex-direction: column;
  flex: 1 0;
  align-items: center;
  padding: .85rem .5rem 1.2rem;
  font-size: .8rem;
  text-decoration: none;
  display: flex;
}

.shortcuts__shortcut svg {
  margin-bottom: .15rem;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 400px) {
  .shortcuts {
    flex-direction: column;
  }

  .shortcuts__shortcut {
    flex-direction: row;
    gap: .4rem;
    padding-top: .3rem;
    padding-bottom: .3rem;
  }
}

.footer-menu {
  border-bottom: 1px solid rgba(0, 0, 0, .13);
  border-bottom: 1px solid var(--color-thin-line);
  padding-bottom: 2.5rem;
  transition: border-color .1s ease-out;
}

.footer-menu a {
  text-decoration: none;
}

.footer-menu__list {
  padding-left: 0;
  list-style: none;
  display: flex;
}

.footer-menu__item {
  margin-right: 2.3rem;
}

.footer-menu__item a {
  color: #1c3f8c;
  color: var(--color-blue);
  font-size: 1.2rem;
  font-weight: 500;
}

@media (max-width: 730px) {
  .footer-menu__item {
    margin-right: 1.2rem;
  }
}

@media (max-width: 620px) {
  .footer-menu {
    padding-bottom: 1.6rem;
  }

  .footer-menu__list {
    flex-direction: column;
  }

  .footer-menu__item {
    margin-right: 0;
  }

  .footer-menu__item:not(:last-child) {
    margin-bottom: .8rem;
  }
}

.heading {
  letter-spacing: .03rem;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, .13);
  border-bottom: 1px solid var(--color-thin-line);
  font-size: .75rem;
  font-weight: 600;
  line-height: 1.5;
  display: inline-block;
}

.heading, .heading a {
  color: inherit;
}

.heading--no-underline {
  border-bottom: 0;
}

.heading > span, .heading > label {
  border-bottom: 1px solid rgba(0, 0, 0, .13);
  border-bottom: 1px solid var(--color-thin-line);
  display: inline-block;
}

.heading--no-underline > span, .heading--no-underline > label {
  border-bottom: 0;
}

.page-card__content > .heading {
  border-bottom: 0;
  display: block;
}

.symbol-button {
  vertical-align: top;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  white-space: nowrap;
  background: none;
  border: 0;
  align-items: center;
  padding: 0;
  font-size: 1em;
  line-height: 1.2;
  display: inline-flex;
}

a.symbol-button, button.symbol-button {
  cursor: pointer;
  color: #1c3f8c;
  color: var(--color-link);
}

a.symbol-button:hover, button.symbol-button:hover {
  box-shadow: none;
  color: #1c3f8c;
  color: var(--color-link);
  background: none;
}

a.symbol-button:hover span, button.symbol-button:hover span {
  text-decoration: underline;
}

.symbol-button:focus {
  box-shadow: none;
}

a.symbol-button:active, button.symbol-button:active {
  color: #014ac9;
  color: var(--color-link--active);
}

.symbol-button svg, .symbol-button img {
  vertical-align: middle;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: .2rem;
}

.symbol-button span {
  position: relative;
  top: .05rem;
}

.symbol-button--large {
  line-height: 1.6rem;
}

.symbol-button--large svg, .symbol-button--large img {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: .5rem;
}

.symbol-button--reverse {
  text-align: right;
  flex-direction: row-reverse;
}

.symbol-button--reverse svg, .symbol-button--reverse img {
  order: -1;
  margin-left: .2rem;
  margin-right: 0;
}

.symbol-button--reverse.symbol-button--large svg, .symbol-button--reverse.symbol-button--large img {
  margin-left: .5rem;
}

.image {
  position: relative;
}

.image__image {
  z-index: 2;
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}

.image__label {
  z-index: 10;
  color: #fff;
  background-color: #1c3f8c;
  background-color: var(--color-blue);
  text-transform: uppercase;
  letter-spacing: .05rem;
  -webkit-font-smooth: antialiased;
  border-radius: .1rem;
  padding: .2rem .45rem .1rem;
  font-size: .7rem;
  font-weight: 600;
  position: absolute;
  top: .5rem;
  left: .5rem;
}

.image__label--right {
  left: auto;
  right: .5rem;
}

@media (max-width: 575px) {
  .image__label {
    padding: .2rem .3rem .1rem;
    font-size: .6rem;
    top: .3rem;
    left: .3rem;
  }

  .image__label--right {
    left: auto;
  }
}

@media (min-width: 576px) {
  .image__label--large {
    padding: .3rem .6rem .25rem;
    top: .95rem;
    left: .95rem;
  }

  .image__label--large.image__label--right {
    left: auto;
    right: .95rem;
  }
}

.image__caption {
  color: #fff;
  padding: .85rem 1.35rem;
}

@supports (object-fit: cover) {
  .image--cover-3-4:before, .image--cover-4-3:before, .image--cover-16-9:before, .image--cover-1-1:before {
    content: "";
    height: 0;
    width: 100%;
    display: block;
  }

  .image--cover-4-3:before {
    padding-top: 75%;
  }

  .image--cover-16-9:before {
    padding-top: 56.25%;
  }

  .image--cover-1-1:before {
    padding-top: 100%;
  }

  .image--cover-3-4:before {
    padding-top: 133%;
  }

  .image--cover .image__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.image--zoom {
  overflow: hidden;
}

.image--zoom img {
  transition: transform .8s cubic-bezier(.3, 0, .15, 1);
}

a.image-zoom:hover img, a:hover .image--zoom img {
  transform: scale(1.03);
}

@media (max-width: 991px) {
  .image__caption {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 575px) {
  .image__caption {
    color: #989898;
    color: var(--color-gray);
    background-color: #fafafa;
    padding: .45rem .65rem;
  }
}

@media (min-width: 576px) {
  .image__caption {
    box-sizing: content-box;
    max-width: 14rem;
    z-index: 8;
    background-image: linear-gradient(20deg, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .66) 10%, rgba(0, 0, 0, 0) 35%);
    padding-top: 6rem;
    padding-right: 8rem;
    font-size: .85rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.overlapped-images {
  width: 95%;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.overlapped-image__container {
  height: 0;
  padding-top: 84%;
  position: relative;
}

.overlapped-images__image {
  width: 54%;
  height: auto;
}

.overlapped-images__image > img, .overlapped-images__image > noscript > img {
  width: 100%;
  height: auto;
}

.overlapped-images__image:first-child {
  z-index: 20;
  position: absolute;
  bottom: 0;
  left: 0;
}

.overlapped-images__image:last-child {
  z-index: 30;
  position: absolute;
  top: 0;
  right: 0;
}

.overlapped-images__image--shadowed {
  box-shadow: 0 .15rem 1.5rem rgba(3, 0, 35, .35);
}

@media (max-width: 991px) {
  .overlapped-images {
    width: 75%;
    max-width: 24rem;
  }
}

@media (max-width: 575px) {
  .overlapped-image__container {
    padding-top: 81%;
  }
}

.image-grid {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: .2rem;
  padding: .2rem .2rem 0;
  display: grid;
}

.image-grid:not(:last-child) {
  margin-bottom: 1rem;
}

.image-grid__image {
  max-width: 100%;
}

.short-text {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 1.5rem;
  padding-bottom: 1.6rem;
  display: flex;
}

.short-text > * {
  max-width: 100%;
}

.short-text__heading {
  margin-bottom: 1rem;
}

.short-text__quote {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.25;
}

.short-text__button-p {
  margin-top: .25rem;
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .short-text {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1200px) {
  .short-text {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .short-text__quote {
    font-size: 2rem;
  }
}

.newsfeed__top {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

@media (min-width: 992px) {
  .newsfeed__top {
    margin-bottom: 1rem;
  }
}

.newsfeed__see-more {
  margin-top: -.05rem;
}

.newsfeed__see-more a {
  color: #1c3f8c;
  color: var(--color-blue);
  text-decoration: none;
}

.newsfeed__item {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .13);
  box-shadow: inset 0 0 0 1px var(--color-thin-line);
}

a.newsfeed__item {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

@supports (object-fit: cover) {
  .newsfeed__item {
    min-height: 11.5rem;
    align-items: stretch;
    margin-top: 0;
    margin-left: 0;
  }

  .newsfeed__image {
    object-fit: cover;
  }
}

@media (min-width: 576px) {
  @supports (object-fit: cover) {
    .newsfeed__image {
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.newsfeed__image {
  width: 100%;
  background-color: #eee;
}

.newsfeed__content {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.newsfeed__item--no-image .newsfeed__content {
  padding-left: 1rem;
}

@media (max-width: 575px) {
  .newsfeed__content {
    padding-left: 1rem;
  }
}

.newsfeed__content--spread {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.newsfeed__heading {
  font-size: 1.25rem;
  font-weight: 400;
}

.support .support__heading {
  margin-bottom: 1rem;
}

.support__box {
  padding-bottom: 1.5rem;
  display: block;
}

a.support__box {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.support__box > :not(.support__image-wrapper) {
  padding-left: 1rem;
  padding-right: 1rem;
}

.support__image {
  width: 100%;
  height: auto;
}

.support__title {
  margin-top: 1.25rem;
  margin-bottom: .75rem;
  font-size: 1.25rem;
  font-weight: normal;
}

.support__content {
  text-align: center;
}

@media (max-width: 575px) {
  .support__description {
    display: none;
  }
}

@media (max-width: 991px) {
  .support__box {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .13);
    box-shadow: inset 0 0 0 1px var(--color-thin-line);
  }
}

@media (min-width: 992px) {
  .support {
    text-align: center;
  }

  .support__box {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .08);
  }

  .support .support__heading {
    margin-bottom: 2rem;
  }

  .support .support__heading--close {
    margin-bottom: .5rem;
  }

  .support__box--highlight {
    box-shadow: 0 3px 36px rgba(0, 0, 0, .16);
  }
}

@media (min-width: 1200px) {
  .support__title {
    margin-top: 1.75rem;
  }
}

@supports (object-fit: cover) {
  .support__image-wrapper {
    height: 0;
    padding-top: 66%;
    position: relative;
  }

  .support__image-wrapper .support__image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @media (max-width: 575px) {
    .support__image-wrapper {
      padding-top: 73%;
    }
  }

  @media (min-width: 992px) {
    .support__box--highlight .support__image-wrapper {
      padding-top: calc(66% + 1rem);
    }

    .support__box:not(.support__box--highlight) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .support__box--highlight {
      padding-bottom: 2rem;
    }
  }

  @media (min-width: 1200px) {
    .support .support__box {
      padding-bottom: 2.25rem;
    }

    .support .support__box--highlight {
      padding-bottom: 3rem;
    }
  }

  @media (min-width: 576px) and (max-width: 991px) {
    .support__box > .support__image-wrapper {
      padding-top: 35%;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .support__box > .support__image-wrapper {
      padding-top: 45%;
    }
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .support__box {
    align-items: center;
    padding-bottom: 0;
    display: flex;
  }

  .support__image-wrapper, .support__content {
    flex: 50%;
  }

  .support__content {
    margin-bottom: 1.5rem;
  }
}

.support-main__box {
  box-shadow: 0 .15rem .8rem rgba(98, 108, 104, .08);
  box-shadow: var(--shadow-medium);
}

.support-main__title {
  margin: 0 2rem;
  margin: 0 var(--page-margin);
}

.support-main__title:not(:first-child) {
  margin-top: 1.5rem;
  margin-top: calc(var(--page-margin) * .75);
}

.support-main__content {
  padding: .5rem 2rem 2rem;
  padding: .5rem var(--page-margin) var(--page-margin);
  align-items: flex-end;
  display: flex;
}

.support-main__text {
  flex: 1;
  margin-right: 1rem;
}

@media (max-width: 575px) {
  .support-main__title, .support-main__content {
    --page-margin: 1rem;
    text-align: center;
  }

  .support-main__content {
    flex-direction: column;
    align-items: center;
  }

  .support-main__text {
    margin-right: 0;
  }

  .support-main__action {
    margin-top: .75rem;
  }
}

.support-mini {
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  background-color: #fff;
  background-color: var(--color-background);
  display: block;
  box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .05), 0 .1rem .1rem rgba(0, 0, 0, .02);
}

a.support-mini {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.support-mini__title {
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.support-mini__title:not(:last-child) {
  margin-bottom: .4rem;
}

.support-mini__title--small {
  font-size: 1rem;
  font-weight: 700;
}

.support-mini__description {
  margin-top: 0;
  font-size: .9rem;
}

.support-mini__description:not(:last-child) {
  margin-bottom: 0;
}

.support-mini__price {
  margin-top: .75rem;
  font-size: 1.25rem;
  line-height: 1.2;
}

.support-mini__price:not(:last-child) {
  margin-bottom: .8rem;
}

.support-mini__action:not(:first-child) {
  margin-top: .8rem;
}

.support-mini__content {
  text-align: center;
  margin-bottom: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (max-width: 575px) {
  .support-mini__description {
    font-size: .8rem;
  }

  .support-mini__price {
    font-size: 1rem;
  }

  .support-mini__content {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.support-mini-wrapper {
  margin-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.support-mini-wrapper:first-child {
  padding-top: 1rem;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .support-mini-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 1200px) {
  .support-mini-wrapper {
    margin-bottom: .5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .support-mini-wrapper:first-child {
    padding-top: 1.5rem;
  }
}

.partners {
  text-align: center;
}

.partners .partners__heading:not(:last-child) {
  margin-bottom: 1rem;
}

.partners__list {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.partners__partner {
  flex-grow: 0;
  margin: 1rem 2rem;
  text-decoration: none;
  position: relative;
}

.partners__partner .partners__logo {
  display: block;
}

@media (min-width: 992px) {
  .partners .partners__heading {
    margin-bottom: 2rem;
  }
}

@media (max-width: 991px) {
  .partners__partner {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

@media (max-width: 691px) {
  .partners__partner {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media (max-width: 659px) {
  .partners__list {
    justify-content: space-around;
  }

  .partners__partner {
    margin: .5rem 3vw;
  }

  .partners__partner > img {
    max-height: 7vw;
    width: auto;
  }
}

@media (min-width: 1100px) {
  .partners__partner {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
}

.direct-donation__intro {
  max-width: 34rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .direct-donation__intro {
    text-align: center;
  }
}

.direct-donation__method {
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 2.2rem 1rem;
  display: flex;
}

.direct-donation__method--swish {
  background-color: #f0f8f0;
  background-color: var(--color-pale-green);
}

.direct-donation__method--bankgirot {
  background-color: #fcebee;
  background-color: var(--color-pale-red);
}

.direct-donation__method--plusgirot {
  background-color: #edf3fd;
  background-color: var(--color-light-blue);
}

.direct-donation__number {
  border-bottom: 1px solid rgba(0, 0, 0, .13);
  border-bottom: 1px solid var(--color-thin-line);
  white-space: nowrap;
  margin-bottom: 1rem;
}

.direct-donation__number:not(:first-child) {
  margin-top: 0;
}

.direct-donation__logo {
  min-height: 3rem;
  align-items: center;
  display: flex;
}

.direct-donation__logo > img, .direct-donation__logo > noscript > img {
  max-width: 100%;
  width: 6.75rem;
  height: auto;
}

@media (max-width: 767px) {
  .direct-donation__method {
    flex-direction: row;
    justify-content: space-between;
  }

  .direct-donation__number {
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .direct-donation__method {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
}

.direct-donation-simple {
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.direct-donation-simple__number {
  white-space: nowrap;
}

@media (max-width: 575px) {
  .direct-donation-simple__number {
    font-size: .8rem;
  }
}

.direct-donation-simple__logo {
  max-width: 50%;
  height: auto;
}

.direct-donation-simple__logo > img {
  max-width: 100%;
  height: auto;
  display: block;
}

@supports (object-fit: contain) {
  .direct-donation-simple__logo > img {
    max-height: 1.5rem;
    object-fit: contain;
    object-position: left center;
  }
}

.social-section__icons {
  margin-top: 2.5rem;
}

.social-section__icon {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (max-width: 575px) {
  .social-section {
    --grid-row-margin: 1.5rem;
  }

  .social-section__icon .button {
    margin-top: 1rem;
  }
}

@media (min-width: 577px) {
  .social-section__icon .button {
    margin-top: 1.5rem;
  }
}

.social-links {
  flex-wrap: wrap;
  font-size: .85rem;
  display: flex;
}

.social-links__link {
  white-space: nowrap;
  text-decoration: none;
}

.social-links__link:not(:last-child) {
  margin-right: .75rem;
}

.social-links__link > img {
  vertical-align: -.2rem;
  width: .85rem;
  height: .85rem;
  margin-right: .25rem;
  position: relative;
  top: -.05rem;
}

.highlights__heading {
  text-align: center;
  display: block;
}

.highlights__list {
  flex-direction: column;
  padding-left: 0;
  display: flex;
  position: relative;
}

.highlights__item {
  display: block;
  position: relative;
}

.highlights__item:before {
  content: "";
  width: .9rem;
  height: .9rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: .45rem;
  display: block;
}

.highlights__item:nth-child(3n+1) {
  color: #b31634;
  color: var(--color-red);
}

.highlights__item:nth-child(3n+2) {
  color: #1c3f8c;
  color: var(--color-blue);
}

.highlights__item:nth-child(3n+3) {
  color: #178972;
  color: var(--color-green);
}

.highlights__item strong {
  margin-bottom: -.05em;
  font-size: 1.9em;
  font-weight: 600;
  line-height: 1.1;
  display: block;
}

.highlights__image {
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (max-width: 575px) {
  .highlights__list {
    text-align: center;
    padding-top: .5rem;
    padding-bottom: 1rem;
  }

  .highlights__item {
    max-width: 16rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4rem;
  }

  .highlights__item + .highlights__item {
    margin-top: 1rem;
  }

  .highlights__item:before {
    position: absolute;
    top: 2.5rem;
    left: calc(50% - .45rem);
  }

  .highlights__item:after {
    content: "";
    height: 2.5rem;
    width: 1px;
    background: linear-gradient(#fff, #ccc);
    position: absolute;
    top: 0;
    left: 50%;
  }

  .highlights__image {
    margin-top: 1rem;
  }

  .highlights__image--small {
    width: 30%;
    height: auto;
    position: absolute;
    top: 6.5rem;
    left: 80%;
  }
}

@media (min-width: 576px) {
  .highlights__list {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .highlights__list:after {
    content: "";
    width: 1px;
    height: auto;
    background: linear-gradient(#fff 0%, #ccc 2.5rem, #ccc 3rem, rgba(204, 204, 204, 0) 3rem), linear-gradient(to top, #fff 0%, #ccc 2.5rem, #ccc 3rem, rgba(204, 204, 204, 0) 3rem), #ccc;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
  }

  .highlights__item {
    width: calc(50% - 5rem);
    font-size: 1.35rem;
    line-height: 1.3;
  }

  .highlights__item + .highlights__item {
    margin-top: 3rem;
  }

  .highlights__item:nth-child(2n) {
    text-align: left;
    align-self: flex-end;
    margin-right: 2.5rem;
  }

  .highlights__item:nth-child(odd) {
    text-align: right;
    align-self: flex-start;
    margin-left: 2.5rem;
  }

  .highlights__item--large-image {
    min-height: 11rem;
  }

  .highlights__image {
    position: absolute;
    top: 0;
  }

  .highlights__image--small {
    width: 8rem;
  }

  .highlights__item:nth-child(2n) .highlights__image {
    right: calc(100% + 5rem);
  }

  .highlights__item:nth-child(odd) .highlights__image {
    left: calc(100% + 5rem);
  }

  .highlights__item:before {
    z-index: 2;
    position: absolute;
    top: .66em;
  }

  .highlights__item:nth-child(2n):before {
    left: -2.95rem;
  }

  .highlights__item:nth-child(odd):before {
    right: -2.95rem;
  }
}

@media (min-width: 992px) {
  .highlights__item {
    font-size: 1.75rem;
  }
}

.dimmed {
  color: #989898;
  color: var(--color-gray);
  font-size: .9em;
}

.dimmed a {
  color: inherit;
  text-decoration: underline;
}

.dimmed + h1, .dimmed + h2, .dimmed + h3, .dimmed + h4, .dimmed + .h1, .dimmed + .h2, .dimmed + .h3, .dimmed + .h4 {
  margin-top: .5rem;
}

.foldable + .foldable {
  margin-top: .9rem;
}

.foldable__heading {
  color: #1c3f8c;
  color: var(--color-blue);
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  background-color: #fff;
  border-radius: .2rem;
  margin: 0;
  font-size: 1.25rem;
  transition: background-color .2s ease-out, border-radius .2s ease-out, box-shadow .2s ease-out;
  position: relative;
}

.foldable__heading:not(:last-child) {
  margin-bottom: 0;
}

.foldable__heading, .foldable__body {
  padding: .75rem 1.3rem .8rem;
}

.foldable__heading:after {
  content: "";
  width: .5rem;
  height: 1.1rem;
  transform-origin: 50% 45%;
  background: url("arrow.0f92edb9.svg") center / .5rem 1.1rem no-repeat;
  transition: transform .2s ease-out;
  position: absolute;
  top: .9rem;
  right: 1rem;
  transform: rotate(90deg);
}

.no-js .foldable__heading:after {
  display: none;
}

.js .foldable__heading {
  cursor: pointer;
  padding-right: 2rem;
}

.js .foldable__heading:focus {
  outline: 0;
}

.js .foldable--expanded .foldable__heading {
  border-radius: .2rem .2rem 0 0;
}

.js.tabbed .foldable__heading:focus {
  box-shadow: 0 0 0 .05rem #fff, 0 0 0 .2rem rgba(0, 124, 216, .533);
  box-shadow: var(--input-prominent-focus-inner) 0 0 0 .05rem, var(--input-prominent-focus) 0 0 0 .2rem;
}

.foldable__body {
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  border-top: 0;
  border-radius: 0 0 .2rem .2rem;
}

.foldable--expanded .foldable__heading, .no-js .foldable .foldable__heading {
  background-color: #f0f8f0;
  background-color: var(--color-pale-green);
}

.foldable--expanded .foldable__heading:after {
  transform: rotate(-90deg);
}

.foldable--expanded .foldable__body {
  display: block;
}

@media (max-width: 575px) {
  .foldable + .foldable {
    margin-top: .3rem;
  }

  .foldable__heading, .foldable__body {
    padding: .55rem .7rem .6rem;
  }

  .foldable__heading {
    font-size: 1rem;
  }

  .foldable__heading:after {
    top: .55rem;
  }
}

.preamble {
  text-align: center;
  margin-bottom: 2rem;
}

.preamble:after {
  content: "";
  border-bottom: 1px solid rgba(0, 0, 0, .13);
  border-bottom: 1px solid var(--color-thin-line);
  margin-top: 2rem;
  display: block;
}

@media (min-width: 768px) {
  .preamble > p {
    width: 85%;
    max-width: 34rem;
    max-width: var(--page-content-width);
    margin-left: auto;
    margin-right: auto;
  }
}

.preamble--no-line:after {
  border-color: rgba(0, 0, 0, 0);
  margin-top: 1rem;
}

@media (max-width: 575px) {
  .preamble--no-line:after {
    display: none;
  }
}

.contact-box {
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
  max-width: 21rem;
  padding: 1rem;
}

.contact-box--center {
  margin-left: auto;
  margin-right: auto;
}

.contact-box__name {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.3rem;
  line-height: 1.1;
}

.contact-box__name:not(:first-child) {
  margin-top: 1.2rem;
}

.contact-box a[href^="mailto:"] {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
}

.contact-list {
  margin-bottom: 1rem;
}

.contact-list__item {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.contact-list__item--highlight {
  background-color: #f0f8f0;
  background-color: var(--color-pale-green);
}

.contact-list__item--highlight-alt {
  background-color: #edf3fd;
  background-color: var(--color-light-blue);
}

.contact-list__item--highlight, .contact-list__item--highlight-alt {
  margin-top: 0;
  padding: 1rem;
}

.contact-list__heading:first-child {
  margin-top: 0;
}

.contact-list__primary-info {
  color: #1c3f8c;
  color: var(--color-blue);
  font-size: 1.3rem;
  line-height: 1;
}

.contact-list a[href^="mailto:"] {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
}

.contact-list__trim {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: -.45rem;
  display: inline-block;
  overflow: hidden;
}

.contact-list__divider {
  width: 100%;
  margin-top: .5rem;
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .contact-list {
    margin-bottom: 1.5rem;
    line-height: 1.8;
  }

  .contact-list__item {
    margin-top: 1.25rem;
    margin-bottom: .75rem;
  }

  .contact-list__item--highlight, .contact-list__item--highlight-alt {
    position: relative;
    left: -1rem;
  }

  .contact-list__trim {
    margin-bottom: -.6rem;
  }
}

@media (max-width: 575px) {
  .contact-list {
    margin-bottom: .1rem;
  }

  .contact-list__divider {
    display: none;
  }
}

.people-list__person {
  display: block;
}

li.people-list__person {
  margin-left: 0;
}

li + li.people-list__person {
  margin-top: 0;
  margin-left: 0;
}

.people-list__image {
  max-width: 100%;
  height: auto;
  display: block;
}

.people-list__name, .people-list__name:not(:first-child) {
  margin-top: 1.2rem;
  margin-bottom: .5rem;
  font-size: 1.3rem;
}

.people-list a[href^="mailto:"] {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
}

@media (max-width: 575px) {
  .people-list__person {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 576px) {
  .people-list__person {
    margin-bottom: 2rem;
  }
}

.notification {
  background-color: #178972;
  background-color: var(--color-green);
  color: #fff;
  border-radius: .25rem;
  border-radius: var(--input-border-radius);
  width: 13.6rem;
  padding: 1rem;
}

.notification--fixed {
  z-index: 90;
  border-radius: .25rem .25rem 0 0;
  border-radius: var(--input-border-radius) var(--input-border-radius) 0 0;
  box-shadow: 0 .15rem .8rem rgba(98, 108, 104, .08);
  box-shadow: var(--shadow-medium);
  position: fixed;
  bottom: 0;
  right: 1rem;
}

.notification__symbol {
  float: right;
  width: 1.1rem;
  height: 1.1rem;
  margin-top: -.05rem;
}

.notification .notification__heading {
  border-color: rgba(255, 255, 255, .67);
  margin-bottom: .75rem;
}

.frontpage-intro__insamlingskontroll {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (max-width: 991px) {
  .frontpage-intro {
    text-align: center;
  }

  .frontpage-intro__button-p {
    width: 100%;
  }

  .frontpage-intro__text {
    max-width: 24rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.8rem;
    padding-bottom: .8rem;
  }

  .frontpage-intro__insamlingskontroll {
    margin-top: .6rem;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .frontpage-intro__heading {
    font-size: 2.3rem;
    line-height: .9;
  }
}

@media (min-width: 576px) {
  .frontpage-intro__text-mobile {
    display: none;
  }
}

@media (max-width: 575px) {
  .frontpage-intro {
    margin-top: 1.2rem;
  }

  .frontpage-intro__text {
    max-width: 20rem;
    padding-top: 1.2rem;
    font-size: .9rem;
  }

  .frontpage-intro__text-desktop {
    display: none;
  }

  .frontpage-intro__heading {
    max-width: 16rem;
    margin-bottom: .6rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.6rem;
    line-height: .9;
  }

  .frontpage-intro__text-mobile:not(:last-child) {
    margin-bottom: .8rem;
  }

  .short-text__button-p {
    margin-top: 0;
  }

  .short-text__button-p > .button {
    font-weight: inherit;
    font-size: inherit;
    padding: .6rem 1.25rem;
    box-shadow: 0 .1rem .2rem rgba(0, 0, 0, .16);
  }

  .frontpage-intro__insamlingskontroll {
    width: 71px;
    height: 17px;
  }
}

@media (max-width: 419px) {
  .frontpage-intro__heading {
    font-size: 1.6rem;
  }
}

@media (min-width: 992px) {
  .frontpage-intro__heading {
    font-size: 2.2rem;
    line-height: .9;
  }

  .page-section.frontpage-intro {
    margin-top: 2.5rem;
  }

  .frontpage-intro__insamlingskontroll {
    margin-right: calc(1.25% - .0125rem);
    margin-right: calc(1.25% - var(--grid-gutter) * .5 * .025);
  }
}

@media (min-width: 1025px) and (max-width: 1319px) {
  .frontpage-intro__heading {
    font-size: 4vw;
  }
}

@media (min-width: 1320px) {
  .frontpage-intro__heading {
    font-size: 3rem;
  }
}

.category-selector {
  color: #fff;
  background-color: #1c3f8c;
  background-color: var(--color-blue);
  height: 2.6rem;
  border-radius: .15rem;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  position: relative;
  box-shadow: 0 .15rem .3rem rgba(0, 0, 0, .16);
}

input[type="checkbox"].category-selector__checkbox:checked {
  border-color: #fff;
}

.category-selector__categories > :not(:last-child) {
  margin-right: .5rem;
}

.category-selector__category {
  color: #fff;
  text-decoration: none;
  display: inline-block;
}

.category-selector__category:active {
  color: #fff;
}

.category-selector__category:before {
  content: "";
  width: .85rem;
  height: .85rem;
  vertical-align: -.15rem;
  border-color: #1c3f8c;
  border-color: var(--color-blue);
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--input-background-color);
  border-radius: .15rem;
  margin-right: .25rem;
  display: inline-block;
}

.category-selector__category--active:before {
  background-image: url("checkmark.76557bfd.svg");
}

.category-selector__category--active:focus:before {
  border-color: #dedede;
  border-color: var(--input-border-color--focus);
  box-shadow: 0 0 .3rem rgba(0, 0, 0, .04);
}

@media (min-width: 576px) {
  .category-selector__categories > :not(:last-child) {
    margin-right: 1.5rem;
  }

  .category-selector__category:before {
    width: 1.1rem;
    height: 1.1rem;
    vertical-align: -.25rem;
    background-size: 1.1rem 1.1rem;
    margin-right: .4rem;
  }
}

@media (min-width: 768px) {
  .category-selector {
    justify-content: center;
  }

  .category-selector__heading {
    position: absolute;
    left: 1rem;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .category-selector {
    justify-content: space-between;
  }
}

@media (max-width: 575px) {
  .category-selector {
    height: auto;
    flex-direction: column;
    padding-top: .6rem;
    padding-bottom: .65rem;
    font-size: .8rem;
  }

  .category-selector__heading {
    margin-bottom: .3rem;
  }
}

.news-card__meta {
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  margin-top: 1.2rem;
  display: flex;
}

.news-card__meta > :not(:last-child) {
  margin-right: 1rem;
}

.news-card__meta > * {
  margin-bottom: .5rem;
}

.news-card__symbols {
  font-size: .85rem;
  display: flex;
}

.news-card__symbols > :not(:last-child) {
  margin-right: 1rem;
}

.news-card__content {
  padding-top: 3rem;
  padding-top: calc(var(--page-margin) * 1.5);
  padding-bottom: 4rem;
  padding-bottom: calc(var(--page-margin) * 2);
}

.news-card__content > * {
  max-width: 34rem;
  max-width: var(--page-content-width);
  padding-left: 3rem;
  padding-left: calc(var(--page-margin) * 1.5);
  padding-right: 3rem;
  padding-right: calc(var(--page-margin) * 1.5);
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .news-card__content {
    padding-top: 1rem;
    padding-top: calc(var(--page-margin) * .5);
  }
}

.article-break, .fullwidth.article-break {
  background-color: #f0f8f0;
  background-color: var(--color-pale-green);
}

.article-break:not(:first-child), .fullwidth.article-break:not(:first-child) {
  margin-top: 3rem;
}

.article-break:not(:last-child), .fullwidth.article-break:not(:last-child) {
  margin-bottom: 3rem;
}

.article-break__image {
  margin-top: -1rem;
  margin-top: var(--page-overlap-y);
}

@media (max-width: 767px) {
  .article-break__content {
    padding-left: 1rem;
  }

  .article-break__image {
    margin-bottom: -1rem;
    margin-bottom: var(--page-overlap-y);
  }
}

@media (min-width: 768px) {
  .article-break__image {
    width: calc(100% - 2rem);
  }
}

@media (max-width: 1199px) {
  .article-break__content {
    padding-right: 1rem;
  }
}

@media (min-width: 1200px) {
  .article-break__content {
    padding-right: 5rem;
  }
}

.article-break--inner-spacing, .fullwidth.article-break--inner-spacing {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.article-break--inner-spacing-bottom, .fullwidth.article-break--inner-spacing-bottom {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 2rem;
}

.bankid-button {
  white-space: nowrap;
}

.bankid-button__symbol {
  width: 1.3rem;
  height: 1.2rem;
  vertical-align: middle;
  background: url("bank-id.e9adcd3c.svg") 0 0 / 1.3rem 1.2rem no-repeat;
  display: inline-block;
}

.bankid-button__button + .bankid-button__symbol {
  margin-left: .65rem;
}

@media (max-width: 575px) {
  .bankid-button__button {
    padding: .35rem .8rem;
    font-size: .75rem;
  }

  .bankid-button__loading .loading__label {
    display: none;
  }
}

@media (max-width: 339px) {
  .bankid-button__button {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .bankid-button__button + .bankid-button__symbol {
    width: .8rem;
    height: .738rem;
    background-size: .8rem .738rem;
    margin-left: .45rem;
  }
}

.bankid-sign-takeover {
  z-index: 300;
  background-color: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bankid-sign-takeover__block {
  z-index: 320;
  width: 100%;
  max-width: 30rem;
  background-color: #fff;
  border-radius: .15rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  box-shadow: 0 0 1.4rem rgba(0, 0, 0, .16);
}

.arrow {
  position: relative;
}

.arrow:before {
  content: "";
  width: .5rem;
  height: .5rem;
  background-color: inherit;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .13);
  border-top: 1px solid var(--color-thin-line);
  border-left: 1px solid rgba(0, 0, 0, .13);
  border-left: 1px solid var(--color-thin-line);
  box-shadow: inherit;
  display: block;
  position: absolute;
  top: -.28rem;
  left: 50%;
  transform: matrix(.95, .46, -.95, .46, 0, 0);
}

.arrow--align-contact-button:before {
  left: 6.7rem;
}

.arrow--1-2:before {
  left: 50%;
}

.arrow--1-3:before {
  left: 33.3333%;
}

.arrow--2-3:before {
  left: 66.6667%;
}

.arrow--1-4:before {
  left: 25%;
}

.arrow--2-4:before {
  left: 50%;
}

.arrow--3-4:before {
  left: 75%;
}

.arrow--1-5:before {
  left: 20%;
}

.arrow--2-5:before {
  left: 40%;
}

.arrow--3-5:before {
  left: 60%;
}

.arrow--4-5:before {
  left: 80%;
}

.arrow--col-1-4:before {
  left: calc(12.5% - .5rem);
  left: calc(12.5% - var(--grid-gutter) * .5);
}

.arrow--col-2-4:before {
  left: calc(37.5% - .25rem);
  left: calc(37.5% - var(--grid-gutter) * .25);
}

.arrow--col-3-4:before {
  left: calc(62.5% - .125rem);
  left: calc(62.5% - var(--grid-gutter) * .125);
}

.arrow--col-4-4:before {
  left: calc(87.5% - .0625rem);
  left: calc(87.5% - var(--grid-gutter) * .0625);
}

.tabbed-area__content-section.arrow:before {
  border-color: #dedede;
  border-color: var(--color-thin-line-solid);
}

.arrow-link {
  text-decoration: none;
}

.arrow-link:after {
  content: "";
  width: .4rem;
  height: .6rem;
  vertical-align: 0;
  background: url("arrow-small.3a6d0f9e.svg") center no-repeat;
  margin-left: .25rem;
  display: inline-block;
}

.card-preview {
  background-color: #fcfcfc;
  background-color: var(--color-light-gray);
  box-sizing: content-box;
  padding: 1rem;
}

@media (min-width: 992px) {
  .card-preview {
    padding: 2.5rem;
  }
}

.card-preview__card {
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1.65rem 3.3rem rgba(0, 0, 0, .16);
}

.card-preview__card:before {
  content: "";
  height: 0;
  padding-top: 141.43%;
  display: block;
}

.card-preview__card > iframe {
  width: 210mm;
  height: 297mm;
  transform-origin: 0 0;
  position: absolute;
  top: 0;
  left: 0;
}

.card-preview__card--landscape:before {
  padding-top: 70.707%;
}

.card-preview__card--landscape > iframe {
  width: 297mm;
  height: 210mm;
}

.card-preview__card--fold-mid:after {
  content: "";
  pointer-events: none;
  background: url("fold-light-gray.f3d6f815.svg") 50% -1px no-repeat, linear-gradient(90deg, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, .09) 49.95%, rgba(0, 0, 0, .12) 50%, rgba(0, 0, 0, .07) 50.25%, rgba(0, 0, 0, 0) 65%);
  background: url("fold-light-gray.f3d6f815.svg") center -1px no-repeat, var(--card-fold-mid-gradient);
  background-size: 18% 1.75%, auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-preview--large .card-preview__card--fold-mid:after {
  background-image: url("fold-dark-gray.a9ed25a6.svg"), linear-gradient(90deg, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, .09) 49.95%, rgba(0, 0, 0, .12) 50%, rgba(0, 0, 0, .07) 50.25%, rgba(0, 0, 0, 0) 65%);
  background-image: url("fold-dark-gray.a9ed25a6.svg"), var(--card-fold-mid-gradient);
}

.card-preview__card--christmas-card:before {
  padding-top: 62.5%;
}

.card-preview__card--christmas-card > iframe {
  width: 800px;
  height: 500px;
}

.card-preview__button {
  margin: 2rem auto 0;
  display: block;
}

@media (max-width: 991px) {
  .card-preview__card {
    max-width: 10rem;
  }

  .card-preview__card--landscape, .card-preview__card--christmas-card {
    max-width: 14.14rem;
  }

  .card-preview__button {
    margin-top: 1rem;
  }
}

.card-preview--large {
  z-index: 100;
  min-width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .75);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: scroll;
}

.card-preview--large .card-preview__button {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.card-preview--large .card-preview__card {
  max-width: 40rem;
}

.card-preview-thumb {
  background-color: #fafafa;
  display: block;
  position: relative;
  overflow: hidden;
}

.card-preview-thumb--with-card:before, .card-preview-thumb--with-christmas-card:before {
  content: "";
  width: 54.2857%;
  height: 76.4286%;
  background-size: cover;
  display: block;
  position: absolute;
  top: 11.4286%;
  left: 22.8571%;
  box-shadow: 0 .2rem 1rem rgba(0, 0, 0, .12);
}

.card-preview-thumb--with-card:before {
  background-image: url("card-preview-thumb.6bd1eddc.png");
}

.card-preview-thumb--with-christmas-card:before {
  background-image: url("christmas-card-preview-thumb.2141b77d.jpg");
}

.card-preview-thumb__image {
  width: 51.4286%;
  height: 34.2857%;
  object-fit: cover;
  position: absolute;
  top: 12.8571%;
  left: 24.2857%;
}

.card-preview-thumb__christmas-card-image {
  width: 22.8571%;
  object-fit: cover;
  position: absolute;
  top: 43.5714%;
  left: 39.2857%;
}

.card-preview-thumb__card-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.9);
}

@media (max-width: 767px) {
  .card-preview-thumb__card-icon {
    transform: translate(-50%, -50%)scale(.45);
  }
}

.price {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  display: flex;
}

.price__heading {
  display: block;
}

.price__heading:not(:last-child) {
  margin-bottom: 0;
}

.price__amount {
  font-size: 1.3rem;
}

.price__input {
  margin-top: .5rem;
}

.price__input > input {
  width: 6.5rem;
}

.price__input + .price__recommend {
  margin-top: .5rem;
}

.price .price__recommend {
  min-width: 5rem;
}

.price__arrow:before {
  left: 3.75rem;
}

.mini-cart {
  top: 100%;
  left: 2rem;
  left: var(--page-margin);
  right: 2rem;
  right: var(--page-margin);
  text-align: right;
  pointer-events: none;
  position: absolute;
}

.mini-cart a {
  text-decoration: none;
}

.mini-cart__block {
  color: #b31634;
  color: var(--color-red);
  background-color: #fcebee;
  background-color: var(--color-pale-red);
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  pointer-events: auto;
  border-radius: 0 0 .15rem .15rem;
  padding: .6rem .8rem .5rem;
  font-size: .8rem;
  display: inline-block;
}

.js.tabbed a.mini-cart__block:focus {
  box-shadow: 0 0 0 .05rem #fff, 0 0 0 .2rem rgba(0, 124, 216, .533);
  box-shadow: var(--input-prominent-focus-inner) 0 0 0 .05rem, var(--input-prominent-focus) 0 0 0 .2rem;
  outline: 0;
}

.mini-cart__heading:before {
  content: "";
  width: .75rem;
  height: .65rem;
  vertical-align: -.05rem;
  background: url("heart.647a6732.svg") center no-repeat;
  margin-left: .05rem;
  margin-right: .5rem;
  display: inline-block;
}

.mini-cart__contains {
  background: #b31634;
  background: var(--color-red);
  color: #fff;
  width: 1.2rem;
  height: 1.2rem;
  text-align: center;
  border-radius: 2rem;
  margin-left: 1.5rem;
  padding-top: .2rem;
  padding-bottom: .2rem;
  font-size: .65rem;
  display: inline-block;
}

@media (max-width: 767px) {
  .mini-cart__block {
    padding: .25rem .5rem .3rem;
  }

  .mini-cart__contains {
    width: 1rem;
    height: 1rem;
    margin-left: .25rem;
    padding-top: .1rem;
    padding-bottom: .1rem;
  }
}

.cart__section {
  position: relative;
}

.cart__section:not(:first-child) {
  padding-top: 1rem;
  padding-top: calc(var(--page-margin) * .5);
  border-top: 1px solid rgba(0, 0, 0, .13);
  border-top: 1px solid var(--color-thin-line);
}

.cart__section:not(:last-child) {
  padding-bottom: 1rem;
  padding-bottom: calc(var(--page-margin) * .5);
}

.cart__section--sum {
  display: flex;
}

.cart__sum-label {
  min-width: calc(50% - 17rem);
  min-width: calc((100% - var(--page-content-width)) / 2);
  padding-right: .5rem;
}

.cart__sum {
  flex: 1;
}

@media (max-width: 1199px) {
  .cart__section--product {
    border-top: 1px solid rgba(0, 0, 0, .13);
    border-top: 1px solid var(--color-thin-line);
  }
}

@media (max-width: 859px) {
  .cart__section--sum {
    flex-direction: column;
  }

  .cart__sum-label {
    letter-spacing: .03rem;
    text-transform: uppercase;
    margin-bottom: .25rem;
    font-size: .75rem;
    font-weight: 600;
  }
}

@media (max-width: 767px) {
  .cart__section:not(:first-child) {
    padding-top: 2rem;
    padding-top: var(--page-margin);
  }

  .cart__section:not(:last-child) {
    padding-bottom: 2rem;
    padding-bottom: var(--page-margin);
  }
}

.cart-product {
  gap: 1rem;
  gap: var(--form-spacing-x);
  grid-template-columns: auto minmax(0, 1fr);
  font-size: .8rem;
  display: grid;
}

.cart-product__heading {
  font-size: 1rem;
}

.cart-product__heading:not(:last-child) {
  margin-bottom: .35rem;
}

.cart-product__description, p.cart-product__description {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: .35rem;
  overflow: hidden;
}

.cart-product__description:not(:last-child) {
  margin-bottom: .5rem;
}

.cart-product__specification {
  width: 100%;
  border-collapse: collapse;
  margin-top: .35rem;
}

.cart-product__specification th, .cart-product__specification td {
  vertical-align: top;
  padding: 0;
}

.cart-product__specification tr + tr th, .cart-product__specification tr + tr td {
  padding-top: 0;
}

.cart-product__specification th {
  text-align: left;
  width: 1px;
  letter-spacing: .03rem;
  text-transform: uppercase;
  padding-right: .3rem;
  font-size: .75rem;
  font-weight: 600;
}

.cart-product__specification:not(:last-child) {
  margin-bottom: .5rem;
}

.cart-product__actions {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .25rem;
  display: flex;
}

.cart-product__actions .button {
  text-align: center;
}

@media (min-width: 768px) {
  .cart-product__details {
    min-width: 0;
    flex: 1;
  }

  .cart-product__image {
    width: 7rem;
    height: 7rem;
  }
}

@media (max-width: 767px) {
  .cart-product__image {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: .25rem;
  }

  .cart-product__actions {
    font-size: .7rem;
  }

  .cart-product__actions .button {
    padding: .2rem .5rem;
    font-size: .7rem;
  }
}

.product {
  display: flex;
}

.product__image img {
  max-width: 100%;
  height: auto;
  display: block;
}

@media (min-width: 992px) {
  .product__details, .product__image {
    width: 50%;
  }

  .product--wide-details .product__details {
    width: 63%;
  }

  .product--wide-details .product__image {
    width: 37%;
  }

  .product--wide-details .product__details {
    min-height: 25rem;
  }
}

@media (max-width: 991px) {
  .product {
    flex-direction: column;
  }

  .product__image {
    margin: 2rem;
    margin: var(--page-margin);
    margin-bottom: 0;
  }

  .product__details {
    order: 2;
  }
}

@media (max-width: 991px) {
  .card-product {
    --page-content-width: 30rem;
    flex-direction: column;
  }

  .card-product__image {
    order: 2;
  }
}

@media (min-width: 992px) {
  .card-product {
    grid-template-columns: auto 45%;
    display: grid;
  }
}

@media (min-width: 1200px) {
  .card-product {
    grid-template-columns: auto 35%;
  }
}

.contact-card {
  max-width: 20rem;
}

.contact-card__image {
  width: 100%;
  height: auto;
  display: block;
}

.contact-card__name {
  margin-bottom: .25rem;
  font-size: 1.3rem;
}

.contact-card a {
  text-decoration: none;
}

.contact-card a:hover {
  text-decoration: underline;
}

.contact-card a[href^="mailto:"] {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
}

.contact-card__info {
  padding-top: 1rem;
  line-height: 1.6;
}

.contact-card--highlight .contact-card__info {
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
  padding: 1rem;
}

.order-summary {
  border-top: 1px solid rgba(0, 0, 0, .13);
  border-top: 1px solid var(--color-thin-line);
  font-size: .85rem;
}

.order-summary__receipt {
  padding: 2rem 3rem;
  padding: var(--page-margin) calc(var(--page-margin) * 1.5);
}

.order-summary__info {
  padding: 2rem 3rem;
  padding: var(--page-margin) calc(var(--page-margin) * 1.5);
  background-color: #f0f8f0;
  background-color: var(--color-pale-green);
}

@media (min-width: 768px) {
  .order-summary__info {
    padding-left: 1rem;
    padding-left: var(--grid-gutter);
  }
}

.order-summary__list dt {
  margin-bottom: .5rem;
  display: block;
}

.order-summary__list dd {
  margin-left: 0;
}

.order-summary__list dd:not(:last-child) {
  margin-bottom: 1.25rem;
}

@media (min-width: 576px) {
  .order-summary__list {
    column-count: 2;
    column-gap: 1rem;
    column-gap: var(--grid-gutter);
  }

  .order-summary__list dt {
    break-after: avoid-column;
  }
}

.order-summary__amount {
  font-size: 1.3rem;
}

.checkmark-list {
  font-size: .85rem;
  list-style: none;
}

:not(.content) > .checkmark-list {
  padding-left: 0;
}

.checkmark-list li + li {
  margin-top: 0;
}

.checkmark-list__item {
  white-space: normal;
  padding: 0 0 0 1.05rem;
  display: block;
}

.checkmark-list .checkmark-list__item {
  margin-left: 0;
}

.checkmark-list__item:not(:last-child) {
  margin-bottom: .25rem;
}

.checkmark-list__item:before {
  content: "";
  width: 1rem;
  height: .8rem;
  background-image: url("data:image/svg+xml;utf8,<svg width=\"16\" height=\"16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M1.333 8L6 12.667 14.667 4\" stroke=\"%23178972\" stroke-width=\"1.714\"/></svg>");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: .8rem .8rem;
  margin-bottom: -.1rem;
  margin-left: -1rem;
  display: inline-block;
}

a.attachment {
  cursor: pointer;
  text-decoration: none;
}

a.attachment:hover {
  text-decoration: underline;
}

.attachment__image {
  background-color: #f0f8f0;
  background-color: var(--color-pale-green);
  display: block;
  position: relative;
  overflow: hidden;
}

.attachment__image:before {
  content: "";
  height: 0;
  padding-top: 66.6%;
  display: block;
}

.attachment__image > img, .attachment__image > noscript > img {
  width: 100%;
  height: auto;
  z-index: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@supports (object-fit: contain) {
  .attachment__image > img, .attachment__image > noscript > img {
    height: 100%;
    object-fit: contain;
    bottom: 0;
  }
}

.attachment__name {
  margin-top: .5rem;
  font-size: .85rem;
  line-height: 1.2;
  display: block;
}

.loading {
  color: #555;
  display: inline-block;
}

.loading:before {
  content: "";
  width: .85em;
  height: .85em;
  vertical-align: -.1em;
  background: url("loading.8ac04ddc.svg") 0 0 / .85em .85em no-repeat;
  animation: 1s linear infinite loading-animation;
  display: inline-block;
}

.loading__label {
  margin-left: .5rem;
}

.loading--margin-left {
  margin-left: .65rem;
}

.loading--negative {
  color: #fff;
}

.loading--negative:before {
  background-image: url("loading-negative.9cc8d7f6.svg");
}

@keyframes loading-animation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.share {
  border-top: 1px solid rgba(0, 0, 0, .13);
  border-top: 1px solid var(--color-thin-line);
  margin-top: 2.25rem;
  padding-top: .75rem;
}

.share__social-links a > img {
  width: .85rem;
  height: .85rem;
  margin-right: .35rem;
  position: relative;
  top: -.05rem;
}

.share__social-links a.symbol-button > img:only-child {
  margin-right: .35rem;
}

.share ul {
  padding-left: 0;
}

.share li {
  margin-left: 0;
  display: block;
}

.share li + li {
  margin-top: 0;
}

.share__heading {
  margin-bottom: 0;
}

.share__social-links, ul.share__social-links {
  flex-wrap: wrap;
  margin-top: .5rem;
  font-size: .8rem;
  display: flex;
}

.share__social-links li:not(:last-child) {
  margin-right: 1.25rem;
}

.waiting-for-signature {
  text-align: center;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  font-size: 1.1rem;
}

.waiting-for-signature__symbol {
  margin-bottom: 1rem;
}

.waiting-for-signature__message {
  max-width: 19rem;
  margin-left: auto;
  margin-right: auto;
}

.waiting-for-signature__message--qr {
  max-width: 24rem;
}

.waiting-for-signature__qr {
  max-width: 100%;
  width: 8rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.waiting-for-signature__button, .waiting-for-signature__qr:not(:last-child) {
  margin-bottom: 1rem;
}

.waiting-for-signature__loading {
  margin-top: .25rem;
  font-size: .85rem;
  display: block;
}

.js [data-hide-before-load], .no-js form [data-hide-before-load] {
  display: none;
}

.visually-hidden {
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute !important;
}

.package-select__top {
  flex-direction: column;
  align-items: center;
  padding: 1.25rem 1rem 1.5rem;
  display: flex;
}

@media (min-width: 576px) {
  .package-select__input {
    font-size: 1.5rem;
  }

  .package-select__input > input {
    width: 100%;
    max-width: 9.5rem;
  }
}

@media (min-width: 768px) {
  .package-select__input {
    font-size: 2rem;
  }

  .package-select__input > input {
    max-width: 12rem;
  }
}

.package-select__input > input::-ms-input-placeholder {
  color: #b5b5b5;
}

.package-select__input > input::placeholder {
  color: #b5b5b5;
}

.package-select__recommend {
  margin-top: 1rem;
}

.package-select__list {
  padding: 2rem;
  padding: var(--page-margin);
  border-radius: .25rem .25rem 0 0;
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  grid-template-rows: repeat(4, auto);
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-auto-flow: column;
  margin-top: 1rem;
  display: grid;
  margin-bottom: 0 !important;
}

@media (max-width: 575px) {
  .package-select__list {
    grid-template-rows: auto;
    grid-template-columns: minmax(0, 1fr);
    grid-auto-flow: row;
  }
}

.package-select__actions {
  text-align: center;
  background-color: #edf3fd;
  background-color: var(--color-light-blue);
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  border-radius: 0 0 .25rem .25rem;
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
  padding: 1rem 2rem;
  padding: 1rem var(--page-margin);
  border-top: 0;
}

.package-asset__content {
  padding: 3rem;
  padding: calc(var(--page-margin) * 1.5);
}

.package-asset__image {
  padding: 3rem;
  padding: calc(var(--page-margin) * 1.5);
  background-color: #fcfcfc;
  background-color: var(--color-light-gray);
  justify-content: center;
  align-items: center;
  display: flex;
}

.package-asset__video {
  background-color: #333;
  position: relative;
}

@media (min-width: 768px) {
  .package-asset {
    display: flex;
  }

  .package-asset__content {
    width: 55%;
  }

  .package-asset__image {
    width: 45%;
    order: 2;
  }

  .package-asset__video {
    width: 66%;
    order: 2;
  }
}

.package-asset--gallery {
  display: block;
}

.package-asset__gallery {
  padding: 3rem;
  padding: calc(var(--page-margin) * 1.5);
  column-count: 3;
  column-gap: 1.5rem;
  padding-top: 0;
}

.package-asset__gallery-image {
  margin-bottom: 1.5rem;
}

.package-asset__gallery-image > img {
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 575px) {
  .package-asset__gallery {
    column-count: 2;
    column-gap: 1rem;
  }

  .package-asset__gallery-image {
    margin-bottom: 1rem;
  }
}

@media (max-width: 399px) {
  .package-asset__gallery {
    column-count: 2;
    column-gap: .75rem;
  }

  .package-asset__gallery-image {
    margin-bottom: .75rem;
  }
}

@media (max-width: 767px) {
  [data-business-packages] .packages--desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  [data-business-packages] .packages--mobile {
    display: none;
  }
}

.packages {
  border-bottom: 1px solid rgba(0, 0, 0, .13);
  border-bottom: 1px solid var(--color-thin-line);
  padding-left: 0;
  display: flex;
}

.packages:not(:last-child) {
  margin-bottom: 0;
}

.packages__package {
  width: 25%;
  border-top: .3rem solid #f0f8f0;
  border-top: .3rem solid var(--color-pale-green);
  display: block;
  position: relative;
  overflow: hidden;
}

.packages__package:before {
  content: "";
  width: .7rem;
  height: .6rem;
  opacity: 0;
  background-image: url("data:image/svg+xml;utf-8,<svg width=\"14\" height=\"12\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M.19098-1.5h13.61804L7 12.11803.19098-1.5z\" stroke=\"%23003798\" fill=\"%23003798\" stroke-linejoin=\"round\"/></svg>");
  background-repeat: no-repeat;
  background-size: .7rem .6rem;
  transition: transform .15s ease-out, opacity .15s ease-out;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-.7rem);
}

.packages__package:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, .13);
  border-right: 1px solid var(--color-thin-line);
}

.packages__heading {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .13);
  border-bottom: 1px solid var(--color-thin-line);
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1.4rem;
}

button.packages__heading {
  cursor: pointer;
  width: 100%;
  color: inherit;
  background-color: #fff;
}

button.packages__heading:focus {
  box-shadow: inset 0 0 0 .1rem #dedede;
  box-shadow: inset 0 0 0 .1rem var(--input-border-color--focus);
  border-color: rgba(0, 0, 0, .13);
  border-color: var(--color-thin-line);
  outline: 0;
}

.packages__name {
  margin-bottom: 0;
  font-size: 1.5rem;
}

.packages__name:not(:first-child) {
  margin-top: .5rem;
}

.packages__list {
  padding-left: 0;
  font-size: .85rem;
  list-style: none;
}

.packages__list-item {
  padding: .6rem 1rem .6rem 1.5rem;
  display: block;
}

.packages__list-item:before {
  content: "";
  width: 1.2rem;
  height: .8rem;
  vertical-align: -.2rem;
  background-image: url("data:image/svg+xml;utf8,<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M2 6H10\" stroke=\"%23ccc\"/></svg>");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: .8rem .8rem;
  margin-left: -1.2rem;
  display: inline-block;
}

.packages__list-item--check:before {
  background-image: url("data:image/svg+xml;utf8,<svg width=\"12\" height=\"12\" viewbox=\"0 0 12 12\" xmlns=\"http://www.w3.org/2000/svg\"><path stroke=\"%23178972\" fill=\"none\" d=\"M.5 5.5l4 4 7-7\"/></svg>");
  animation: .35s cubic-bezier(.25, 2.2, .5, 1) pop-in;
}

@keyframes pop-in {
  0% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

.packages--mobile {
  flex-direction: column;
}

.packages--mobile .packages__list-item:nth-child(odd) {
  background-color: #f7fdf7;
}

.packages--mobile .packages__list-item small {
  display: none;
}

.packages--mobile .packages__list-item--divider {
  padding-right: 4.5rem;
  position: relative;
}

.packages--mobile .packages__list-item--divider small {
  color: #178972;
  color: var(--color-green);
  margin-left: .5rem;
  font-size: .75rem;
  transition: inherit;
  display: block;
  position: absolute;
  top: .5rem;
  right: 1rem;
}

.packages__package {
  transition-property: color, border-top-color, box-shadow;
  transition-duration: .15s;
  transition-timing-function: ease-out;
}

.packages__package--dim {
  color: rgba(0, 0, 0, .37);
}

.packages__package--highlight {
  border-top-color: #1c3f8c;
  border-top-color: var(--color-blue);
  box-shadow: 0 .15rem .8rem rgba(98, 108, 104, .08);
  box-shadow: var(--shadow-medium);
  z-index: 2;
  position: relative;
}

.packages__package--highlight:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, .13);
}

.packages__package--highlight:before {
  opacity: 1;
  transform: translateY(0);
}

.packages__list-item {
  transition-property: color, border-color, background-color;
  transition-duration: .15s;
  transition-timing-function: ease-out;
}

.packages__list-item--dim, .packages__list-item--dim.packages__list-item--divider small {
  color: rgba(0, 0, 0, .37);
}

.packages--mobile .packages__list-item--dim:nth-child(odd) {
  background-color: #fff;
}

.packages--mobile .packages__list-item--dim {
  border-color: #e5e5e5;
}

.packages--mobile .packages__list-item--highlight + .packages__list-item--dim {
  border-color: #a7c7bf;
}

.packages__package--dim .packages__list-item:before, .packages__list-item--dim:before {
  background-image: url("data:image/svg+xml;utf8,<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M1.5 5.5H8.5\" stroke=\"black\" stroke-opacity=\"0.30\"/></svg>");
}

@media (min-width: 768px) {
  .campaign {
    position: relative;
  }

  .campaign__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .campaign__image--mobile {
    display: none;
  }

  .campaign__content {
    color: #fff;
    z-index: 2;
    max-width: 34rem;
    padding: 3rem;
    position: relative;
  }

  .campaign__heading {
    font-size: 4.5rem;
    font-weight: normal;
    line-height: .85;
  }
}

@media (max-width: 767px) {
  .campaign {
    text-align: center;
  }

  .campaign__image--desktop {
    display: none;
  }

  .campaign__heading {
    margin-bottom: .5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .campaign__content {
    padding-top: 1rem;
  }

  .campaign__button-set {
    width: 100%;
    justify-content: center;
  }
}

.popover {
  z-index: 200;
  background-color: rgba(0, 0, 0, .2);
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.popover.popover--open {
  opacity: 1;
  pointer-events: auto;
  display: flex;
}

.popover__box {
  width: 28rem;
  width: calc(20rem + var(--page-margin) * 4);
  max-width: 95vw;
  height: auto;
  padding: 4rem;
  padding: calc(var(--page-margin) * 2);
  -webkit-overflow-scrolling: touch;
  text-align: center;
  background-color: #fff;
  border-radius: .15rem;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
}

.popover--wide .popover__box {
  width: 38rem;
  width: calc(30rem + var(--page-margin) * 4);
}

.popover--products .popover__box {
  text-align: left;
  padding: 1.2rem;
}

.popover__close {
  z-index: 210;
  color: rgba(0, 0, 0, 0);
  width: 1.8rem;
  height: 1.8rem;
  text-indent: 2rem;
  white-space: nowrap;
  cursor: pointer;
  background-color: #000;
  background-image: url("data:image/svg+xml,<svg width=\"16\" height=\"16\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M1 1l14 14M1 15L15 1\" stroke=\"%23FFF\" stroke-width=\"2\" fill=\"none\" /></svg>");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 1rem;
  position: fixed;
  top: 1rem;
  right: 1rem;
  overflow: hidden;
}

.popover__close:active {
  transform: scale(.95);
}

.popover__actions {
  flex-direction: column;
  align-items: center;
  margin-top: 1.25rem;
  display: flex;
}

.popover__actions > :not(:first-child) {
  margin-top: .5rem;
}

@media (min-width: 577px) {
  .popover__actions--horizontal {
    flex-direction: row;
    justify-content: center;
  }

  .popover__actions > :not(:first-child) {
    margin-top: 0;
  }

  .popover__actions--horizontal > :not(:first-child) {
    margin-left: .5rem;
  }
}

.admin-bar .popover {
  top: 32px;
}

.admin-bar .popover__close {
  top: calc(32px + 1rem);
}

@media screen and (max-width: 782px) {
  .admin-bar .popover {
    top: 46px;
  }

  .admin-bar .popover__close {
    top: calc(46px + 1rem);
  }
}

@media screen and (max-width: 600px) {
  .admin-bar .popover {
    top: 0;
  }

  .admin-bar .popover__close {
    top: 1rem;
  }
}

.box-example {
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  border-radius: .25rem;
  border-radius: var(--input-border-radius);
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.box-example__image {
  background-color: #fcfcfc;
  background-color: var(--color-light-gray);
  min-height: 7.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.box-example__title {
  border-top: 1px solid rgba(0, 0, 0, .13);
  border-top: 1px solid var(--color-thin-line);
  padding: .75rem;
}

.card-icon {
  display: block;
}

.card-icon * {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card-icon--pdf {
  width: 4.25rem;
  height: 6rem;
  position: relative;
}

.card-icon--pdf .card-icon__card {
  width: 4.25rem;
  height: 6rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .04), 0 4px 10px rgba(0, 0, 0, .1);
}

.card-icon--pdf:after {
  content: "PDF";
  background-color: #1c3f8c;
  background-color: var(--color-blue);
  color: #fff;
  border-radius: .15rem;
  padding: .1rem .3rem;
  font-size: .7rem;
  position: absolute;
  top: 4.1rem;
  left: -.6rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
}

.card-icon--pdf .card-icon__image {
  width: 3.75rem;
  height: 2.35rem;
  position: absolute;
  top: .25rem;
  left: .25rem;
}

.card-icon--pdf .card-icon__heading {
  width: 3.2rem;
  height: auto;
  position: absolute;
  top: 2.6rem;
  left: .5rem;
}

.card-icon--folded {
  width: 7.75rem;
  height: 7.75rem;
  perspective: 15rem;
  perspective-origin: 50%;
  transform-style: preserve-3d;
  position: relative;
}

.card-icon--folded .card-icon__heading {
  width: 4rem;
  position: absolute;
  top: 1.35rem;
  left: .15rem;
}

.card-icon--folded .card-icon__page-1 img, .card-icon--folded .card-icon__page-2 img, .card-icon--folded .card-icon__page-3 img {
  max-width: 100%;
  height: auto;
}

.card-icon--folded .card-icon__page-1, .card-icon--folded .card-icon__page-2, .card-icon--folded .card-icon__page-3, .card-icon--folded:after {
  width: 4.25rem;
  height: 6rem;
  transform-origin: 0%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition-property: transform, filter, box-shadow, opacity;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.7, 0, .1, 1);
  display: none;
  position: absolute;
  top: .9rem;
  left: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0), 0 4px 10px rgba(0, 0, 0, 0);
}

.card-icon--folded:after {
  content: "";
  height: 3rem;
  width: 2rem;
  opacity: .05;
  background: linear-gradient(to right, #000, rgba(0, 0, 0, 0));
  display: block;
  top: 5rem;
  left: 5.5rem;
  transform: translateX(.8rem)scaleX(1)scaleY(1)translateY(.2rem)translateZ(-5rem)rotateX(-30deg)rotateX(90deg)rotateY(-10deg);
}

.box-example--active .card-icon--folded:after {
  opacity: 0;
  transform: translateX(-5rem)scaleX(3)scaleY(2.1)translateY(-1rem)translateZ(-2rem)rotateX(-5deg)rotateX(0)rotateY(0);
}

.card-icon--folded .card-icon__page-1, .card-icon--folded .card-icon__page-2, .card-icon--folded .card-icon__page-3 {
  will-change: transform, box-shadow;
  display: block;
  overflow: hidden;
}

.card-icon--folded .card-icon__page-1 {
  z-index: 4;
  transform: translateX(-50%)translateZ(0)rotateX(-30deg)rotateY(-10deg);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 4px 10px rgba(0, 0, 0, 0);
}

.card-icon--folded .card-icon__page-2 {
  filter: brightness(.8);
  z-index: 3;
  transform: translateX(-50%)translateZ(0)rotateX(-30deg)rotateY(170deg)translateX(-100%);
}

.card-icon--folded .card-icon__page-3 {
  filter: brightness(.9);
  z-index: 2;
  transform: translateX(-50%)translateZ(0)rotateX(-30deg)rotateY(20deg);
}

.box-example--active .card-icon--folded .card-icon__page-1, .box-example--active .card-icon--folded .card-icon__page-2, .box-example--active .card-icon--folded .card-icon__page-3 {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .04), 0 4px 10px rgba(0, 0, 0, .1);
}

.box-example--active .card-icon--folded .card-icon__page-1 {
  transform: translateX(0%)translateZ(-1rem)rotateX(-5deg)rotateY(-170deg);
}

.box-example--active .card-icon--folded .card-icon__page-2 {
  filter: brightness();
  transform: translateX(0%)translateZ(-1rem)rotateX(-5deg)rotateY(10deg)translateX(-100%);
}

.box-example--active .card-icon--folded .card-icon__page-3 {
  filter: brightness();
  transform: translateX(0%)translateZ(-1rem)rotateX(-5deg)rotateY(-20deg);
}

.christmas-campaign {
  color: #f5ecdf;
  color: var(--color-beige);
  background-color: #d52822;
  background-color: var(--color-christmas-2023);
  position: relative;
}

.page-section + .page-section.christmas-campaign {
  margin-top: 2rem;
}

.page-section.christmas-campaign + .page-navigation {
  margin-top: 0;
}

.christmas-campaign__inner {
  align-items: center;
  display: flex;
}

.christmas-campaign .button, .christmas-campaign .button:hover {
  color: #fff;
  background-color: #1c3f8c;
  background-color: var(--color-blue);
  border-color: #1c3f8c;
  border-color: var(--color-blue);
}

.christmas-campaign__content, .christmas-campaign__image {
  z-index: 2;
  position: relative;
}

@media (min-width: 768px) {
  .christmas-campaign__inner {
    flex-direction: row-reverse;
  }

  .christmas-campaign__image {
    width: 45%;
    flex: 1;
    padding-bottom: 1rem;
    padding-right: 2rem;
  }

  .christmas-campaign__heading {
    font-size: 3.75rem;
    font-weight: normal;
    line-height: .95;
  }

  .christmas-campaign__content {
    max-width: 34rem;
    padding: 2rem 3rem;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  .christmas-campaign__heading {
    font-size: 5.85938vw;
  }

  .christmas-campaign__content {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .christmas-campaign__inner {
    flex-direction: column;
  }

  .christmas-campaign__image {
    width: 95%;
    padding: .5rem 2rem 0;
    padding: .5rem var(--page-margin) 0;
  }

  .christmas-campaign__heading {
    width: 100%;
    max-width: 10em;
    margin-left: auto;
    margin-right: auto;
  }

  .christmas-campaign__content {
    padding-left: 2rem;
    padding-left: var(--page-margin);
    padding-right: 2rem;
    padding-right: var(--page-margin);
    text-align: center;
  }

  .christmas-campaign__button-set {
    width: 100%;
    justify-content: center;
  }
}

.company-presentation {
  display: flex;
}

.company-presentation:not(:first-child) {
  margin-top: 1rem;
}

p + .company-presentation:not(:first-child) {
  margin-top: 1.5rem;
}

.company-presentation:not(:last-child) {
  margin-bottom: 1.5rem;
}

.company-presentation__image {
  margin-right: 1.5rem;
  margin-right: calc(var(--grid-gutter) * 1.5);
  width: 10rem;
  flex-shrink: 0;
  margin-top: .2em;
}

.company-presentation__content h3:first-child, .company-presentation__content .h3:first-child {
  margin-top: .2rem;
}

.company-presentation__content h3:not(:last-child), .company-presentation__content .h3:not(:last-child) {
  margin-bottom: .45rem;
}

@media (max-width: 1199px) {
  .company-presentation__image {
    width: 6rem;
    margin-right: 1rem;
    margin-right: var(--grid-gutter);
  }
}

@media (max-width: 567px) {
  .company-presentation__content {
    font-size: .8rem;
  }

  .company-presentation__image {
    width: 3.5rem;
  }
}

.press-release {
  display: flex;
}

.press-release__content {
  max-width: 34rem;
  max-width: var(--page-content-width);
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  margin: 1rem;
  display: flex;
}

.press-release__snippet {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
}

.press-release__actions {
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .press-release__image {
    width: calc(50% - 1rem);
    width: calc(50% - var(--grid-gutter));
  }
}

@media (max-width: 767px) {
  .press-release {
    flex-direction: column;
  }
}

.press-mention {
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  align-items: center;
  display: flex;
}

.press-mention--image-first {
  flex-direction: row-reverse;
}

.press-mention:not(:last-child) {
  margin-bottom: 1rem;
}

a.press-mention {
  color: inherit;
  text-decoration: none;
}

.press-mention__content {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.press-mention__heading {
  flex: 1;
  padding: 1rem;
}

.press-mention__content .press-mention__heading:not(:last-child) {
  margin-bottom: .75rem;
}

.press-mention__image {
  width: 100%;
  max-width: 6rem;
}

.press-mention__image > img {
  max-width: 100%;
  display: block;
}

.press-mention--large {
  padding: 2rem;
  padding: var(--page-margin);
}

@media (min-width: 769px) {
  .press-mention--large {
    padding: 1rem;
  }
}

.press-mention--large .press-mention__content, .press-mention--large .press-mention__heading {
  margin-right: 1rem;
  padding: 0;
}

.press-mention--large .press-mention__content > .press-mention--large .press-mention__heading {
  margin-right: 0;
}

.small-text {
  --paragraph-margin: .75rem;
  font-size: .85rem;
}

.asset-list__item {
  --paragraph-margin: .75rem;
}

.asset-list__image {
  border: .05rem solid rgba(0, 0, 0, .13);
  border: .05rem solid var(--color-thin-line);
  margin-bottom: 1rem;
  padding: 1rem;
  display: block;
}

.asset-list__image > img {
  max-width: 100%;
  display: block;
}

.asset-list__symbol {
  z-index: 2;
  width: 1.75rem;
  height: 1.75rem;
  border: 1px solid #1c3f8c;
  border: 1px solid var(--color-blue);
  background: #fff url("download-arrow-blue.bd4a52b8.svg") center no-repeat;
  border-radius: 1.75rem;
  transition-property: color, box-shadow, border-color, background-color;
  transition-duration: .1s, .1s, .1s, .15s;
  transition-timing-function: ease-out;
  position: absolute;
  bottom: .75rem;
  left: .75rem;
}

a:hover .asset-list__symbol {
  background-color: #164dad;
  background-image: url("download-arrow-white.98fe4ad9.svg");
  border-color: #164dad;
  box-shadow: 0 3px 16px rgba(0, 0, 0, .3);
}

.asset-list__meta {
  grid-template-columns: auto 1fr;
  font-size: .85rem;
  display: grid;
}

.asset-list__meta dt, .asset-list__meta dd {
  margin-left: 0;
  padding-bottom: .25rem;
  padding-right: 1rem;
}

@supports (object-fit: contain) {
  .asset-list__image {
    position: relative;
  }

  .asset-list__image:before {
    content: "";
    height: 0;
    width: 100%;
    padding-top: 90%;
    display: block;
  }

  .asset-list__image > img {
    object-fit: contain;
    width: calc(100% - 3rem);
    height: calc(100% - 2rem);
    position: absolute;
    top: 1rem;
    left: 1.5rem;
  }
}

.adoveo-iframe:not(:first-child) {
  margin-top: 2rem;
}

.adoveo-iframe:not(:last-child) {
  margin-bottom: 2rem;
}

.adoveo-iframe--greeting .iframe-container {
  height: 500px;
  padding-top: 0;
}

.key-figure {
  border-left: 1px solid rgba(0, 0, 0, .16);
  padding: 1rem 1.3rem 1.1rem;
}

.key-figure h2 {
  font-size: 2rem;
}

.key-figure h2:not(:last-child) {
  margin-bottom: .15rem;
}

.key-figure p {
  font-size: .8rem;
}

.signature {
  display: flex;
}

.signature__image {
  height: 6.55rem;
  width: 6.55rem;
  flex-shrink: 0;
  margin-right: 1.5rem;
}

.signature__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.signature__content {
  flex-direction: column;
  justify-content: end;
  display: flex;
}

.signature__signature {
  height: 2.5rem;
  width: auto;
}

.signature__signature img {
  height: 100%;
  width: 12rem;
  max-width: 100%;
  object-fit: contain;
  object-position: top left;
}

.signature__text p:not(:last-child) {
  margin-bottom: 0;
}

.content > .signature:not(:first-child) {
  margin-top: 1.5rem;
}

.content > .signature:not(:last-child) {
  margin-bottom: 1.5rem;
}

.pie-chart__heading {
  text-transform: uppercase;
  font-size: .75rem;
}

.pie-chart__heading:not(:last-child) {
  margin-bottom: 1.2rem;
}

.pie-chart__container {
  align-items: center;
  display: flex;
}

.pie-chart__pie-chart {
  margin-right: 1.8rem;
}

.pie-chart__item {
  justify-content: space-between;
  font-size: .8rem;
  display: flex;
}

.pie-chart__item dt {
  text-indent: -1.15rem;
  margin-left: 1rem;
}

.pie-chart__item dt svg {
  vertical-align: -.1rem;
  margin-right: .2rem;
}

.pie-chart__item dd {
  margin-left: .5rem;
}

@media (max-width: 991px) {
  .pie-chart {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 576px) {
  .pie-chart__container {
    flex-direction: column;
    margin-bottom: 1rem;
    display: flex;
  }

  .pie-chart__pie-chart {
    margin-bottom: 1rem;
  }
}

@media (max-width: 375px) {
  .pie-chart__description {
    width: 100%;
  }
}

.document {
  grid-column-gap: 1rem;
  grid-column-gap: var(--grid-gutter);
  grid-template-rows: auto auto;
  grid-template-columns: auto minmax(0, 1fr);
  display: grid;
}

.document__image {
  max-width: 5rem;
  grid-area: 2 / 1 / 4;
}

.document__title {
  grid-area: 2 / 2;
  align-self: end;
}

.document__download {
  grid-area: 3 / 2;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media (min-width: 576px) {
  .text-left--sm {
    text-align: left;
  }

  .text-center--sm {
    text-align: center;
  }

  .text-right--sm {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .text-left--md {
    text-align: left;
  }

  .text-center--md {
    text-align: center;
  }

  .text-right--md {
    text-align: right;
  }
}

@media (min-width: 992px) {
  .text-left--lg {
    text-align: left;
  }

  .text-center--lg {
    text-align: center;
  }

  .text-right--lg {
    text-align: right;
  }
}

@media (min-width: 1200px) {
  .text-left--xl {
    text-align: left;
  }

  .text-center--xl {
    text-align: center;
  }

  .text-right--xl {
    text-align: right;
  }
}

.steps {
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.steps > .steps__step {
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.steps > .steps__step--animated {
  transition: width .25s ease-out;
}

.steps > .steps__step:not(.steps__step--wide), .steps > .steps__step.steps__step--wide[aria-expanded="false"] {
  width: 34rem;
  width: var(--page-content-max-width);
}

.step {
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  border-radius: .25rem;
  border-radius: var(--card-border-radius);
}

.step__indicator {
  margin-left: 1rem;
}

.step__header {
  min-height: 4.5rem;
  grid-template-columns: auto minmax(0, 1fr) auto;
  align-items: center;
  padding-right: 1rem;
  display: grid;
}

.step__heading {
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.step__actions {
  color: #888;
  color: var(--input-placeholder-color);
  font-size: .85rem;
  display: grid;
}

.step__actions > * {
  text-align: right;
  grid-area: 1 / 1;
}

.step__body {
  padding: 1rem;
}

.step__body:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, .13);
  border-top: 1px solid var(--color-thin-line);
}

.step__body--flush {
  padding: 0;
}

.step__buttons {
  border-top: 1px solid rgba(0, 0, 0, .13);
  border-top: 1px solid var(--color-thin-line);
  text-align: center;
  background-color: #edf3fd;
  background-color: var(--color-light-blue);
  padding: 1rem;
}

.step__buttons-message {
  text-align: center;
}

.step-indicator {
  border: 1px solid #178972;
  border: 1px solid var(--color-green);
  width: 1.1rem;
  height: 1.1rem;
  color: #178972;
  color: var(--color-green);
  text-align: center;
  background-color: #fff;
  border-radius: 1.1rem;
  font-size: .7rem;
  line-height: 1.55;
  display: inline-block;
  position: relative;
}

.step-indicator--filled {
  color: #fff;
  background-color: #178972;
  background-color: var(--color-green);
}

.contact-form {
  border: 1px solid #dedede;
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  display: flex;
}

.contact-form__form {
  padding: 3rem 4rem;
}

.contact-form__contact {
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
  align-items: center;
  padding: 1.7rem 2rem 1.4rem;
  display: flex;
}

.contact-form__content {
  text-align: center;
  flex-direction: column;
  align-items: center;
  font-size: .9rem;
  display: flex;
}

.contact-form__image {
  height: 8.75rem;
  width: 8.75rem;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 2rem;
  display: block;
}

.contact-form__contact-details {
  flex-direction: column;
  display: flex;
}

.contact-form__name {
  font-size: 1rem;
  font-weight: 700;
}

.contact-form__name:not(:last-child) {
  margin-bottom: .3rem;
}

.contact-form__title:not(:last-child) {
  margin-bottom: 0;
}

.contact-form__phone {
  color: #000;
  text-decoration: none;
}

.contact-form__form .hf-message.hf-message-success {
  color: #222;
  color: var(--color-text);
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
  border-radius: .25rem;
  border-radius: var(--input-border-radius);
  margin-top: 1rem;
  padding: .6rem;
  font-size: .8rem;
}

@media (max-width: 900px) {
  .contact-form {
    flex-direction: column;
    display: flex;
  }

  .contact-form__form {
    padding: 1.5rem;
  }

  .contact-form__contact {
    padding: 1.7rem 1.5rem 1.4rem;
  }

  .contact-form__content {
    text-align: left;
    flex-direction: row;
    align-items: flex-start;
    display: flex;
  }

  .contact-form__image {
    height: 4.4rem;
    width: 4.4rem;
    margin-bottom: 0;
    margin-right: .8rem;
  }
}

@media (max-width: 460px) {
  .contact-form__email {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 7.5rem;
    overflow: hidden;
  }
}

@media (max-width: 375px) {
  .contact-form__form {
    padding: .5rem;
  }
}

.video {
  width: 100%;
  background-color: #fff;
  flex-shrink: 0;
  position: relative;
}

.video:before {
  content: "";
  height: 0;
  padding-top: 75%;
  display: block;
}

.video--16-9:before {
  padding-top: 56.25%;
}

.video--4-5:before {
  padding-top: 125%;
}

.video iframe, .video video, .video__placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video video, .video__placeholder {
  object-fit: cover;
}

.video__action {
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

label.video__action, label.video__action button {
  cursor: pointer;
}

.video__play {
  width: 5.3rem;
  height: 3.4rem;
  text-indent: 150%;
  white-space: nowrap;
  background: url("video-play.727ae4d4.svg") center / 5.3rem 3.4rem no-repeat;
  border: 0;
  transition: transform 1s cubic-bezier(0, 1, .2, 1);
  overflow: hidden;
}

label.video__action:hover .video__play {
  transform: scale(1.1);
}

.box {
  --box-spacing: 2rem;
  --box-background: var(--color-pale-blue);
  background-color: var(--box-background);
  padding: var(--box-spacing);
}

.content .box {
  margin-left: calc(var(--box-spacing) * -1);
  margin-right: calc(var(--box-spacing) * -1);
}

.page-card__content > .box {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575px) {
  .box {
    --box-spacing: 1rem;
  }
}

.christmas-2022 {
  width: 100%;
  height: 100%;
  padding-right: 2rem;
  padding-right: var(--page-margin);
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.christmas-2022__inner {
  height: auto;
  width: 26rem;
  max-width: 90%;
  display: block;
  position: relative;
}

.christmas-2022__inner:before {
  content: "";
  width: 100%;
  height: 0;
  padding-top: 100%;
  display: block;
}

@media (max-width: 767px) {
  .christmas-2022 {
    padding-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
  }

  .christmas-2022__inner {
    max-width: 70%;
  }
}

.christmas-2022__before, .christmas-2022__after, .christmas-2022__heart {
  height: auto;
  position: absolute;
}

.christmas-2022__before {
  width: 50%;
  top: 21%;
  left: 0;
  transform: rotate(-2deg);
}

.christmas-2022__after {
  width: 50%;
  bottom: 0;
  right: 8%;
  transform: rotate(2deg);
}

.christmas-2022__heart {
  width: 70%;
  top: 0;
  right: 0;
}

.christmas-2022__card > img, .christmas-2022__heart > img {
  width: 100%;
  height: auto;
  display: block;
}

.christmas-2022__heart > svg {
  animation: 1.5s linear infinite heartbeat;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.06);
  }

  20% {
    transform: scale(1.03);
  }

  30% {
    transform: scale(1.05);
  }

  40% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.cta {
  background-color: #f5ecdf;
  background-color: var(--color-beige);
  z-index: 99;
  --padding-y: 1.5rem;
  padding: var(--padding-y) 2rem calc(var(--padding-y)  + env(safe-area-inset-bottom));
  padding: var(--padding-y) var(--page-margin) calc(var(--padding-y)  + env(safe-area-inset-bottom));
  justify-content: center;
  transition: transform .2s linear;
  display: flex;
  position: sticky;
  bottom: 0;
}

.cta--hide {
  transform: translateY(100%);
}

.cta__link {
  white-space: nowrap;
  background-color: #009375;
  background-color: var(--color-light-green);
  border-color: #009375;
  border-color: var(--color-light-green);
  color: #fff;
  color: var(--color-white);
  text-transform: uppercase;
  font-family: HCo Gotham, sans-serif;
  font-weight: 700;
  position: relative;
}

.cta__link:not(:disabled):hover {
  background-color: #009375;
  background-color: var(--color-light-green);
  border-color: #009375;
  border-color: var(--color-light-green);
}

.cta__link:before {
  content: "";
  height: .9rem;
  width: .9rem;
  vertical-align: -.2rem;
  background-image: url("heart-white.b49e8e96.svg");
  background-repeat: no-repeat;
  margin-right: .6rem;
  display: inline-block;
}

.cta + .footer {
  margin-top: 0;
}

@media (max-width: 575px), (max-height: 625px) {
  .cta {
    --padding-y: 1rem;
  }
}

.donations-graph__header {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.h1.donations-graph__result {
  text-transform: none;
  letter-spacing: 2px;
  font-weight: 900;
}

.donations-graph__bar {
  color: #fff;
  background-color: #d9d9d9;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  position: relative;
}

.donations-graph__progress {
  z-index: 1;
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.donations-graph__progress:after {
  content: "";
  height: calc(100% + 1rem);
  width: 3px;
  background-color: #000;
  display: inline-block;
  position: absolute;
  bottom: -1rem;
  right: 0;
}

.donations-graph__status {
  text-align: left;
  letter-spacing: 2px;
  margin-top: 1rem;
  font-weight: 900;
}

.donations-graph__numbers {
  text-align: center;
  z-index: 2;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  display: grid;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.donations-graph__number {
  padding: 1.2rem 7%;
  font-size: .8rem;
}

.donations-graph__number:not(:last-child) {
  border-right: 2px solid #fff;
  border-right: 2px solid var(--color-white);
}

.donations-graph__details {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

@media (min-width: 576px) {
  .donations-graph__header h2:not(:last-child) {
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .donations-graph__bar {
    font-size: .7rem;
  }

  .donations-graph__number span {
    display: none;
  }
}

.campaign-header {
  margin-bottom: 1.6rem;
  padding-top: 1.1rem;
}

.campaign-header__container {
  align-items: center;
  column-gap: .25rem;
  display: flex;
}

.campaign-header__logo {
  justify-content: center;
  display: flex;
}

.campaign-header__campaign-logo {
  width: 3rem;
  height: 3rem;
}

@media (max-width: 767px) {
  .campaign-header {
    margin-bottom: .4rem;
    padding-top: 1.1rem;
  }

  .campaign-header__logo {
    margin-left: 0;
    margin-right: 0;
  }
}

.campaign-navbar {
  width: 100%;
  --header-height: 2.7rem;
  height: 3.8rem;
  height: var(--header-height);
  background-color: #000;
  position: relative;
}

.campaign-navbar__nav {
  text-transform: uppercase;
  background-color: #000;
  padding-left: 0;
  font-weight: 700;
  list-style: none;
  display: flex;
}

.campaign-navbar__nav a {
  color: #fff;
  text-decoration: none;
}

.campaign-navbar__hamburger {
  height: 2.2rem;
  width: 2.2rem;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("hamburger.9a9cd03b.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  margin-right: .3rem;
}

.campaign-navbar--open .campaign-navbar__hamburger {
  background-image: url("close.85eda751.svg");
}

@media (min-width: 768px) {
  .campaign-navbar__hamburger {
    display: none;
  }

  .campaign-navbar__nav {
    height: 100%;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .campaign-navbar {
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }

  .campaign-navbar__nav {
    display: none;
  }

  .campaign-navbar--open .campaign-navbar__nav {
    top: 3.8rem;
    top: var(--header-height);
    z-index: 99;
    flex-direction: column;
    align-items: start;
    padding: .2rem 1.4rem .8rem;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .campaign-navbar__nav a {
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: inline-block;
  }
}

.monthly-donation-concept {
  font-size: inherit;
  font-size: var(--font-size, inherit);
  --paragraph-margin: .5rem;
  line-height: 1.2;
}

.monthly-donation-concept > * {
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
}

.monthly-donation-concept__description {
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
}

.monthly-donation-concept__description:not(:last-child) {
  margin-bottom: 1rem;
}

.monthly-donation-concept__checkmark-list {
  font-size: var(--font-size);
  --paragraph-margin: .4rem;
}

@media (max-width: 575px) {
  .monthly-donation-concept {
    --font-size: .75rem;
  }

  .monthly-donation-concept__description {
    margin-top: .5rem;
  }
}

@media (min-width: 460px) {
  .monthly-donation-concept {
    --grid-gutter: 1rem;
  }
}

.icon-list {
  --font-size: .85rem;
  font-size: var(--font-size);
  list-style: none;
}

.icon-list .icon-list__item {
  --grid-gutter: .5rem;
  column-gap: 1rem;
  column-gap: var(--grid-gutter);
  grid-template-columns: auto minmax(0, 1fr);
  margin-left: 0;
  display: grid;
}

.icon-list__heading {
  font-size: var(--font-size);
}

.icon-list__heading:not(:last-child) {
  margin-bottom: 0;
}

.icon-list svg {
  margin-top: .05rem;
}

@media (max-width: 575px) {
  .icon-list {
    --font-size: .75rem;
  }

  .icon-list svg {
    margin-top: -.05rem;
  }
}

.image-text {
  text-align: center;
  padding: 1.2rem 1.2rem 2rem;
  font-size: .9rem;
}

.image-text--blue {
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
}

.tooltip {
  --size: 1.1rem;
  display: inline-block;
}

.tooltip__button {
  cursor: pointer;
  background: #1c3f8c url("help-symbol.cdc2e1f9.svg") center no-repeat;
  background: var(--color-blue) url("help-symbol.cdc2e1f9.svg") no-repeat center;
  background-size: var(--size) var(--size);
  width: var(--size);
  height: var(--size);
  white-space: nowrap;
  text-indent: 3rem;
  border: 0;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
}

.tooltip__tooltip {
  z-index: 10;
  background-color: #fff;
  background-color: var(--color-background);
  box-shadow: 0 .15rem .8rem rgba(98, 108, 104, .08), 0 0 0 1px rgba(0, 0, 0, .1);
  box-shadow: var(--shadow-medium), 0 0 0 1px rgba(0, 0, 0, .1);
  border-radius: .25rem;
  border-radius: var(--card-border-radius);
  max-width: 20rem;
  --paragraph-margin: .3rem;
  padding: .4rem .75rem .5rem;
  font-size: .75rem;
  line-height: 1.3;
  animation: 75ms ease-out fade-in;
  display: none;
}

.tooltip__tooltip ul, .tooltip__tooltip ol {
  padding-left: 1rem;
}

.tooltip__tooltip li {
  margin-left: 0;
}

.tooltip--show .tooltip__tooltip {
  display: block;
}

.tooltip--opened-with-click .tooltip__button {
  background-color: #222;
  background-color: var(--color-black);
}

.tooltip__heading:not(:first-child) {
  margin-top: 0;
}

.tooltip__heading:not(:last-child) {
  margin-bottom: .15rem;
}

@media (pointer: coarse) {
  .tooltip {
    --size: 1.8rem;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tooltip__arrow {
  --arrow-width: .7rem;
  --arrow-height: .45rem;
  --arrow-offset: calc(var(--arrow-height) * -1);
  width: var(--arrow-width);
  height: var(--arrow-height);
  pointer-events: none;
  background: url("data:image/svg+xml;utf8,<svg width=\"14\" height=\"9\" viewBox=\"0 0 14 9\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M7 7L0 0H14L7 7Z\" fill=\"white\"/><path d=\"M0 1V0L7 7L14 0V1L7 8.5L0 1Z\" fill=\"black\" fill-opacity=\"0.13\"/></svg>");
  display: block;
}

[data-popper-placement="top"] .tooltip__arrow {
  bottom: var(--arrow-offset);
}

[data-popper-placement="bottom"] .tooltip__arrow {
  top: var(--arrow-offset);
  background: url("data:image/svg+xml;utf8,<svg width=\"14\" height=\"9\" viewBox=\"0 0 14 9\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M7 2L14 9L0 9L7 2Z\" fill=\"white\"/><path d=\"M14 8L14 9L7 2L0 9L8.74228e-08 8L7 0.499999L14 8Z\" fill=\"black\" fill-opacity=\"0.13\"/></svg>");
}

.progress-bar {
  margin-bottom: 2.2rem;
}

.progress-bar__numbers {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  column-gap: 1rem;
  display: flex;
}

.progress-bar__numbers-current {
  white-space: nowrap;
}

.progress-bar__numbers-current:not(:last-child) {
  margin: 0;
}

.progress-bar__numbers-target {
  white-space: nowrap;
  font-size: 1rem;
}

.progress-bar__bar {
  width: 100%;
  height: 1.2rem;
  background-color: #fff;
  background-color: var(--color-white);
  margin-top: .5rem;
}

.progress-bar__progress {
  height: 100%;
  background-color: #1c3f8c;
  background-color: var(--color-blue);
}

.list {
  --gap: 1.2rem;
  column-gap: var(--gap);
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 4.8rem;
  padding-left: 0;
  display: grid;
}

.list--scrollable {
  -webkit-overflow-scrolling: touch;
  box-sizing: content-box;
  --columns: 3.15;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  cursor: grab;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding-bottom: .65rem;
  padding-left: calc(50vw - 50%);
  padding-right: calc(50vw - 50%);
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

@media (max-width: 575px) {
  .list--scrollable > * {
    scroll-snap-align: center;
  }
}

.list--scrollable::-webkit-scrollbar {
  display: none;
}

.list--scrollable > * {
  --list-column-width: calc((100% - var(--gap) * (var(--columns)  - 1)) / var(--columns));
  min-width: max(15rem, var(--list-column-width));
  max-width: calc((100% - var(--gap)) / 2);
  flex-basis: 100%;
  transition: opacity .25s linear;
}

@media (min-width: 1024px) {
  .list--scrollable > .is-outside {
    opacity: .25;
  }
}

@media (max-width: 840px) {
  .list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 567px) {
  .list {
    grid-template-columns: minmax(0, 1fr);
    row-gap: 4.4rem;
  }
}

@media (max-width: 375px) {
  .list--scrollable > * {
    min-width: max(13rem, var(--list-column-width));
  }
}

.list--scrollable {
  column-gap: 0;
}

.list--scrollable > :not(:last-child) {
  margin-right: var(--gap);
}

.scrollable-list {
  --button-size: 3rem;
  --button-margin: .55rem;
  --arrow-width: .5rem;
  --arrow-height: 1.1rem;
  position: relative;
}

.scrollable-list__action {
  z-index: 4;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  width: var(--button-size);
  height: var(--button-size);
  white-space: nowrap;
  text-indent: 10rem;
  background: #1c3f8c;
  background-color: var(--color-blue);
  box-shadow: 0 .15rem .8rem rgba(98, 108, 104, .08);
  box-shadow: var(--shadow-medium);
  border: 0;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  transition-property: color, box-shadow, border-color, background-color, opacity;
  transition-duration: .1s, .1s, .1s, .15s, .12s;
  transition-timing-function: ease-out;
  position: absolute;
  top: 50%;
  overflow: hidden;
  transform: translateY(-50%);
}

@media (max-width: 575px) {
  .scrollable-list__action {
    --button-size: 2.5rem;
    --arrow-width: .4rem;
    --arrow-height: .9rem;
  }
}

.scrollable-list__action:not(:disabled):hover {
  background-color: #164dad;
  border-color: #164dad;
}

.scrollable-list__action:disabled {
  opacity: 0;
}

.scrollable-list__action:before {
  content: "";
  width: var(--arrow-width);
  height: var(--arrow-height);
  background: url("arrow-white.ef782d58.svg") center no-repeat;
  background-size: var(--arrow-width) var(--arrow-height);
  top: calc(50% - var(--arrow-height) / 2);
  right: calc(50% - var(--arrow-width) / 2);
  position: absolute;
}

.scrollable-list__action--prev {
  left: -.25rem;
}

.scrollable-list__action--next {
  right: -.25rem;
}

.scrollable-list__action--prev:before {
  transform: rotate(180deg);
}

.amount {
  display: inline-block;
  position: relative;
}

.amount .amount__input.u-show-validation:valid, .amount .amount__input.u-show-validation:invalid {
  background-image: none;
}

.amount__input, .amount__currency {
  line-height: 1.5rem;
}

.amount__input {
  width: 100%;
  padding-right: 2.4rem;
  font-size: 1.25rem;
}

.amount__input::-webkit-outer-spin-button, .amount__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.amount__input[type="number"] {
  -moz-appearance: textfield;
}

.amount__currency {
  border-left: 1px solid #dedede;
  border-left: 1px solid var(--input-border-color);
  padding: .5rem .7rem .5rem .6rem;
  padding: var(--input-padding-y) .7rem var(--input-padding-y) .6rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  font-size: 1rem;
  transition: border-color .1s ease-out;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.amount__input:focus + .amount__currency {
  border-color: #dedede;
  border-color: var(--input-border-color--focus);
}

.amount__input:hover + .amount__currency {
  border-color: #dedede;
  border-color: var(--input-border-color--hover);
}

.amount.amount--valid:after, .amount.amount--invalid:after {
  content: "";
  width: 2.2rem;
  height: 2.2rem;
  pointer-events: none;
  position: absolute;
  top: .2rem;
  right: 2.2rem;
}

.amount.amount--valid:after {
  background-image: url("valid.4d14cf02.svg");
}

.amount.amount--invalid:after {
  background-image: url("invalid.0a193052.svg");
}

.amount.amount--valid .amount__input {
  background-color: #fcfffc;
  background-color: var(--input-background-color--valid);
}

.amount.amount--valid .amount__input:hover {
  background-color: #fcfffc;
  background-color: var(--input-background-color--valid-hover);
}

.amount.amount--valid .amount__input, .amount.amount--valid .amount__currency {
  border-color: #a7c7bf;
  border-color: var(--input-border-color--valid);
}

.amount.amount--invalid .amount__input {
  background-color: #fff;
  background-color: var(--input-background-color--invalid);
}

.amount.amount--invalid .amount__input:hover {
  background-color: #fcebee;
  background-color: var(--input-background-color--invalid-hover);
}

.amount.amount--invalid .amount__input, .amount.amount--invalid .amount__currency {
  border-color: #b31634;
  border-color: var(--input-border-color--invalid);
}

.amount.amount--valid .amount__input:hover, .amount.amount--valid .amount__input:hover + .amount__currency {
  border-color: #178972;
  border-color: var(--input-border-color--valid-hover);
}

.amount.amount--invalid .amount__input:hover + .amount__currency {
  border-color: #b31634;
  border-color: var(--input-border-color--invalid-hover);
}

.quantity {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.quantity__heading {
  width: 100%;
  margin-bottom: .4rem;
}

.quantity__field {
  width: 6rem;
  margin-right: .8rem;
  padding-left: 1rem;
}

.quantity__button {
  width: 1.6rem;
  height: 1.6rem;
  white-space: nowrap;
  text-indent: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: .2rem;
  padding: 0;
  overflow: hidden;
}

.quantity__button:not(:last-child) {
  margin-right: .4rem;
}

.quantity__button--increase {
  background-image: url("increase.c650696d.svg");
}

.quantity__button--decrease {
  background-image: url("decrease.1249fb93.svg");
}

.quantity__field {
  -moz-appearance: textfield;
}

.quantity__field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity__field::-ms-clear {
  display: none;
}

.js .input {
  display: inline-block;
  position: relative;
}

.js .input__label {
  top: .55rem;
  left: .5rem;
  left: var(--input-padding-x);
  right: .5rem;
  right: var(--input-padding-x);
  pointer-events: none;
  text-align: left;
  color: #888;
  color: var(--input-placeholder-color);
  transform-origin: 0 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition-property: transform, top;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.07, .6, .45, 1);
  position: absolute;
}

.js .input:has(input.input__field) .input__label {
  white-space: nowrap;
}

.input--full-width, .input--full-width .input__field {
  width: 100%;
}

.input--is-focused .input__label, .input--has-content .input__label {
  top: .2em;
  left: .55rem;
  left: calc(var(--input-padding-x)  + .05rem);
  width: 150%;
  transform: scale(.6);
}

.js .input > input[type="date"] {
  transition: color .2s linear;
}

.js .input:not(.input--has-content):not(.input--is-focused) > input[type="date"] {
  color: rgba(0, 0, 0, 0);
}

.js .input .input__field:not(.input__field--no-inline-label), .js .input .input__suffix:not(.input__field--no-inline-label ~ .input__suffix) {
  transition-property: margin-top, margin-bottom, padding-top, padding-bottom;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.07, .6, .45, 1);
}

@media (min-width: 576px) {
  .js .input--is-focused .input__field:not(.input__field--no-inline-label), .js .input--has-content .input__field:not(.input__field--no-inline-label), .js .input--is-focused .input__suffix:not(.input__field--no-inline-label ~ .input__suffix), .js .input--has-content .input__suffix:not(.input__field--no-inline-label ~ .input__suffix) {
    padding-top: 1.05rem;
    padding-top: calc(var(--input-padding-y)  + .55rem);
    padding-bottom: .35rem;
    padding-bottom: calc(var(--input-padding-y)  - .15rem);
    margin-top: -.2rem;
    margin-bottom: -.2rem;
  }

  .js .input--is-focused .input__meta, .js .input--has-content .input__meta {
    bottom: .35rem;
    bottom: calc(var(--input-padding-x)  - .15rem);
  }
}

.js .input__field:not(.input__field--no-inline-label) {
  padding-top: .8rem;
  padding-top: calc(var(--input-padding-y)  + .3rem);
  padding-bottom: .2rem;
  padding-bottom: calc(var(--input-padding-y)  - .3rem);
}

.input__meta {
  pointer-events: none;
  color: #888;
  color: var(--input-placeholder-color);
  left: .5rem;
  left: var(--input-padding-x);
  right: .5rem;
  right: var(--input-padding-x);
  bottom: .5rem;
  bottom: var(--input-padding-x);
  text-align: right;
  opacity: 0;
  font-size: .75rem;
  line-height: 1;
  transition: opacity .15s ease-out;
  position: absolute;
}

.input__meta--outside {
  bottom: -.3rem;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.input--has-content .input__meta {
  opacity: 1;
}

.input--with-suffix, .js .input--with-suffix {
  display: inline-flex;
}

.input--with-suffix .input__field {
  border-radius: .25rem 0 0 .25rem;
  border-radius: var(--input-border-radius) 0 0 var(--input-border-radius);
}

.input--with-suffix .input__suffix {
  padding: .5rem;
  padding: var(--input-padding-x) var(--input-padding-y);
  border: 1px solid #dedede;
  border: 1px solid var(--input-border-color);
  background-color: #fff;
  background-color: var(--input-background-color);
  border-radius: 0 .25rem .25rem 0;
  border-radius: 0 var(--input-border-radius) var(--input-border-radius) 0;
  pointer-events: none;
  border-left: 0;
  transition-property: color, box-shadow, border-color, background-color;
  transition-duration: .1s, .1s, .1s, .15s;
  transition-timing-function: ease-out;
}

.input--with-suffix input {
  padding-right: 0;
}

.input--with-suffix input:focus ~ .input__suffix {
  border-color: #dedede;
  border-color: var(--input-border-color--focus);
}

.input--with-suffix input:hover ~ .input__suffix {
  border-color: #dedede;
  border-color: var(--input-border-color--hover);
  background-color: #fafcff;
  background-color: var(--input-background-color--hover);
}

.input--with-suffix > input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

.input--with-suffix > input[type="number"] {
  -moz-appearance: textfield;
}

.input--with-suffix > input.u-show-validation:valid, .input--with-suffix > input.u-show-validation:invalid {
  background-image: none;
}

.input--with-suffix > input.u-show-validation:invalid ~ .input__suffix {
  border-color: #b31634;
  border-color: var(--input-border-color--invalid);
  background-color: #fff;
  background-color: var(--input-background-color--invalid);
}

.input--with-suffix > input.u-show-validation:invalid:hover ~ .input__suffix {
  border-color: #b31634;
  border-color: var(--input-border-color--invalid-hover);
  background-color: #fcebee;
  background-color: var(--input-background-color--invalid-hover);
}

.input--with-suffix > input.u-show-validation:valid ~ .input__suffix {
  border-color: #a7c7bf;
  border-color: var(--input-border-color--valid);
  background-color: #fcfffc;
  background-color: var(--input-background-color--valid);
}

.input--with-suffix > input.u-show-validation:valid:hover ~ .input__suffix {
  border-color: #178972;
  border-color: var(--input-border-color--valid-hover);
  background-color: #fcfffc;
  background-color: var(--input-background-color--valid-hover);
}

.radio-set {
  width: 100%;
  display: flex;
}

.radio-set__option {
  color: #1c3f8c;
  color: var(--color-blue);
  border-radius: .2rem;
  align-items: flex-start;
  padding-right: 1rem;
  display: flex;
}

.radio-set__option > input {
  flex-shrink: 0;
  margin-top: .3rem;
}

.radio-set__option--vertical {
  flex-direction: column;
  justify-items: flex-start;
}

@supports selector(:has(a, b)) {
  .js.tabbed .radio-set__option:has(input:focus) {
    box-shadow: 0 0 0 .05rem #fff, 0 0 0 .2rem rgba(0, 124, 216, .533);
    box-shadow: var(--input-prominent-focus-inner) 0 0 0 .05rem, var(--input-prominent-focus) 0 0 0 .2rem;
  }

  .js.tabbed .radio-set__option input:focus {
    box-shadow: none;
  }
}

.radio-set__content {
  color: #222;
  color: var(--color-black);
  margin-top: .2rem;
  margin-left: 1.1rem;
  overflow: hidden;
}

.radio-set__content p:last-of-type {
  margin-bottom: 0;
}

.radio-set__content .radio-set:not(:first-child) {
  margin-top: 1rem;
  margin-top: var(--paragraph-margin);
}

.radio-set__label {
  vertical-align: middle;
  font-size: 1.15rem;
  line-height: 1.65rem;
}

.radio-set__label--nowrap {
  white-space: nowrap;
}

@media (min-width: 576px) and (max-width: 767px) {
  .radio-set__label {
    font-size: 1em;
  }
}

.radio-set__option--small .radio-set__label {
  font-size: .9rem;
}

.radio-set--outlined .radio-set__option {
  border: 1px solid #dedede;
  border: 1px solid var(--input-border-color);
  background-color: #fff;
  background-color: var(--input-background-color);
  padding-top: .8rem;
  padding-bottom: .8rem;
  padding-left: 1rem;
  transition-property: border-color, background-color;
  transition-duration: .1s, .15s;
  transition-timing-function: ease-out;
}

.radio-set--outlined .radio-set__option:hover {
  border: 1px solid #dedede;
  border: 1px solid var(--input-border-color--hover);
  background-color: #fafcff;
  background-color: var(--input-background-color--hover);
}

@supports selector(:has(a, b)) {
  .radio-set--outlined .radio-set__option:has(.radio-set__content):hover {
    background-color: #fff;
    background-color: var(--color-background);
  }
}

.radio-set--highlight-checked.radio-set--outlined .radio-set__option {
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: .1s, .15s, .15s, .15s;
  transition-timing-function: ease-out;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0);
}

.radio-set--highlight-checked.radio-set--outlined .radio-set__option--checked {
  color: #fff;
  background-color: #1c3f8c;
  background-color: var(--color-blue);
  border-color: #1c3f8c;
  border-color: var(--color-blue);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.radio-set--highlight-checked.radio-set--outlined .radio-set__option--checked input {
  border-color: #eee;
}

@media (min-width: 650px) {
  .radio-set--horizontal .radio-set__option {
    align-items: center;
  }

  .radio-set--horizontal .radio-set__option input {
    margin-top: 0;
    margin-bottom: .15rem;
  }

  .radio-set--horizontal .radio-set__option:not(:last-child) {
    margin-right: 1rem;
    margin-right: var(--form-spacing-x);
  }
}

@media (max-width: 649px) {
  .radio-set--horizontal {
    flex-direction: column;
  }

  .radio-set--horizontal .radio-set__option:not(:last-child) {
    margin-bottom: .45rem;
    margin-bottom: calc(var(--form-spacing-y) / 2);
  }
}

.radio-set--horizontal.radio-set--even .radio-set__option {
  flex-grow: 1;
  flex-shrink: 1;
}

.radio-set--vertical {
  flex-direction: column;
}

.radio-set--vertical .radio-set__option:not(:last-child) {
  margin-bottom: .45rem;
  margin-bottom: calc(var(--form-spacing-y) / 2);
}

.radio-set--grid {
  --radio-set-columns: 1;
  grid-template-columns: repeat(var(--radio-set-columns), minmax(0, 1fr));
  column-gap: 1rem;
  column-gap: var(--form-spacing-x);
  row-gap: .25rem;
  display: grid;
}

@media (min-width: 440px) {
  .radio-set--grid {
    --radio-set-columns: 2;
    row-gap: .9rem;
    row-gap: var(--form-spacing-y);
  }
}

@media (min-width: 1200px) {
  .radio-set--grid {
    --radio-set-columns: 4;
  }
}

.radio-set--images {
  transform: translate3d(0, 0, 0);
}

.radio-set--images .radio-set__option {
  background-color: #000;
  border-radius: .2rem;
  flex: 1;
  padding-right: 0;
  position: relative;
  overflow: hidden;
}

.radio-set--images .radio-set__option:not(:last-child) {
  margin-right: 1rem;
}

@media (max-width: 575px) {
  .radio-set--images {
    flex-wrap: wrap;
  }

  .radio-set--images .radio-set__option {
    flex: 0 calc(50% - .25rem);
    margin-bottom: .5rem;
  }

  .radio-set--images .radio-set__option:not(:last-child) {
    margin-right: .5rem;
  }

  .radio-set--images .radio-set__option:nth-child(2n) {
    margin-right: 0;
  }
}

.radio-set--images .radio-set__image {
  width: 100%;
  height: auto;
}

.radio-set--images .radio-set__option:before {
  content: "";
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .15s ease-out;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.radio-set--images .radio-set__option.radio-set__option--checked:before {
  background-color: rgba(0, 0, 0, .33);
}

.radio-set--images input {
  z-index: 2;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.radio-set--images .radio-set__label {
  position: absolute;
  top: 0;
  left: 150%;
}

.radio-set--dense {
  --form-spacing-x: .5rem;
}

.radio-set--dense.radio-set--outlined .radio-set__option {
  padding: .5rem .55rem;
}

.radio-set--dense .radio-set__option:not(.radio-set__option--small) .radio-set__label {
  font-size: 1rem;
}

@media (max-width: 1196px) and (min-width: 650px) {
  .radio-set--campaign {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: .5rem;
    display: grid;
  }

  .radio-set--campaign.radio-set--horizontal .radio-set__option:not(:last-child) {
    margin-right: 0;
  }
}

.button-set {
  flex-flow: wrap;
  margin-bottom: -.3rem;
  display: flex;
}

.button-set--vertical {
  flex-direction: column;
}

.button-set--center {
  justify-content: center;
}

.button-set--spaced {
  justify-content: space-between;
}

.button-set__button {
  margin-bottom: .3rem;
  margin-right: .3rem;
}

.button-set__description {
  color: #989898;
  color: var(--color-gray);
  align-self: center;
  margin-bottom: .3rem;
  margin-right: .3rem;
  line-height: 1.25;
}

.button-set--increased-space {
  margin-bottom: -.75rem;
}

.button-set--increased-space .button-set__button, .button-set--increased-space .button-set__description {
  margin-bottom: .75rem;
  margin-right: .75rem;
}

@media (max-width: 374px) {
  .button-set--small-on-mobile .button {
    padding: .35rem .8rem .4rem;
    font-size: .8rem;
  }
}

.recommend {
  color: #222;
  color: var(--color-text);
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
  border-radius: .25rem;
  border-radius: var(--input-border-radius);
  align-items: center;
  font-size: .8rem;
  display: flex;
}

.recommend:not(:first-child) {
  margin-top: .9rem;
  margin-top: var(--form-spacing-y);
}

.recommend:not(:last-child) {
  margin-bottom: .9rem;
  margin-bottom: var(--form-spacing-y);
}

.radio-set + .recommend, .radio-set + .recommend ~ .recommend {
  margin-top: 1rem;
}

.recommend--block-flow {
  max-width: 22rem;
  margin-top: .75rem;
}

.recommend__heading {
  border-bottom: 0;
  margin-bottom: .65rem;
}

.recommend__content {
  flex: 1;
  padding: .6rem 1rem;
}

.recommend__amount {
  width: 11rem;
  margin: .5rem;
}

.recommend__content > p:not(:last-child) {
  margin-bottom: 0;
}

.recommend em {
  color: #1c3f8c;
  color: var(--color-blue);
  font-style: normal;
}

@media (max-width: 575px) {
  .recommend {
    flex-direction: column;
    align-items: flex-start;
  }

  .recommend__amount {
    margin-top: 0;
    margin-left: 2.25rem;
  }
}

.payment-option {
  min-height: 2.3rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.payment-option__logo > img {
  display: block;
}

@media (max-width: 575px) {
  .payment-option__logo {
    max-width: 33%;
  }

  .payment-option__logo > img {
    max-width: 100%;
    height: auto;
  }
}

.subscribe:not(:first-child) {
  margin-top: 1.25rem;
}

.subscribe__input, .subscribe__input > input {
  width: 100%;
}

.subscribe.subscribe--center {
  width: 100%;
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575px) {
  .subscribe {
    flex-direction: column;
    align-items: flex-start;
  }

  .subscribe.subscribe--center {
    align-items: center;
  }

  .subscribe__input {
    margin-bottom: .4rem;
  }
}

@media (min-width: 576px) {
  .subscribe {
    align-items: flex-start;
    display: flex;
  }

  .subscribe__input {
    flex: 1;
    margin-right: .4rem;
  }
}

.tabbed-area {
  --outer-border: 1px solid var(--color-thin-line-solid);
  --outer-radius: var(--input-border-radius);
}

.tabbed-area--in-step {
  --outer-border: 0;
  --outer-radius: 0;
}

.tabbed-area__tabs {
  background-color: #edf3fd;
  background-color: var(--color-light-blue);
  white-space: nowrap;
  --tab-bottom-shadow: inset var(--color-thin-line-solid) 0 -1px 0;
  box-shadow: var(--tab-bottom-shadow);
  border: var(--outer-border);
  border-top-left-radius: var(--outer-radius);
  border-top-right-radius: var(--outer-radius);
  border-bottom: 0;
  display: flex;
  overflow-x: auto;
}

.tabbed-area__tabs::-webkit-scrollbar {
  display: none;
}

.tabbed-area__tabs {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tabbed-area--in-step .tabbed-area__tabs:not(:first-child) {
  border-top: 1px solid #dedede;
  border-top: 1px solid var(--color-thin-line-solid);
}

.tabbed-area__tab {
  color: #1c3f8c;
  color: var(--color-blue);
  z-index: 1;
  padding: 1rem 1.2rem 1rem 1rem;
  transition-property: border-color, background-color;
  transition-duration: .1s, .15s;
  transition-timing-function: ease-out;
  position: relative;
}

.tabbed-area__tab:first-child {
  border-top-left-radius: calc(var(--outer-radius)  - .05rem);
}

.tabbed-area__tab {
  box-shadow: -1px 0 #dedede;
  box-shadow: -1px 0 0 var(--color-thin-line-solid);
}

.tabbed-area__tab--active:last-child {
  box-shadow: -1px 0 #dedede, 1px 0 #dedede, 0 1px #fff;
  box-shadow: -1px 0 0 var(--color-thin-line-solid), 1px 0 0 var(--color-thin-line-solid), #fff 0 1px 0;
}

.tabbed-area__tab:not(:first-child) {
  margin-left: 1px;
}

.tabbed-area__tab--active {
  box-shadow: -1px 0 #dedede, 0 1px #fff;
  box-shadow: -1px 0 0 var(--color-thin-line-solid), #fff 0 1px 0;
  background-color: #fff;
}

.tabbed-area__tab:not(.tabbed-area__tab--active):hover {
  background-color: #fafcff;
  background-color: var(--input-background-color--hover);
  box-shadow: 0 0 0 1px #dedede, var(--tab-bottom-shadow);
  box-shadow: 0 0 0 1px var(--input-border-color--hover), var(--tab-bottom-shadow);
  z-index: 2;
}

.tabbed-area__content {
  border-top: var(--outer-border);
  border-left: var(--outer-border);
  border-right: var(--outer-border);
  border-top-left-radius: var(--outer-radius);
  border-top-right-radius: var(--outer-radius);
  padding: 1rem;
  overflow: hidden;
}

.tabbed-area__tabs + .tabbed-area__content {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tabbed-area__content:last-child {
  border-bottom: var(--outer-border);
  border-bottom-left-radius: var(--outer-radius);
  border-bottom-right-radius: var(--outer-radius);
}

.tabbed-area__content-section {
  width: calc(100% + 2rem);
  margin: -1rem;
  padding: 1rem;
}

.tabbed-area__content-section.grid {
  margin-bottom: 0;
  padding-bottom: 0;
}

.tabbed-area__content-section.grid:last-child {
  margin-bottom: -.9rem;
  margin-bottom: calc(var(--grid-row-margin) * -1);
}

.tabbed-area__content-section + .tabbed-area__content-section {
  border-top: 1px solid #dedede;
  border-top: 1px solid var(--color-thin-line-solid);
  margin-top: 0;
}

.tabbed-area__content-section:not(:last-child) {
  margin-bottom: 0;
}

.tabbed-area__content-section--alternate {
  background-color: #edf3fd;
  background-color: var(--color-pale-blue);
}

@media (max-width: 575px) {
  .tabbed-area__tabs {
    font-size: .75rem;
  }

  .tabbed-area__tab {
    padding: .6rem .4rem;
    font-size: .85rem;
  }

  .tabbed-area__tab input[type="checkbox"], .tabbed-area__tab input[type="radio"] {
    width: .65rem;
    height: .65rem;
    vertical-align: -.1rem;
    background-size: .25rem .25rem;
    margin-right: .2rem;
  }

  .tabbed-area__content {
    --grid-row-margin: .45rem;
    --grid-gutter: .5rem;
    padding: .5rem;
  }

  .tabbed-area__content-section {
    width: calc(100% + 1rem);
    margin: -.5rem;
    padding: .5rem;
  }
}

@media (max-width: 469px) {
  .tabbed-area__tab {
    font-size: .8rem;
  }
}

@media (max-width: 439px) {
  .tabbed-area__tab {
    font-size: .75rem;
  }
}

@media (max-width: 399px) {
  .tabbed-area__tab {
    flex: 1;
    font-size: .7rem;
  }

  .tabbed-area__tab:last-child {
    border-top-right-radius: .2rem;
    border-top-right-radius: calc(var(--input-border-radius)  - .05rem);
  }
}

@media (min-width: 400px) {
  .tabbed-area__tab-label--lt-400 {
    display: none;
  }
}

@media (max-width: 399px) {
  .tabbed-area__tab-label--gte-400 {
    display: none;
  }
}

.confirm {
  text-align: left;
  align-items: flex-start;
  margin-bottom: 1rem;
  display: flex;
}

.confirm__input {
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: .1rem;
}

input.confirm__input {
  margin-right: .5rem;
}

@media (min-width: 768px) {
  fieldset + .confirm {
    margin-top: -1rem;
  }
}

.confirm__label a:not(:hover) {
  text-decoration: underline;
}

.validation {
  color: #989898;
  color: var(--color-gray);
  text-align: left;
  font-size: .75rem;
  display: block;
}

.validation--error {
  color: #b31634;
  color: var(--color-red);
  margin-top: .25rem;
}

.js .validation:not(.validation--error):empty {
  display: none;
}

.confirm + .validation {
  margin-top: -.6rem;
}

.form-error, .woocommerce-error, .hf-message.hf-message-warning {
  color: #b31634;
  color: var(--color-red);
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  background-color: #fcebee;
  background-color: var(--color-pale-red);
  border-radius: .25rem;
  border-radius: var(--input-border-radius);
  text-align: left;
  margin-top: 1rem;
  padding: .6rem;
  font-size: .8rem;
}

.form-error a, .woocommerce-error a, .hf-message.hf-message-warning a {
  color: #b31634;
  color: var(--color-red);
}

.form-error:before, .woocommerce-error li:before, .hf-message.hf-message-warning:before {
  content: "";
  width: 1rem;
  height: 1rem;
  vertical-align: -.25rem;
  background: url("invalid.0a193052.svg") center / 2rem 2rem;
  margin-right: .4rem;
  display: inline-block;
}

.woocommerce-error {
  padding-left: 1rem;
  padding-right: 1rem;
}

.woocommerce-error li {
  margin-left: 0;
  display: block;
}

.woocommerce-error li + li {
  margin-top: 0;
}

.woocommerce-error li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, .13);
  border-bottom: 1px solid var(--color-thin-line);
  margin-bottom: .6rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-bottom: .6rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.bank-selector__logo {
  max-width: 100%;
  height: auto;
}

.bank-selector.grid {
  --grid-row-margin: .45rem;
}

@media (max-width: 575px) {
  .bank-selector {
    --grid-gutter: .5rem;
  }
}

.js .file-input {
  border: 1px solid rgba(0, 0, 0, .13);
  border: 1px solid var(--color-thin-line);
  border-radius: .25rem;
  border-radius: var(--input-border-radius);
  max-width: 100%;
  padding: .3rem;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.js .file-input:hover {
  background-color: #fafcff;
  background-color: var(--input-background-color--hover);
  border-color: #dedede;
  border-color: var(--input-border-color--hover);
}

.js .file-input--is-focused {
  border-color: #dedede;
  border-color: var(--input-border-color--focus);
}

.js .file-input__field {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.js .file-input__label {
  color: #989898;
  color: var(--color-gray);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 18rem;
  padding-right: .5rem;
  overflow: hidden;
}

.js .file-input--has-file .file-input__label {
  color: #000;
}

.no-js .file-input__label {
  display: none;
}

.js .file-input__label:before {
  content: "+";
  color: #fff;
  border-color: #1c3f8c;
  border-color: var(--color-blue);
  background-color: #1c3f8c;
  background-color: var(--color-blue);
  border-radius: .15rem;
  border-radius: calc(var(--input-border-radius)  - .1rem);
  width: 1.35rem;
  text-align: center;
  margin-right: .4rem;
  padding: .1rem 0;
  transition-property: color, box-shadow, border-color, background-color;
  transition-duration: .1s, .1s, .1s, .15s;
  transition-timing-function: ease-out;
  display: inline-block;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0);
}

.js .file-input__field:hover + .file-input__label:before {
  background-color: #164dad;
  border-color: #164dad;
}

.js .file-input__field:focus + .file-input__label:before {
  border-color: #dedede;
  border-color: var(--input-border-color--focus);
  outline: 0;
  box-shadow: 0 0 .3rem rgba(0, 0, 0, .04);
}

.js .file-input.file-input--is-valid {
  border-color: #a7c7bf;
  border-color: var(--input-border-color--valid);
  background-color: #fcfffc;
  background-color: var(--input-background-color--valid);
}

.js .file-input.file-input--is-invalid {
  border-color: #b31634;
  border-color: var(--input-border-color--invalid);
  background-color: #fff;
  background-color: var(--input-background-color--invalid);
}

.custom-amount {
  margin-top: .35rem;
  margin-bottom: .9rem;
  margin-bottom: var(--form-spacing-y);
  justify-content: end;
  display: flex;
}

.custom-amount__amount {
  width: 100%;
}

@media (min-width: 440px) {
  .custom-amount__amount {
    width: calc(50% - .5rem);
    width: calc(50% - var(--form-spacing-x) * 2 / 4);
  }
}

@media (min-width: 1200px) {
  .custom-amount__amount {
    width: calc(25% - .75rem);
    width: calc(25% - var(--form-spacing-x) * 3 / 4);
  }
}





/*# sourceMappingURL=index.897233bc.css.map */
