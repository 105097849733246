.quantity {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.quantity__heading {
	margin-bottom: 0.4rem;
	width: 100%;
}

.quantity__field {
	width: 6rem;
	margin-right: 0.8rem;
	padding-left: 1rem;
}

.quantity__button {
	width: 1.6rem;
	height: 1.6rem;
	border-radius: 0.2rem;
	background-repeat: no-repeat;
	background-position: center center;

	/* Hide text */
	padding: 0;
	overflow: hidden;
	white-space: nowrap;
	text-indent: 2rem;
}

.quantity__button:not(:last-child) {
	margin-right: 0.4rem;
}

.quantity__button--increase {
	background-image: url('../../../assets/increase.svg');
}

.quantity__button--decrease {
	background-image: url('../../../assets/decrease.svg');
}

/**
 * Deal with number input cross browser weirdness.
 * Consider moving this to affect all inputs.
 */
.quantity__field {
	/* Removes increase/decrease buttons in Firefox */
	-moz-appearance: textfield;
}
.quantity__field::-webkit-inner-spin-button {
	/* Removes increase/decrease buttons in Chrome/Safari */
	-webkit-appearance: none;
	margin: 0;
}
.quantity__field::-ms-clear {
	/* Removes clear field button in IE/Edge */
	display: none;
}
