.footer {
	background-color: var(--color-pale-blue);
	padding-top: 2.8rem;
	padding-bottom: 2.8rem;
	font-size: 0.75rem;
}

.footer form {
	font-size: inherit;
}

.footer__content {
	padding-top: 2.5rem;
	padding-bottom: 2.2rem;
}

.footer__content,
.footer__container {
	transition: border-color 100ms ease-out;
	border-bottom: 1px solid var(--color-thin-line);
}

.footer__content a {
	text-decoration: none;
	color: var(--color-black);
}

.footer__content p {
	margin-bottom: 0.1rem;
}

.footer__heading {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.75rem;
}

.footer__heading:not(:last-child) {
	margin-bottom: 0.4rem;
}

.footer__details {
	margin-bottom: 1rem;
}

.footer__social-media svg {
	display: inline-block;
	vertical-align: -4px;
}

.footer__newsletter {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	column-gap: var(--page-margin);
	padding-top: 2.8rem;
	padding-bottom: 2.5rem;
}

.footer__logos {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 2.6rem;
}

.footer__input {
	display: flex;
	margin-bottom: 0.5rem;
}

.footer__input input {
	width: 100%;
	max-width: 19rem;
	margin-right: 0.65rem;
	font-size: 0.85rem;
}

@media (max-width: 971px) {
	.footer {
		padding-top: 1.6rem;
		padding-bottom: 2.1rem;
	}

	.footer__newsletter {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		row-gap: 1.1rem;
	}

	.footer__newsletter p {
		max-width: 30rem;
	}
}

@media (max-width: 575px) {
	.footer__content {
		padding-top: 1.9rem;
	}

	.footer__newsletter {
		padding-top: 1.8rem;
		padding-bottom: 1.6rem;
	}

	.footer__input input {
		margin-right: 8px;
	}

	.footer .button {
		font-size: 0.75rem;
	}

	.footer__logos {
		padding-top: 1.7rem;
	}

	.footer__logo-90-konto {
		width: 80px;
		height: auto;
	}

	.footer__logo-operation-smile {
		width: 150px;
		height: auto;
	}
}

@media (max-width: 375px) {
	.footer__input {
		flex-direction: column;
		row-gap: 0.5rem;
	}
}

.footer__quick-nav {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;
	font-size: 1.25rem;
	line-height: 1.2;

	border-bottom: 1px solid var(--color-thin-line);
	padding-bottom: 1.5rem;
}

.footer__quick-nav a {
	text-decoration: none;
}
