.donations-graph {
}

.donations-graph__header {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: 1rem;
}

.h1.donations-graph__result {
	font-weight: 900;
	text-transform: none;
	letter-spacing: 2px;
}

.donations-graph__bar {
	position: relative;
	background-color: #d9d9d9;
	color: #fff;
	font-weight: 500;
	font-size: 1.2rem;
	margin-bottom: 1rem;
}

.donations-graph__progress {
	position: absolute;
	background-color: #000;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.donations-graph__progress::after {
	content: '';
	height: calc(100% + 1rem);
	background-color: #000;
	width: 3px;
	display: inline-block;
	position: absolute;
	bottom: -1rem;
	right: 0;
}

.donations-graph__status {
	margin-top: 1rem;
	text-align: left;
	font-weight: 900;
	letter-spacing: 2px;
}

.donations-graph__numbers {
	position: relative;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: grid;
	text-align: center;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	z-index: 2;
}

.donations-graph__number {
	padding: 1.2rem 7%;
	font-size: 0.8rem;
}

.donations-graph__number:not(:last-child) {
	border-right: 2px solid var(--color-white);
}

.donations-graph__details {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-top: 1rem;
}

@media (min-width: 576px) {
	.donations-graph__header h2:not(:last-child) {
		margin-bottom: 0;
	}
}

@media (max-width: 575px) {
	.donations-graph__bar {
		font-size: 0.7rem;
	}

	.donations-graph__number span {
		display: none;
	}
}
