.highlights {
}

.highlights__heading {
	display: block;
	text-align: center;
}

.highlights__list {
	position: relative;
	padding-left: 0;
	display: flex;
	flex-direction: column;
}

.highlights__item {
	display: block;
	position: relative;
}

.highlights__item::before {
	content: '';
	display: block;
	border: 1px solid #ccc;
	background-color: #fff;
	width: 0.9rem;
	height: 0.9rem;
	border-radius: 0.45rem;
}

.highlights__item:nth-child(3n + 1) {
	color: var(--color-red);
}

.highlights__item:nth-child(3n + 2) {
	color: var(--color-blue);
}

.highlights__item:nth-child(3n + 3) {
	color: var(--color-green);
}

.highlights__item strong {
	display: block;
	font-size: 1.9em;
	font-weight: 600;
	line-height: 1.1;
	margin-bottom: -0.05em;
}

.highlights__image {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
	height: auto;
}

@media (width < 576px) {
	.highlights__list {
		text-align: center;
		padding-top: 0.5rem;
		padding-bottom: 1rem;
	}

	.highlights__item {
		padding-top: 4rem; /* make room for ::before and ::after */
		max-width: 16rem;
		margin-left: auto;
		margin-right: auto;
	}

	.highlights__item + .highlights__item {
		margin-top: 1rem;
	}

	.highlights__item::before {
		position: absolute;
		top: 2.5rem;
		left: calc(50% - 0.45rem);
	}

	.highlights__item::after {
		content: '';
		background: linear-gradient(to bottom, #fff, #ccc);
		position: absolute;
		top: 0;
		left: 50%;
		height: 2.5rem;
		width: 1px;
	}

	.highlights__image {
		margin-top: 1rem;
	}

	.highlights__image--small {
		position: absolute;
		left: 80%;
		top: 6.5rem;

		width: 30%;
		height: auto;
	}
}

@media (width >= 576px) {
	.highlights__list {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.highlights__list::after {
		content: '';
		background: linear-gradient(
				to bottom,
				#fff 0%,
				#ccc 2.5rem,
				#ccc 3rem,
				rgba(204, 204, 204, 0) 3rem
			),
			linear-gradient(
				to top,
				#fff 0%,
				#ccc 2.5rem,
				#ccc 3rem,
				rgba(204, 204, 204, 0) 3rem
			),
			#ccc;
		width: 1px;
		height: auto;
		position: absolute;
		top: 0;
		left: 50%;
		bottom: 0;
	}

	.highlights__item {
		width: calc(50% - 5rem);
		font-size: 1.35rem;
		line-height: 1.3;
	}

	.highlights__item + .highlights__item {
		margin-top: 3rem;
	}

	.highlights__item:nth-child(even) {
		align-self: flex-end;
		margin-right: 2.5rem;
		text-align: left;
	}

	.highlights__item:nth-child(odd) {
		align-self: flex-start;
		margin-left: 2.5rem;
		text-align: right;
	}

	/* Images are absolutely positioned so this is needed to make
	   enough room to fit larger ones. */
	.highlights__item--large-image {
		min-height: 11rem;
	}

	.highlights__image {
		position: absolute;
		top: 0;
	}

	.highlights__image--small {
		width: 8rem;
	}

	.highlights__item:nth-child(even) .highlights__image {
		right: calc(100% + 5rem);
	}

	.highlights__item:nth-child(odd) .highlights__image {
		left: calc(100% + 5rem);
	}

	.highlights__item::before {
		position: absolute;
		top: 0.66em; /* in em, like <strong> font-size */
		z-index: 2;
	}

	.highlights__item:nth-child(even)::before {
		left: -2.95rem;
	}

	.highlights__item:nth-child(odd)::before {
		right: -2.95rem;
	}
}

@media (width >= 992px) {
	.highlights__item {
		font-size: 1.75rem;
	}
}
