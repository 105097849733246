.page-card {
	position: relative;
}

.page-card + .page-card,
.category-selector + .page-card,
.page-cardwrapper + .page-cardwrapper {
	margin-top: 1rem;
}

/* Heading to the left */

@media (width >= 1200px) {
	.page-card__corner-heading,
	.page-card__content > .page-card__corner-heading {
		position: absolute;
		top: calc(var(--page-margin) * 1.25);
		left: 0;
		margin-bottom: 0;
		padding-right: 1rem;
		width: calc((100% - var(--page-content-width)) / 2);
		max-width: 100%;
		box-sizing: border-box;
	}

	.page-card__corner-heading + * {
		margin-top: 0 !important;
	}
}

@media (width < 1200px) {
	.page-card__corner-heading {
		text-align: center;
	}
}

.page-card__meta {
	display: flex;
	font-size: 0.85rem;
}

/* The label styling should be generalized. This styling duplicates image__label. */
.page-card__label {
	color: #fff;
	background-color: var(--color-blue);
	border-radius: 0.1rem;
	padding: 0.2rem 0.45rem 0.1rem;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 0.7rem;
	letter-spacing: 0.05rem;
	-webkit-font-smooth: antialiased;
}

@media (width < 768px) {
	.page-card__meta {
		font-size: 0.7rem;
		padding: 1rem;
		padding-bottom: 0.5rem;
		flex-wrap: wrap;
	}
	.page-card__meta > * {
		margin-bottom: 0.5rem;
	}
	.page-card__meta > *:not(:last-child) {
		margin-right: 0.75rem;
	}
}

@media (width < 576px) {
	.page-card__meta {
		padding: calc(var(--page-margin) * 2);
	}
}

@media (width >= 768px) {
	.page-card__meta {
		position: absolute;
		top: 1.5rem;
		left: 1.5rem;

		flex-direction: column;
		align-items: flex-start;
	}

	.page-card__meta > *:not(:last-child) {
		margin-bottom: 0.75rem;
	}
}

.page-card__heading {
	text-align: center;
	margin-top: calc(var(--page-margin) * 1.25);
	margin-bottom: calc(var(--page-margin) * 1.25);
	padding-left: calc(var(--page-margin) * 1.5);
	padding-right: calc(var(--page-margin) * 1.5);
}

.page-card__intro {
	text-align: center;
	margin-bottom: 1rem;
}

.page-card__intro:not(:last-child) {
	margin-bottom: 2rem;
}

@media (width < 576px) {
	.page-card__intro {
		margin-top: 0.7rem;
	}
}

.page-card__wide-content {
	margin-top: calc(var(--page-margin) * 1.25);
	margin-bottom: calc(var(--page-margin) * 1.5);
	padding-left: calc(var(--page-margin) * 1.5);
	padding-right: calc(var(--page-margin) * 1.5);
}

.page-card__content {
	margin-top: calc(var(--page-margin) * 1.25);
	margin-bottom: calc(var(--page-margin) * 1.5);
}

.page-card__content > * {
	max-width: var(--page-content-width);
	margin-left: auto;
	margin-right: auto;
	padding-left: calc(var(--page-margin) * 1.5);
	padding-right: calc(var(--page-margin) * 1.5);
	box-sizing: content-box;
}

.page-section--inner-spacing > .page-card .page-card__content:first-child {
	margin-top: 0;
}

.page-section--inner-spacing > .page-card .page-card__content:last-child {
	margin-bottom: 0;
}

.page-card__content--left > * {
	margin-left: 0;
}

@media (width < 576px) {
	.page-card__content {
		margin-top: calc(var(--page-margin) * 2);
		margin-bottom: calc(var(--page-margin) * 2);
	}

	.page-card__content > * {
		padding-left: calc(var(--page-margin) * 2);
		padding-right: calc(var(--page-margin) * 2);
	}

	.page-card__wide-content {
		margin-top: calc(var(--page-margin) * 2);
		margin-bottom: calc(var(--page-margin) * 2);
		padding-left: calc(var(--page-margin) * 2);
		padding-right: calc(var(--page-margin) * 2);
	}
}

@media (width < 440px) {
	/**
	 * @todo Temporary fix for margin issue on mobile. Should be removed when the
	 * page-card classes are removed.
	 */
	.page-wrap .page-card__content > *:not(.box) {
		padding-left: 0;
		padding-right: 0;
	}
}

/* Give room for the scroll context indicator */
@media screen and (width >= 768px) {
	.page-card--offset .page-card__content {
		box-sizing: content-box;
		padding-left: 8rem;
	}
}

.page-card__content--form > * {
	max-width: 34rem;
}

.page-card__content--center {
	text-align: center;
}
