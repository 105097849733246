.contact-box {
	background-color: var(--color-pale-blue);
	padding: 1rem;
	max-width: 21rem;
}

.contact-box--center {
	margin-left: auto;
	margin-right: auto;
}

.contact-box__name {
	font-size: 1.3rem;
	line-height: 1.1;
	margin-top: 0;
	margin-bottom: 0.5rem;
}

.contact-box__name:not(:first-child) {
	margin-top: 1.2rem;
}

.contact-box a[href^='mailto:'] {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	vertical-align: top;
}
