/* Same as in page-intro */
.header + .overlap-intro {
	margin-top: -1.4rem;
}

.overlap-intro {
	overflow: visible !important;
	background-color: var(--color-pale-blue);

	padding-top: 3rem;
	margin-bottom: 5rem;

	display: flex;
	align-items: center;
	flex-direction: column;
}

.overlap-intro__image {
	display: block;
	max-width: 100%;
	width: 38rem;
	height: auto;
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
	margin-bottom: -4rem;
}
