.tooltip {
	display: inline-block;
	--size: 1.1rem;
}

.tooltip__button {
	cursor: pointer;
	border: 0;
	padding: 0;
	background: var(--color-blue) url('../../assets/help-symbol.svg') no-repeat
		center;
	background-size: var(--size) var(--size);
	width: var(--size);
	height: var(--size);
	border-radius: 50%;

	white-space: nowrap;
	overflow: hidden;
	text-indent: 3rem;
}

.tooltip__tooltip {
	display: none;
	z-index: 10;

	background-color: var(--color-background);
	padding: 0.4rem 0.75rem 0.5rem;
	box-shadow: var(--shadow-medium), 0 0 0 1px rgba(0, 0, 0, 0.1);
	border-radius: var(--card-border-radius);
	animation: fade-in 75ms ease-out;
	max-width: 20rem;

	--paragraph-margin: 0.3rem;
	font-size: 0.75rem;
	line-height: 1.3;
}

.tooltip__tooltip ul,
.tooltip__tooltip ol {
	padding-left: 1rem;
}

.tooltip__tooltip li {
	margin-left: 0;
}

.tooltip--show .tooltip__tooltip {
	display: block;
}

.tooltip--opened-with-click .tooltip__button {
	background-color: var(--color-black);
}

.tooltip__heading:not(:first-child) {
	margin-top: 0;
}

.tooltip__heading:not(:last-child) {
	margin-bottom: 0.15rem;
}

@media (pointer: coarse) {
	.tooltip {
		--size: 1.8rem;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.tooltip__arrow {
	--arrow-width: 0.7rem;
	--arrow-height: 0.45rem;
	--arrow-offset: calc(var(--arrow-height) * -1);

	display: block;
	width: var(--arrow-width);
	height: var(--arrow-height);
	background: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 7L0 0H14L7 7Z" fill="white"/><path d="M0 1V0L7 7L14 0V1L7 8.5L0 1Z" fill="black" fill-opacity="0.13"/></svg>');
	pointer-events: none;
}

[data-popper-placement='top'] .tooltip__arrow {
	bottom: var(--arrow-offset);
}

[data-popper-placement='bottom'] .tooltip__arrow {
	top: var(--arrow-offset);
	background: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 2L14 9L0 9L7 2Z" fill="white"/><path d="M14 8L14 9L7 2L0 9L8.74228e-08 8L7 0.499999L14 8Z" fill="black" fill-opacity="0.13"/></svg>');
}
