/**
 * Block for applying the vertical spacing between sections on the page.
 */

.page-section {
	margin-top: 2rem;
}

@media (width < 768px) {
	.header + .page-section {
		margin-top: var(--page-margin);
	}
}

.page-section.page-section--flush {
	margin-top: 0;
}

.page-section--inner-spacing {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media (width >= 992px) {
	.page-section--extra-spacing {
		margin-top: 4rem;
	}

	.page-section--inner-spacing {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

.page-section--pink {
	background-color: var(--color-pale-red);
}

.page-section--blue {
	background-color: var(--color-pale-blue);
}

.page-section--green {
	background-color: var(--color-pale-green);
}

.page-section__image {
	top: -1rem;
	box-shadow: var(--shadow-large);
}

@media (width < 576px) {
	.page-section__image {
		box-shadow: var(--shadow-medium);
	}
}

/* If page-sections are wrapped, give it the same top and bottom margin */
.page-section > .page-section {
	margin-bottom: 2rem;
}
.page-section > .page-section.page-navigation:first-child,
.page-intro + .page-section:has(> .page-navigation:first-child) {
	margin-top: 0;
}
