.support-mini {
	display: block;
	border: 1px solid var(--color-thin-line);
	background-color: var(--color-background);
	box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05),
		0 0.1rem 0.1rem rgba(0, 0, 0, 0.02);
}

a.support-mini {
	color: inherit;
	text-decoration: inherit;
}

.support-mini__title {
	font-size: 1.25rem;
	font-weight: 600;
	margin-top: 1rem;
}

.support-mini__title:not(:last-child) {
	margin-bottom: 0.4rem;
}

.support-mini__title--small {
	font-size: 1rem;
	font-weight: 700;
}

.support-mini__description {
	font-size: 0.9rem;
	margin-top: 0;
}

.support-mini__description:not(:last-child) {
	margin-bottom: 0;
}

.support-mini__price {
	margin-top: 0.75rem;
	font-size: 1.25rem;
	line-height: 1.2;
}

.support-mini__price:not(:last-child) {
	margin-bottom: 0.8rem;
}

.support-mini__action:not(:first-child) {
	margin-top: 0.8rem;
}

.support-mini__content {
	padding-left: 1rem;
	padding-right: 1rem;
	text-align: center;
	margin-bottom: 1.25rem;
}

@media (max-width: 575px) {
	.support-mini__description {
		font-size: 0.8rem;
	}

	.support-mini__price {
		font-size: 1rem;
	}

	.support-mini__content {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
}
