/**
 * This is used to mimick half padding of the combined classes
 * .short-text and .grid__col--padded that is used in support-us.html
 */
.support-mini-wrapper {
	padding-left: 1rem;
	padding-right: 1rem;
	margin-bottom: 0;
}

.support-mini-wrapper:first-child {
	padding-top: 1rem;
}

@media (width >= 768px) and (width < 1024px) {
	.support-mini-wrapper {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

@media (width >= 1200px) {
	.support-mini-wrapper {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		margin-bottom: 0.5rem;
	}

	.support-mini-wrapper:first-child {
		padding-top: 1.5rem;
	}
}
