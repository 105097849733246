.campaign-navbar {
	background-color: #000;
	width: 100%;
	position: relative;
	--header-height: 2.7rem;
	height: var(--header-height);
}

.campaign-navbar__nav {
	padding-left: 0;
	list-style: none;
	background-color: #000;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
}

.campaign-navbar__nav a {
	color: #fff;
	text-decoration: none;
}

.campaign-navbar__hamburger {
	height: 2.2rem;
	width: 2.2rem;
	margin-right: 0.3rem;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	background-image: url('../../assets/hamburger.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.campaign-navbar--open .campaign-navbar__hamburger {
	background-image: url('../../assets/close.svg');
}

@media (min-width: 768px) {
	.campaign-navbar__hamburger {
		display: none;
	}

	.campaign-navbar__nav {
		align-items: center;
		justify-content: space-around;
		height: 100%;
	}
}

@media (max-width: 767px) {
	.campaign-navbar {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.campaign-navbar__nav {
		display: none;
	}

	.campaign-navbar--open .campaign-navbar__nav {
		display: block;
		position: absolute;
		top: var(--header-height);
		flex-direction: column;
		right: 0;
		left: 0;
		z-index: 99;
		align-items: start;
		padding: 0.2rem 1.4rem 0.8rem;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.campaign-navbar__nav a {
		display: inline-block;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
}
