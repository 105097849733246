.card {
	background-color: var(--color-background);
	box-shadow: var(--shadow-medium);
	border-radius: var(--card-border-radius);
	overflow: hidden;
	font-size: 0.85rem;
	padding: 1rem 1.25rem;
	color: inherit;
	text-decoration: none;

	display: grid;
	grid-template-columns: auto minmax(0, 1fr);
	grid-template-rows: auto minmax(0, 1fr) auto;
}

a.card:active {
	color: inherit;
}

.card__image {
	grid-column: 1 / 3;
	grid-row: 1;
	margin: -1rem -1.25rem 1rem;
}

.card__image > img {
	display: block;
	width: 100%;
	aspect-ratio: 16 / 9;
	height: auto;
	object-fit: cover;
}

.card svg {
	grid-column: 1;
	grid-row: 2;
	margin: -0.25rem 0.5rem 0 -0.25rem;
}

.card__content {
	grid-column: 2;
	grid-row: 2;
}

.card__content--extra-spacing {
	padding: 1rem;
}

.card__heading {
	font-size: 1.25rem;
	font-weight: bold;
	color: var(--color-blue);
}

.card__heading:not(:last-child) {
	margin-bottom: 0.35rem;
}

.card__heading--black {
	color: var(--color-black);
}

.card__intro {
	font-weight: 600;
}

.card__read-more {
	grid-column: 2;
	grid-row: 3;
	justify-self: left;
	margin-top: 0.45rem;
	color: var(--color-blue);
}

@media (width < 576px) {
	.card__read-more:not(.card__read-more--with-cta-mobile) {
		display: none;
	}
}
