form:not(.u-no-style) {
	font-size: 0.85rem;
}

input,
select,
textarea,
.button {
	appearance: none;
	background-color: var(--input-background-color);
	border: 1px solid var(--input-border-color);
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0);
	transition-property: color, box-shadow, border-color, background-color;
	transition-duration: 100ms, 100ms, 100ms, 150ms;
	transition-timing-function: ease-out;
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	line-height: inherit;
}

input:focus,
select:focus,
textarea:focus,
.button:focus {
	outline: 0;
	box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.04);
	border-color: var(--input-border-color--focus);
}

.js.tabbed input:focus,
.js.tabbed select:focus,
.js.tabbed textarea:focus,
.js.tabbed .button:focus {
	outline: 0;
	box-shadow: var(--input-prominent-focus-inner) 0 0 0 0.05rem,
		var(--input-prominent-focus) 0 0 0 0.2rem;
}

.js.tabbed input:focus,
.js.tabbed select:focus,
.js.tabbed textarea:focus {
	border-color: var(--input-border-color);
}

.js.tabbed .button:focus {
	border-color: var(--button-border-color);
}

.js.tabbed .button--small:focus,
.js.tabbed .button--outlined:focus {
	border-color: var(--button-border-color);
}

input:not(:disabled):hover,
select:not(:disabled):hover,
textarea:not(:disabled):hover,
.button:not(:disabled):hover {
	background-color: var(--input-background-color--hover);
	border-color: var(--input-border-color--hover);
}

/**
 * Buttons
 */

.button {
	display: inline-block;
	border-radius: 5rem;
	font-size: 1rem;
	line-height: 1.3;
	color: var(--button-border-color);
	border-color: currentColor;
	padding: 0.5rem 1rem 0.5rem;
	text-decoration: none;
	background-color: transparent;
	cursor: pointer;
}

.button:disabled {
	cursor: default;
}

.button:not(:disabled):hover {
	color: #fff;
	border-color: #164dad;
	background-color: #164dad;
	box-shadow: 0 3px 16px rgba(0, 0, 0, 0.3);
}

.button:active {
	color: #fff;
	border-color: #123471;
	background-color: #123471;
	box-shadow: 0 3px 16px rgba(0, 0, 0, 0) !important;
}

a.button:hover,
a.button:active {
	text-decoration: none;
}

button:focus {
	border-color: #123471;
}

.button--small {
	font-size: 0.85rem;
	padding: 0.35rem 0.8rem 0.35rem;
}

.button.button--filled,
.button.button--primary,
.button.button--primary-blue,
.button.button--primary-color {
	color: #fff;
	box-shadow: 0 0.15rem 0.8rem rgba(0, 0, 0, 0.16);
}

.button.button--filled:hover,
.button.button--primary:hover,
.button.button--primary-blue:hover,
.button.button--primary-color:hover {
	color: #fff;
	box-shadow: 0 0.15rem 0.8rem rgba(0, 0, 0, 0.2);
}

.button.button--filled,
.button.button--primary-blue {
	border-color: var(--color-blue);
	background-color: var(--color-blue);
}

.button.button--filled:hover,
.button.button--primary-blue:hover {
	border-color: #164dad;
	background-color: #164dad;
}

.button.button--filled:disabled,
.button.button--filled:disabled:hover,
.button.button--primary-blue:disabled,
.button.button--primary-blue:disabled:hover {
	border-color: #c6c6c6;
	background-color: #c6c6c6;
	box-shadow: none;
}

.button.button--primary,
.button.button--primary-blue {
	font-weight: 600;
	font-size: 1.1rem;
	padding: 0.7rem 1.25rem;
}

.button.button--primary,
.button.button--primary-color {
	border-color: var(--color-blue);
	background-color: var(--color-blue);
}

.button.button--primary:hover,
.button.button--primary-color:hover {
	border-color: #164dad;
	background-color: #164dad;
}

.button.button--primary:active,
.button.button--primary-color:active {
	border-color: #123471;
	background-color: #123471;
}

.button.button--filled:focus,
.button.button--primary:focus,
.button.button--primary-color:focus {
	box-shadow: 0 0.15rem 0.8rem rgba(0, 0, 0, 0.16);
	border-color: rgba(0, 0, 0, 0.25);
}

.button.button--centered {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.button__symbol {
	vertical-align: -0.1em;
	width: auto;
	height: 0.9rem;
}

.button__symbol:first-child {
	margin-right: 0.1rem;
}

.button__symbol:last-child {
	margin-left: 0.1rem;
}

/* Simple outlined version */
.button.button--outlined {
	background-color: transparent;
	--button-border-color: currentColor;
	color: inherit;
}
.button.button--outlined:hover {
	box-shadow: none;
	border-color: currentColor;
}
.button.button--outlined:focus {
	box-shadow: inset 0 0 0 1px currentColor;
}

/**
 * Button that looks like a link
 */
.link {
	appearance: none;
	display: inline-block;
	border: 0;
	padding: 0;
	background: transparent;
	font: inherit;
	text-align: inherit;

	color: var(--color-link);
	cursor: pointer;
}

.link:not([disabled]):hover {
	text-decoration: underline;
}

.link:not([disabled]):active {
	color: var(--color-link--active);
}

.link--edit::before {
	content: '';
	display: inline-block;
	background-image: url('data:image/svg+xml;utf8,<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style="width: 1.4rem; height: 1.4rem;"><path d="M20.612 8.982a2.39 2.39 0 00-1.695.71l-9.225 9.224-.036.183-.638 3.209-.182.856.857-.182 3.208-.638.182-.037 9.225-9.224a2.379 2.379 0 000-3.39 2.39 2.39 0 00-1.696-.711zm0 1.112c.294 0 .59.134.875.419.567.567.567 1.182 0 1.75l-.42.4-1.732-1.731.402-.419c.285-.285.58-.42.875-.42zm-2.096 1.659l1.731 1.732-7.054 7.053a3.976 3.976 0 00-1.733-1.731l7.056-7.054zm-7.802 8.002a2.76 2.76 0 011.53 1.532l-1.914.382.384-1.914z" fill="%231c3f8c"></path></svg>');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 1.4rem 1.4rem;
	width: 0.9rem;
	height: 0.9rem;
	vertical-align: -0.1rem;
}

.link--danger,
.link--danger:not([disabled]):active {
	color: var(--color-red);
}

/**
 * Text inputs
 */

[type='color'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
input:not([type]),
select,
textarea {
	border-radius: var(--input-border-radius);
	padding: var(--input-padding-y) var(--input-padding-x);
	min-height: 2.1rem;
	width: 8rem;
}

textarea {
	resize: vertical;
}

/**
 * Select inputs
 *
 * The inlined SVGs have stroke colors hardcoded. They need to be manually
 * updated if the colors are changed.
 */

select {
	padding-right: 3rem;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 128"><path d="M16.744 61.121l5.986 5.987 5.988-5.987" stroke="%23707070" stroke-width="2" fill="none"/><path stroke="%23e5e5e5" fill="none" d="M.5-.5v129"/></svg>');
	background-position: right center;
	background-repeat: no-repeat;
	background-size: 2.3rem 6.4rem;
}

select:hover,
select:focus {
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 128"><path d="M16.744 61.121l5.986 5.987 5.988-5.987" stroke="%23707070" stroke-width="2" fill="none"/><path stroke="%23cedcf4" fill="none" d="M.5-.5v129"/></svg>');
}

select::-ms-expand {
	display: none;
}

/**
 * Number
 */

input[type='number'] {
	-moz-appearance: textfield;
	appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

/**
 * Checkbox and radio
 */

input[type='checkbox'],
input[type='radio'] {
	width: 0.85rem;
	height: 0.85rem;
	margin-right: 0.25rem;
	vertical-align: -0.15rem;
	background-repeat: no-repeat;
	background-position: center;
}

input[type='checkbox']:checked,
input[type='radio']:checked {
	border-color: var(--color-blue);
}

input[type='radio'] {
	border-radius: 0.85rem;
	background-size: 0.35rem 0.35rem;
	background-position: center center;
}

input[type='checkbox'] {
	border-radius: 0.15rem;
}

input[type='radio']:not(:checked):hover {
	background-image: url('../../assets/radio-circle-blue.svg');
	background-position: center center;
}

input[type='radio']:checked,
input[type='radio']:checked:valid,
input[type='radio']:checked:invalid,
input[type='radio']:checked:valid:hover,
input[type='radio']:checked:invalid:hover {
	background-color: var(--input-radio-checked);
	background-image: url('../../assets/radio-circle.svg');
	background-position: center center;
}

input[type='checkbox']:checked {
	background-color: var(--input-background-color);
	background-image: url('../../assets/checkmark.svg');
}

/**
 * Date field
 */

input[type='date']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	display: none;
}

input[type='date'] {
	padding-right: 0.5rem;
}

input[type='date']::-webkit-calendar-picker-indicator {
	padding-left: 0;
	margin-left: 0;
}

/**
 * Validation styles
 *
 * Only shown when utility class u-show-validation is added. This is
 * typically added with javascript after the user interacted with a
 * field to avoid showing invalid state prematurely.
 */

/* Base style */
input.u-show-validation,
select.u-show-validation,
textarea.u-show-validation {
	background-position: right center;
	background-repeat: no-repeat;
}

input.u-show-validation::-ms-clear,
select.u-show-validation::-ms-clear,
textarea.u-show-validation::-ms-clear {
	display: none;
}

/* Invalid */
input.u-show-validation:invalid,
select.u-show-validation:invalid,
textarea.u-show-validation:invalid {
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0);
	background-color: var(--input-background-color--invalid);
	border-color: var(--input-border-color--invalid);
}

input.u-show-validation:invalid:hover,
select.u-show-validation:invalid:hover,
textarea.u-show-validation:invalid:hover {
	background-color: var(--input-background-color--invalid-hover);
	border-color: var(--input-border-color--invalid-hover);
}

input[type='color'].u-show-validation:invalid,
/*input[type='date'].u-show-validation:invalid,*/
input[type='datetime'].u-show-validation:invalid,
input[type='datetime-local'].u-show-validation:invalid,
input[type='email'].u-show-validation:invalid,
input[type='month'].u-show-validation:invalid,
input[type='number'].u-show-validation:invalid,
input[type='password'].u-show-validation:invalid,
input[type='search'].u-show-validation:invalid,
input[type='tel'].u-show-validation:invalid,
input[type='text'].u-show-validation:invalid,
input[type='time'].u-show-validation:invalid,
input[type='url'].u-show-validation:invalid,
input[type='week'].u-show-validation:invalid,
input:not([type]).u-show-validation:invalid {
	padding-right: 1.8rem;
	background-image: url('../../assets/invalid.svg');
}

input[type='checkbox'].u-show-validation:invalid {
	background-color: var(--input-background-color--invalid-hover);
	border-width: 2px;
}

/* Valid */
input.u-show-validation:valid,
select.u-show-validation:valid,
textarea.u-show-validation:valid {
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0);
	background-color: var(--input-background-color--valid);
	border-color: var(--input-border-color--valid);
}

input.u-show-validation:valid:hover,
select.u-show-validation:valid:hover,
textarea.u-show-validation:valid:hover {
	background-color: var(--input-background-color--valid-hover);
	border-color: var(--input-border-color--valid-hover);
}

input[type='color'].u-show-validation:valid,
/*input[type='date'].u-show-validation:valid,*/
input[type='datetime'].u-show-validation:valid,
input[type='datetime-local'].u-show-validation:valid,
input[type='email'].u-show-validation:valid,
input[type='month'].u-show-validation:valid,
input[type='number'].u-show-validation:valid,
input[type='password'].u-show-validation:valid,
input[type='search'].u-show-validation:valid,
input[type='tel'].u-show-validation:valid,
input[type='text'].u-show-validation:valid,
input[type='time'].u-show-validation:valid,
input[type='url'].u-show-validation:valid,
input[type='week'].u-show-validation:valid,
input:not([type]).u-show-validation:valid {
	padding-right: 1.8rem;
	background-image: url('../../assets/valid.svg');
}

/**
 * Fieldset
 *
 * Used for grouping elements. The only styling applied here is margin between fieldsets
 * and legends. The rest of the styling is provided by other components.
 */

fieldset {
	border: 0;
	padding: 0;
	margin: 0;
	min-inline-size: auto;
}

fieldset:not(.u-no-style):not(:first-child) {
	margin-top: var(--fieldset-margin, 1rem);
}

fieldset:not(.u-no-style):not(:last-child) {
	margin-bottom: var(--fieldset-margin, 1rem);
}

@media (width >= 768px) {
	fieldset:not(.u-no-style):not(:first-child) {
		margin-top: var(--fieldset-margin, 2rem);
	}

	fieldset:not(.u-no-style):not(:last-child) {
		margin-bottom: var(--fieldset-margin, 2rem);
	}
}

fieldset legend {
	margin: 0;
	margin-bottom: 1rem;
}

fieldset:not(.u-no-style) + fieldset:not(.u-no-style) {
	margin-top: var(--fieldset-margin, 2rem);
}

#order_review {
	margin-top: 2rem;
}

/**
 * In grid layouts
 *
 * When fields are placed in a grid column, they should expand to fill the width of
 * the column.
 */

.grid__col > .input,
.grid__col > [type='color'],
.grid__col > [type='date'],
.grid__col > [type='datetime'],
.grid__col > [type='datetime-local'],
.grid__col > [type='email'],
.grid__col > [type='month'],
.grid__col > [type='number'],
.grid__col > [type='password'],
.grid__col > [type='search'],
.grid__col > [type='tel'],
.grid__col > [type='text'],
.grid__col > [type='time'],
.grid__col > [type='url'],
.grid__col > [type='week'],
.grid__col > input:not([type]),
.grid__col > select,
.grid__col > textarea,
.grid__col > .input > [type='color'],
.grid__col > .input > [type='date'],
.grid__col > .input > [type='datetime'],
.grid__col > .input > [type='datetime-local'],
.grid__col > .input > [type='email'],
.grid__col > .input > [type='month'],
.grid__col > .input > [type='number'],
.grid__col > .input > [type='password'],
.grid__col > .input > [type='search'],
.grid__col > .input > [type='tel'],
.grid__col > .input > [type='text'],
.grid__col > .input > [type='time'],
.grid__col > .input > [type='url'],
.grid__col > .input > [type='week'],
.grid__col > .input > input:not([type]) {
	width: 100%;
}
