.adoveo-iframe {
}

.adoveo-iframe:not(:first-child) {
	margin-top: 2rem;
}

.adoveo-iframe:not(:last-child) {
	margin-bottom: 2rem;
}

.adoveo-iframe--swish {
}

.adoveo-iframe--greeting .iframe-container {
	height: 500px;
	padding-top: 0;
}
