.company-presentation {
	display: flex;
}

.company-presentation:not(:first-child) {
	margin-top: 1rem;
}

p + .company-presentation:not(:first-child) {
	margin-top: 1.5rem;
}

.company-presentation:not(:last-child) {
	margin-bottom: 1.5rem;
}

.company-presentation__image {
	margin-right: calc(var(--grid-gutter) * 1.5);
	width: 10rem;
	flex-shrink: 0;
	margin-top: 0.2em;
}

.company-presentation__content h3:first-child,
.company-presentation__content .h3:first-child {
	margin-top: 0.2rem;
}

.company-presentation__content h3:not(:last-child),
.company-presentation__content .h3:not(:last-child) {
	margin-bottom: 0.45rem;
}

@media (width < 1200px) {
	.company-presentation__image {
		width: 6rem;
		margin-right: var(--grid-gutter);
	}
}

@media (width < 568px) {
	.company-presentation__image {
		width: 5rem;
	}

	.company-presentation__content {
		font-size: 0.8rem;
	}
}

@media (width < 568px) {
	.company-presentation__image {
		width: 3.5rem;
	}
}
