.pie-chart {
}

.pie-chart__heading {
	font-size: 0.75rem;
	text-transform: uppercase;
}

.pie-chart__heading:not(:last-child) {
	margin-bottom: 1.2rem;
}

.pie-chart__container {
	display: flex;
	align-items: center;
}

.pie-chart__pie-chart {
	margin-right: 1.8rem;
}

.pie-chart__item {
	display: flex;
	justify-content: space-between;
	font-size: 0.8rem;
}

.pie-chart__item dt {
	margin-left: 1rem;
	text-indent: -1.15rem;
}

.pie-chart__item dt svg {
	margin-right: 0.2rem;
	vertical-align: -0.1rem;
}

.pie-chart__item dd {
	margin-left: 0.5rem;
}

@media (max-width: 991px) {
	.pie-chart {
		max-width: 30rem;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (max-width: 576px) {
	.pie-chart__container {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;
	}

	.pie-chart__pie-chart {
		margin-bottom: 1rem;
	}
}

@media (max-width: 375px) {
	.pie-chart__description {
		width: 100%;
	}
}
