.arrow {
	position: relative;
}

.arrow::before {
	content: '';
	width: 0.5rem;
	height: 0.5rem;
	display: block;
	background-color: inherit;
	border: 0;
	border-top: 1px solid var(--color-thin-line);
	border-left: 1px solid var(--color-thin-line);
	box-shadow: inherit;
	position: absolute;
	top: -0.28rem;
	left: 50%;
	transform: matrix(0.95, 0.46, -0.95, 0.46, 0, 0);
}

.arrow--align-contact-button::before {
	left: 6.7rem;
}

.arrow--1-2::before {
	left: calc(100% * 1 / 2);
}

.arrow--1-3::before {
	left: calc(100% * 1 / 3);
}

.arrow--2-3::before {
	left: calc(100% * 2 / 3);
}

.arrow--1-4::before {
	left: calc(100% * 1 / 4);
}

.arrow--2-4::before {
	left: calc(100% * 2 / 4);
}

.arrow--3-4::before {
	left: calc(100% * 3 / 4);
}

.arrow--1-5::before {
	left: calc(100% * 1 / 5);
}

.arrow--2-5::before {
	left: calc(100% * 2 / 5);
}

.arrow--3-5::before {
	left: calc(100% * 3 / 5);
}

.arrow--4-5::before {
	left: calc(100% * 4 / 5);
}

/**
 * Matching columns
 */

.arrow--col-1-4::before {
	left: calc((100% * 0.5 / 4) - (var(--grid-gutter) * 0.5));
}

.arrow--col-2-4::before {
	left: calc((100% * 1.5 / 4) - (var(--grid-gutter) * 0.25));
}

.arrow--col-3-4::before {
	left: calc((100% * 2.5 / 4) - (var(--grid-gutter) * 0.125));
}

.arrow--col-4-4::before {
	left: calc((100% * 3.5 / 4) - (var(--grid-gutter) * 0.0625));
}

/**
 * Special colors for different places
 */

.tabbed-area__content-section.arrow::before {
	border-color: var(--color-thin-line-solid);
}
