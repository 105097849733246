.popover {
	background-color: rgba(0, 0, 0, 0.2);
	display: none;

	align-items: center;
	justify-content: center;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	overflow: hidden;
	z-index: 200;
}

.popover.popover--open {
	display: flex;
	opacity: 1;
	pointer-events: auto;
}

.popover__box {
	background-color: #fff;
	width: calc(20rem + var(--page-margin) * 4);
	max-width: 95vw;
	height: auto;
	border-radius: 0.15rem;

	padding: calc(var(--page-margin) * 2);
	position: absolute;

	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	text-align: center;
}

.popover--wide .popover__box {
	width: calc(30rem + var(--page-margin) * 4);
}

.popover--products .popover__box {
	text-align: left;
	padding: 1.2rem;
}

.popover__close {
	position: fixed;
	top: 1rem;
	right: 1rem;
	z-index: 210;

	border: 0;
	color: transparent;
	background-color: #000;
	background-image: url('data:image/svg+xml,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l14 14M1 15L15 1" stroke="%23FFF" stroke-width="2" fill="none" /></svg>');
	background-position: center;
	background-repeat: no-repeat;

	width: 1.8rem;
	height: 1.8rem;
	border-radius: 1rem;

	overflow: hidden;
	text-indent: 2rem;
	white-space: nowrap;
	cursor: pointer;
}

.popover__close:active {
	transform: scale(0.95);
}

.popover__actions {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1.25rem;
}

.popover__actions > *:not(:first-child) {
	margin-top: 0.5rem;
}

@media (width > 576px) {
	.popover__actions--horizontal {
		flex-direction: row;
		justify-content: center;
	}

	.popover__actions > *:not(:first-child) {
		margin-top: 0;
	}

	.popover__actions--horizontal > *:not(:first-child) {
		margin-left: 0.5rem;
	}
}

.admin-bar .popover {
	top: 32px;
}

.admin-bar .popover__close {
	top: calc(32px + 1rem);
}

@media screen and (max-width: 782px) {
	.admin-bar .popover {
		top: 46px;
	}

	.admin-bar .popover__close {
		top: calc(46px + 1rem);
	}
}

@media screen and (max-width: 600px) {
	.admin-bar .popover {
		top: 0;
	}

	.admin-bar .popover__close {
		top: calc(1rem);
	}
}
