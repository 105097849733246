/**
 * TODO decide on naming
 *
 * For general form styles, see globals/forms.css
 */

.js .input {
	display: inline-block;
	position: relative;
}

.js .input__label {
	position: absolute;
	top: 0.55rem;
	left: var(--input-padding-x);
	right: var(--input-padding-x);
	pointer-events: none;
	text-align: left;

	color: var(--input-placeholder-color);

	transform-origin: 0 0;

	transition-property: transform, top;
	transition-duration: 200ms;
	transition-timing-function: cubic-bezier(0.07, 0.6, 0.45, 1);

	user-select: none;
}

.js .input:has(input.input__field) .input__label {
	white-space: nowrap;
}

.input--full-width,
.input--full-width .input__field {
	width: 100%;
}

/* When the label should give way for the content in the field */
.input--is-focused .input__label,
.input--has-content .input__label {
	transform: scale(0.6);
	top: 0.2em;
	left: calc(var(--input-padding-x) + 0.05rem);
	width: 150%;
}

.js .input > input[type='date'] {
	transition: color 200ms linear;
}
.js
	.input:not(.input--has-content):not(.input--is-focused)
	> input[type='date'] {
	color: transparent;
}

.js .input .input__field:not(.input__field--no-inline-label),
.js .input .input__suffix:not(.input__field--no-inline-label ~ .input__suffix) {
	transition-property: margin-top, margin-bottom, padding-top, padding-bottom;
	transition-duration: 200ms;
	transition-timing-function: cubic-bezier(0.07, 0.6, 0.45, 1);
}

@media (width >= 576px) {
	.js .input--is-focused .input__field:not(.input__field--no-inline-label),
	.js .input--has-content .input__field:not(.input__field--no-inline-label),
	.js
		.input--is-focused
		.input__suffix:not(.input__field--no-inline-label ~ .input__suffix),
	.js
		.input--has-content
		.input__suffix:not(.input__field--no-inline-label ~ .input__suffix) {
		margin-top: -0.2rem;
		margin-bottom: -0.2rem;
		padding-top: calc(var(--input-padding-y) + 0.3rem + 0.25rem);
		padding-bottom: calc(var(--input-padding-y) - 0.3rem + 0.15rem);
	}

	.js .input--is-focused .input__meta,
	.js .input--has-content .input__meta {
		bottom: calc(var(--input-padding-x) - 0.3rem + 0.15rem);
	}
}

.js .input__field:not(.input__field--no-inline-label) {
	padding-top: calc(var(--input-padding-y) + 0.3rem);
	padding-bottom: calc(var(--input-padding-y) - 0.3rem);
}

.input__meta {
	position: absolute;
	pointer-events: none;
	color: var(--input-placeholder-color);
	left: var(--input-padding-x);
	right: var(--input-padding-x);
	bottom: var(--input-padding-x);
	font-size: 0.75rem;
	line-height: 1;
	text-align: right;
	opacity: 0;
	transition: opacity 150ms ease-out;
}

.input__meta--outside {
	bottom: -0.3rem;
	left: 0;
	right: 0;
	transform: translateY(100%);
}

.input--has-content .input__meta {
	opacity: 1;
}

.input--with-suffix,
.js .input--with-suffix {
	display: inline-flex;
}
.input--with-suffix .input__field {
	border-radius: var(--input-border-radius) 0 0 var(--input-border-radius);
}

.input--with-suffix .input__suffix {
	padding: var(--input-padding-x) var(--input-padding-y);
	border: 1px solid var(--input-border-color);
	background-color: var(--input-background-color);
	border-left: 0;
	border-radius: 0 var(--input-border-radius) var(--input-border-radius) 0;
	pointer-events: none;

	/* Same transitions as input */
	transition-property: color, box-shadow, border-color, background-color;
	transition-duration: 100ms, 100ms, 100ms, 150ms;
	transition-timing-function: ease-out;
}

.input--with-suffix input {
	padding-right: 0;
}

.input--with-suffix input:focus ~ .input__suffix {
	border-color: var(--input-border-color--focus);
}

.input--with-suffix input:hover ~ .input__suffix {
	border-color: var(--input-border-color--hover);
	background-color: var(--input-background-color--hover);
}

.input--with-suffix > input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	display: none;
}

.input--with-suffix > input[type='number'] {
	-moz-appearance: textfield;
}

.input--with-suffix > input.u-show-validation:valid,
.input--with-suffix > input.u-show-validation:invalid {
	background-image: none;
}

/* Invalid */
.input--with-suffix > input.u-show-validation:invalid ~ .input__suffix {
	border-color: var(--input-border-color--invalid);
	background-color: var(--input-background-color--invalid);
}

.input--with-suffix > input.u-show-validation:invalid:hover ~ .input__suffix {
	border-color: var(--input-border-color--invalid-hover);
	background-color: var(--input-background-color--invalid-hover);
}

/* Valid */
.input--with-suffix > input.u-show-validation:valid ~ .input__suffix {
	border-color: var(--input-border-color--valid);
	background-color: var(--input-background-color--valid);
}

.input--with-suffix > input.u-show-validation:valid:hover ~ .input__suffix {
	border-color: var(--input-border-color--valid-hover);
	background-color: var(--input-background-color--valid-hover);
}
