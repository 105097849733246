.package-select {
}

.package-select__top {
	padding: 1.25rem 1rem 1.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (width >= 576px) {
	.package-select__input {
		font-size: 1.5rem;
	}

	.package-select__input > input {
		width: 100%;
		max-width: 9.5rem;
	}
}

@media (width >= 768px) {
	.package-select__input {
		font-size: 2rem;
	}

	.package-select__input > input {
		max-width: 12rem;
	}
}

.package-select__input > input::placeholder {
	color: #b5b5b5;
}

.package-select__recommend {
	margin-top: 1rem;
}

.package-select__packages {
}

.package-select__list {
	padding: var(--page-margin);
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	grid-template-rows: repeat(4, auto);
	grid-auto-flow: column;
	border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
	margin-top: 1rem;
	margin-bottom: 0 !important;
	border: 1px solid var(--color-thin-line);
}

@media (max-width: 575px) {
	.package-select__list {
		grid-auto-flow: row;
		grid-template-columns: minmax(0, 1fr);
		grid-template-rows: auto;
	}
}

.package-select__actions {
	text-align: center;
	background-color: var(--color-light-blue);
	border: 1px solid var(--color-thin-line);
	border-top: 0;
	border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
	padding: 1rem var(--page-margin);
}
