/**
 * Dimmed text
 */

.dimmed {
	color: var(--color-gray);
	font-size: 0.9em;
}

.dimmed a {
	color: inherit;
	text-decoration: underline;
}

/* In combination with headings */
.dimmed + h1,
.dimmed + h2,
.dimmed + h3,
.dimmed + h4,
.dimmed + .h1,
.dimmed + .h2,
.dimmed + .h3,
.dimmed + .h4 {
	margin-top: 0.5rem;
}
