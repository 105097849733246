.direct-donation {
}

.direct-donation__heading {
}

.direct-donation__intro {
	margin-left: auto;
	margin-right: auto;
	max-width: 34rem;
	margin-bottom: 2rem;
}

@media (width >= 992px) {
	.direct-donation__intro {
		text-align: center;
	}
}

.direct-donation__methods {
}

.direct-donation__method {
	display: block;
	text-align: center;

	padding: 2.2rem 1rem;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.direct-donation__method--swish {
	background-color: var(--color-pale-green);
}

.direct-donation__method--bankgirot {
	background-color: var(--color-pale-red);
}

.direct-donation__method--plusgirot {
	background-color: var(--color-light-blue);
}

.direct-donation__number {
	border-bottom: 1px solid var(--color-thin-line);
	margin-bottom: 1rem;
	white-space: nowrap;
}

.direct-donation__number:not(:first-child) {
	margin-top: 0;
}

.direct-donation__logo {
	display: flex;
	min-height: 3rem;
	align-items: center;
}

.direct-donation__logo > img,
.direct-donation__logo > noscript > img {
	max-width: 100%;
	width: 6.75rem;
	height: auto;
}

@media (width < 768px) {
	.direct-donation__method {
		flex-direction: row;
		justify-content: space-between;
	}

	.direct-donation__number {
		margin-bottom: 0;
	}
}

@media (width < 576px) {
	.direct-donation__method {
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
	}
}
