.newsfeed {
}

.newsfeed__top {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

@media (width >= 992px) {
	.newsfeed__top {
		margin-bottom: 1rem;
	}
}

.newsfeed__see-more {
	margin-top: -0.05rem;
}

.newsfeed__see-more a {
	color: var(--color-blue);
	text-decoration: none;
}

.newsfeed__item {
	box-shadow: inset 0 0 0 1px var(--color-thin-line);
}

a.newsfeed__item {
	color: inherit;
	text-decoration: inherit;
}

@supports (object-fit: cover) {
	.newsfeed__item {
		align-items: stretch;
		margin-top: 0;
		margin-left: 0;
		min-height: 11.5rem;
	}

	.newsfeed__image {
		object-fit: cover;
	}
}

@media (width >= 576px) {
	@supports (object-fit: cover) {
		.newsfeed__image {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%;
		}
	}
}

.newsfeed__image {
	width: 100%;
	background-color: #eee;
}

.newsfeed__content {
	padding-top: 1rem;
	padding-right: 1rem;
	padding-bottom: 1rem;
}

.newsfeed__item--no-image .newsfeed__content {
	padding-left: 1rem;
}

@media (width < 576px) {
	.newsfeed__content {
		padding-left: 1rem;
	}
}

.newsfeed__content--spread {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.newsfeed__heading {
	font-size: 1.25rem;
	font-weight: 400;
}
