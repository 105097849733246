.list {
	padding-left: 0;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	--gap: 1.2rem;
	column-gap: var(--gap);
	row-gap: 4.8rem;
}

.list--scrollable {
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	box-sizing: content-box;
	--columns: 3.15;

	margin-left: calc((100vw - 100%) * -0.5);
	margin-right: calc((100vw - 100%) * -0.5);
	padding-left: calc((100vw - 100%) * 0.5);
	padding-right: calc((100vw - 100%) * 0.5);
	padding-bottom: 0.65rem;

	-ms-overflow-style: none;
	scrollbar-width: none;

	scroll-snap-type: x mandatory;
	cursor: grab;
}

@media (max-width: 575px) {
	.list--scrollable > * {
		scroll-snap-align: center;
	}
}

.list--scrollable::-webkit-scrollbar {
	display: none;
}

.list--scrollable > * {
	flex-basis: 100%;
	--list-column-width: calc(
		(100% - (var(--gap) * (var(--columns) - 1))) / var(--columns)
	);
	min-width: max(15rem, var(--list-column-width));
	max-width: calc((100% - var(--gap)) / 2);
	transition: opacity 250ms linear;
}

@media (min-width: 1024px) {
	.list--scrollable > .is-outside {
		opacity: 0.25;
	}
}

@media (max-width: 840px) {
	.list {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

@media (max-width: 567px) {
	.list {
		grid-template-columns: minmax(0, 1fr);
		row-gap: 4.4rem;
	}
}

@media (max-width: 375px) {
	.list--scrollable > * {
		min-width: max(13rem, var(--list-column-width));
	}
}

/* Fix for flexbox and gap: https://caniuse.com/flexbox-gap */
.list--scrollable {
	column-gap: 0;
}
.list--scrollable > *:not(:last-child) {
	margin-right: var(--gap);
}
