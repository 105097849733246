.step-indicator {
	display: inline-block;
	border: 1px solid var(--color-green);
	width: 1.1rem;
	height: 1.1rem;
	border-radius: 1.1rem;
	position: relative;
	color: var(--color-green);
	background-color: #fff;
	font-size: 0.7rem;
	line-height: 1.55;
	text-align: center;
}

.step-indicator--filled {
	color: #fff;
	background-color: var(--color-green);
}
