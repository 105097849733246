/**
 * This block is used for both the subscribe form and the search form.
 */

.subscribe:not(:first-child) {
	margin-top: 1.25rem;
}

.subscribe__input,
.subscribe__input > input {
	width: 100%;
}

.subscribe.subscribe--center {
	width: 100%;
	max-width: 26rem;
	margin-left: auto;
	margin-right: auto;
}

@media (width < 576px) {
	.subscribe {
		flex-direction: column;
		align-items: flex-start;
	}

	.subscribe.subscribe--center {
		align-items: center;
	}

	.subscribe__input {
		margin-bottom: 0.4rem;
	}
}

@media (width >= 576px) {
	.subscribe {
		display: flex;
		align-items: flex-start;
	}

	.subscribe__input {
		flex: 1;
		margin-right: 0.4rem;
	}
}
