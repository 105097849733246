:root {
	--color-white: #fff;
	--color-black: #222;
	--color-blue: #1c3f8c;
	--color-red: #b31634;
	--color-light-green: #009375;
	--color-green: #178972;
	--color-pale-red: #fcebee;
	--color-beige: #f5ecdf;
	--color-darker-green: #178972;
	--color-pale-green: #f0f8f0;
	--color-gray: #989898;
	--color-active-blue: #007cd8;
	--color-pale-blue: #edf3fd;
	--color-light-blue: #edf3fd;
	--color-light-gray: #fcfcfc;
	--color-christmas: #006134;
	--color-beige: #f5ecdf;

	--color-legacy-red: #ac1a24;
	--color-orange: #ff523c;
	--color-christmas-2023: #d52822;

	--shadow-medium: 0 0.15rem 0.8rem rgba(98, 108, 104, 0.08);
	--shadow-large: 0 0.7rem 2.9rem rgba(20, 0, 0, 0.3);

	--color-text: var(--color-black);
	--color-background: #fff;
	--color-thin-line: rgba(0, 0, 0, 0.13);
	--color-thin-line-solid: #dedede;

	--color-link: var(--color-blue);
	--color-link--active: #014ac9;

	--header-height: 3.8rem;
	--header-logo-width: 10.05rem; /* 201px */
	--header-logo-height: 1.9rem; /* 38px */

	--page-width: 66rem;
	--page-margin: 2rem;
	--page-content-max-width: 34rem;
	--page-content-width: var(--page-content-max-width);
	--page-overlap-y: -1rem; /* how much boxes should overlap vertically */
	--page-overlap-x: -1.5rem; /* how much boxes should overlap horizontally */

	--form-spacing-x: 1rem;
	--form-spacing-y: 0.9rem;

	--grid-gutter: 1rem;
	--grid-row-margin: 0.9rem;

	--paragraph-margin: 1rem;
	--card-border-radius: 0.25rem;

	--input-background-color: #fff;
	--input-background-color--hover: #fafcff;
	--input-border-radius: 0.25rem;
	--input-padding-x: 0.5rem;
	--input-padding-y: 0.5rem;
	--input-placeholder-color: #888;
	--input-border-color: var(--color-thin-line-solid);
	--input-border-color--focus: var(--color-thin-line-solid);
	--input-border-color--hover: var(--input-border-color--focus);
	--input-outline--focus: rgba(165, 217, 255, 0.4);
	--input-radio-checked: #014ac9;

	--input-prominent-focus: #007cd888;
	--input-prominent-focus-inner: #fff;

	--input-border-color--invalid: var(--color-red);
	--input-border-color--invalid-hover: var(--color-red);
	--input-background-color--invalid: #ffffff;
	--input-background-color--invalid-hover: #fcebee;

	--input-border-color--valid: #a7c7bf;
	--input-border-color--valid-hover: var(--color-green);
	--input-background-color--valid: #fcfffc;
	--input-background-color--valid-hover: #fcfffc;

	--button-border-color: var(--color-blue);
	--image-align-center-extra-width: 4rem;

	--card-fold-mid-gradient: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0) 35%,
		rgba(0, 0, 0, 0.09) 49.95%,
		rgba(0, 0, 0, 0.12) 50%,
		rgba(0, 0, 0, 0.07) 50.25%,
		rgba(0, 0, 0, 0) 65%
	);
}

@media (width <= 370px) {
	:root {
		--page-margin: 0.4rem;
		--page-overlap-x: -0.3rem;
	}
}

@media (370px <= width < 576px) {
	:root {
		--page-margin: 0.6rem;
		--page-overlap-x: -0.5rem;
	}
}

@media (576px <= width < 768px) {
	:root {
		--page-margin: 1rem;
		--page-overlap-x: -0.75rem;
	}
}

@media (width < 1200px) {
	:root {
		--page-content-width: 28rem;
		--image-align-center-extra-width: 2rem;
	}
}

@media (width < 992px) {
	:root {
		--page-content-width: 24rem;
		--image-align-center-extra-width: 1rem;
	}
}
