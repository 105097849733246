.box-example {
	display: flex;
	flex-direction: column;
	border: 1px solid var(--color-thin-line);
	border-radius: var(--input-border-radius);
	overflow: hidden;
}

.box-example__image {
	background-color: var(--color-light-gray);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 7.75rem;
}

.box-example__title {
	padding: 0.75rem;
	border-top: 1px solid var(--color-thin-line);
}
