@media (width >= 768px) {
	.campaign {
		position: relative;
	}

	.campaign__image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
	}

	.campaign__image--mobile {
		display: none;
	}

	.campaign__content {
		color: #fff;

		position: relative;
		z-index: 2;
		padding: 3rem 3rem;
		max-width: 34rem;
	}

	.campaign__heading {
		font-size: 4.5rem;
		font-weight: normal;
		line-height: 0.85;
	}
}

@media (width < 768px) {
	.campaign {
		text-align: center;
	}

	.campaign__image--desktop {
		display: none;
	}

	.campaign__heading {
		margin-bottom: 0.5rem;
		margin-left: auto;
		margin-right: auto;
	}

	.campaign__content {
		padding-top: 1rem;
	}

	.campaign__button-set {
		justify-content: center;
		width: 100%;
	}
}
