.asset-list {
}

.asset-list__item {
	--paragraph-margin: 0.75rem;
}

.asset-list__image {
	display: block;
	border: 0.05rem solid var(--color-thin-line);
	padding: 1rem;
	margin-bottom: 1rem;
}

.asset-list__image > img {
	display: block;
	max-width: 100%;
}

.asset-list__symbol {
	position: absolute;
	left: 0.75rem;
	bottom: 0.75rem;
	z-index: 2;

	width: 1.75rem;
	height: 1.75rem;
	border: 1px solid var(--color-blue);
	border-radius: 1.75rem;
	background: url(../../assets/download-arrow-blue.svg) no-repeat center;
	background-color: #fff;
	transition-property: color, box-shadow, border-color, background-color;
	transition-duration: 100ms, 100ms, 100ms, 150ms;
	transition-timing-function: ease-out;
}

a:hover .asset-list__symbol {
	background-image: url(../../assets/download-arrow-white.svg);

	/* mirrors .button:hover styles */
	border-color: #164dad;
	background-color: #164dad;
	box-shadow: 0 3px 16px rgba(0, 0, 0, 0.3);
}

.asset-list__meta {
	font-size: 0.85rem;
	display: grid;
	grid-template-columns: auto 1fr;
}

.asset-list__meta dt,
.asset-list__meta dd {
	padding-right: 1rem;
	margin-left: 0;
	padding-bottom: 0.25rem;
}

@supports (object-fit: contain) {
	.asset-list__image {
		position: relative;
	}

	.asset-list__image::before {
		content: '';
		display: block;
		height: 0;
		width: 100%;
		padding-top: 90%;
	}

	.asset-list__image > img {
		object-fit: contain;
		position: absolute;
		top: 1rem;
		left: 1.5rem;
		width: calc(100% - 3rem);
		height: calc(100% - 2rem);
	}
}
