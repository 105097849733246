/**
 * TODO naming
 */

.short-text {
	padding-top: 1.5rem;
	padding-bottom: 1.6rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

/*  IE 11 flexbox bug fix */
.short-text > * {
	max-width: 100%;
}

.short-text__heading {
	margin-bottom: 1rem;
}

.short-text__quote {
	font-size: 1.5rem;
	line-height: 1.25;
	font-weight: 400;
}

.short-text__button-p {
	margin-top: 0.25rem;
}

@media (1024px < width < 1200px) {
	.short-text {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

@media (width >= 1200px) {
	.short-text {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.short-text__quote {
		font-size: 2rem;
	}
}
