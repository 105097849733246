.press-release {
	display: flex;
}

.press-release__content {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	margin: 1rem;
	max-width: var(--page-content-width);
}

.press-release__snippet {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
}

.press-release__actions {
	margin-top: 1rem;
}

@media (width >= 768px) {
	.press-release__image {
		width: calc(50% - var(--grid-gutter));
	}
}

@media (width < 768px) {
	.press-release {
		flex-direction: column;
	}
}
