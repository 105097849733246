.monthly-donation-concept {
	font-size: var(--font-size, inherit);
	line-height: 1.2;
	--paragraph-margin: 0.5rem;
}

.monthly-donation-concept > * {
	max-width: 24rem;
	margin-left: auto;
	margin-right: auto;
}

.monthly-donation-concept__description {
	max-width: 20rem;
	margin-left: auto;
	margin-right: auto;
}

.monthly-donation-concept__description:not(:last-child) {
	margin-bottom: 1rem;
}

.monthly-donation-concept__checkmark-list {
	font-size: var(--font-size);
	--paragraph-margin: 0.4rem;
}

@media (max-width: 575px) {
	.monthly-donation-concept {
		--font-size: 0.75rem;
	}

	.monthly-donation-concept__description {
		margin-top: 0.5rem;
	}
}

@media (min-width: 460px) {
	.monthly-donation-concept {
		--grid-gutter: 1rem;
	}
}
