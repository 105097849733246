/**
 * Implements a colored box with padding.
 */

.box {
	--box-spacing: 2rem;
	--box-background: var(--color-pale-blue);

	background-color: var(--box-background);
	padding: var(--box-spacing);
}

.content .box {
	margin-left: calc(var(--box-spacing) * -1);
	margin-right: calc(var(--box-spacing) * -1);
}

.page-card__content > .box {
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 575px) {
	.box {
		--box-spacing: 1rem;
	}
}
