.document {
	display: grid;
	grid-template-columns: auto minmax(0, 1fr);
	grid-template-rows: auto auto;
	grid-column-gap: var(--grid-gutter);
}

.document__image {
	grid-column: 1;
	grid-row: 2 / 4;
	max-width: 5rem;
}

.document__title {
	grid-column: 2;
	grid-row: 2;
	align-self: end;
}

.document__download {
	grid-column: 2;
	grid-row: 3;
}
