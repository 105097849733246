.bankid-button {
	white-space: nowrap;
}

.bankid-button__symbol {
	width: 1.3rem;
	height: 1.2rem;
	background: url('../../assets/bank-id.svg');
	background-size: 1.3rem 1.2rem;
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: middle;
}

.bankid-button__button + .bankid-button__symbol {
	margin-left: 0.65rem;
}

@media (width < 576px) {
	.bankid-button__button {
		font-size: 0.75rem;
		padding: 0.35rem 0.8rem 0.35rem;
	}

	.bankid-button__loading .loading__label {
		display: none;
	}
}

@media (width < 340px) {
	.bankid-button__button {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.bankid-button__button + .bankid-button__symbol {
		width: 0.8rem;
		height: 0.738rem;
		background-size: 0.8rem 0.738rem;
		margin-left: 0.45rem;
	}
}
