.bankid-sign-takeover {
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 300;

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
}

.bankid-sign-takeover__block {
	background-color: #fff;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 0.15rem;
	box-shadow: 0 0 1.4rem rgba(0, 0, 0, 0.16);

	position: relative;
	z-index: 320;

	width: 100%;
	max-width: 30rem;
}
