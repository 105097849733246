.direct-donation-simple {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;
}

.direct-donation-simple__number {
	white-space: nowrap;
}

@media (max-width: 575px) {
	.direct-donation-simple__number {
		font-size: 0.8rem;
	}
}

.direct-donation-simple__logo {
	max-width: 50%;
	height: auto;
}

.direct-donation-simple__logo > img {
	max-width: 100%;
	height: auto;
	display: block;
}

@supports (object-fit: contain) {
	.direct-donation-simple__logo > img {
		max-height: 1.5rem;
		object-fit: contain;
		object-position: left center;
	}
}
