.attachment {
}

a.attachment {
	cursor: pointer;
	text-decoration: none;
}

a.attachment:hover {
	text-decoration: underline;
}

.attachment__image {
	background-color: var(--color-pale-green);
	overflow: hidden;
	position: relative;
	display: block;
}

.attachment__image::before {
	content: '';
	display: block;
	height: 0;
	padding-top: 66.6%;
}

.attachment__image > img,
.attachment__image > noscript > img {
	width: 100%;
	height: auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	display: block;
}

@supports (object-fit: contain) {
	.attachment__image > img,
	.attachment__image > noscript > img {
		height: 100%;
		bottom: 0;
		object-fit: contain;
	}
}

.attachment__name {
	display: block;
	margin-top: 0.5rem;
	font-size: 0.85rem;
	line-height: 1.2;
}
