/**
 * This block is very similar to the individual entries in people-list.
 * Consider using this block for the people-list entries instead. This would
 * require changing the HTML markup.
 */

.contact-card {
	max-width: 20rem;
}

.contact-card__image {
	display: block;
	width: 100%;
	height: auto;
}

.contact-card__name {
	font-size: 1.3rem;
	margin-bottom: 0.25rem;
}

.contact-card a {
	text-decoration: none;
}

.contact-card a:hover {
	text-decoration: underline;
}

.contact-card a[href^='mailto:'] {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	vertical-align: top;
}

.contact-card__info {
	padding-top: 1rem;
	line-height: 1.6;
}

.contact-card--highlight .contact-card__info {
	background-color: var(--color-pale-blue);
	padding: 1rem;
}
