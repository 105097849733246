/* card-preview-thumb assumes that both width and height are set */

.card-preview-thumb {
	display: block;
	position: relative;
	overflow: hidden;
	background-color: #fafafa;
}

.card-preview-thumb--with-card::before,
.card-preview-thumb--with-christmas-card::before {
	content: '';
	display: block;
	position: absolute;
	top: calc(16 / 140 * 100%);
	left: calc(32 / 140 * 100%);
	width: calc(76 / 140 * 100%);
	height: calc(107 / 140 * 100%);
	background-size: cover;
	box-shadow: rgba(0, 0, 0, 0.12) 0 0.2rem 1rem;
}

.card-preview-thumb--with-card::before {
	background-image: url('../../assets/card-preview-thumb.png');
}

.card-preview-thumb--with-christmas-card::before {
	background-image: url('/src/assets/christmas-2023/christmas-card-preview-thumb.jpg');
}

.card-preview-thumb__image {
	position: absolute;
	top: calc(18 / 140 * 100%);
	left: calc(34 / 140 * 100%);
	width: calc(72 / 140 * 100%);
	height: calc(48 / 140 * 100%);
	object-fit: cover;
}

.card-preview-thumb__christmas-card-image {
	position: absolute;
	top: calc(61 / 140 * 100%);
	left: calc(55 / 140 * 100%);
	width: calc(32 / 140 * 100%);
	object-fit: cover;
}

/* for placing card-icon inside card-preview-thumb */
.card-preview-thumb__card-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0.9);
}

/* needs to be adjusted with media query here, because transform can’t handle percentage values */
@media (max-width: 767px) {
	.card-preview-thumb__card-icon {
		transform: translate(-50%, -50%) scale(0.45);
	}
}
