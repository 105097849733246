.frontpage-intro__insamlingskontroll {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

@media (width < 992px) {
	.frontpage-intro {
		text-align: center;
	}

	.frontpage-intro__button-p {
		width: 100%;
	}

	.frontpage-intro__text {
		padding-top: 1.8rem;
		/* Adjusts padding applied by .short-text */
		padding-bottom: 0.8rem;

		max-width: 24rem;
		margin-left: auto;
		margin-right: auto;
	}

	.frontpage-intro__insamlingskontroll {
		margin-top: 0.6rem;
		margin-bottom: 1.5rem;
	}
}

@media (width >= 576px) and (width < 992px) {
	.frontpage-intro__heading {
		font-size: 2.3rem;
		line-height: 0.9;
	}
}

@media (width >= 576px) {
	.frontpage-intro__text-mobile {
		display: none;
	}
}

@media (width < 576px) {
	.frontpage-intro {
		margin-top: 1.2rem;
	}

	.frontpage-intro__text {
		padding-top: 1.2rem;
		font-size: 0.9rem;
		max-width: 20rem;
	}

	.frontpage-intro__text-desktop {
		display: none;
	}

	.frontpage-intro__heading {
		font-size: 1.6rem;
		line-height: 0.9;
		max-width: 16rem;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0.6rem;
	}

	.frontpage-intro__text-mobile:not(:last-child) {
		margin-bottom: 0.8rem;
	}

	.short-text__button-p {
		margin-top: 0;
	}

	.short-text__button-p > .button {
		font-weight: inherit;
		font-size: inherit;
		padding: 0.6rem 1.25rem;
		box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.16);
	}

	.frontpage-intro__insamlingskontroll {
		width: 71px;
		height: 17px;
	}
}

@media (width < 420px) {
	.frontpage-intro__heading {
		font-size: 1.6rem;
	}
}

@media (width >= 992px) {
	.frontpage-intro__heading {
		font-size: 2.2rem;
		line-height: 0.9;
	}

	.page-section.frontpage-intro {
		margin-top: 2.5rem;
	}

	.frontpage-intro__insamlingskontroll {
		/* Line up with .overlapped-images */
		margin-right: calc((50% - var(--grid-gutter) * 0.5) * 0.025);
	}
}

/* 1025px due to the breakpoint in .short-text */
@media (width >= 1025px) and (width < 1320px) {
	.frontpage-intro__heading {
		font-size: 4vw;
	}
}

@media (width >= 1320px) {
	.frontpage-intro__heading {
		font-size: 3rem;
	}
}
