.news-card {
}

.news-card__meta {
	margin-top: 1.2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	align-self: stretch;
}

.news-card__meta > *:not(:last-child) {
	margin-right: 1rem;
}

.news-card__meta > * {
	margin-bottom: 0.5rem;
}

.news-card__symbols {
	display: flex;
	font-size: 0.85rem;
}

.news-card__symbols > *:not(:last-child) {
	margin-right: 1rem;
}

.news-card__content {
	padding-top: calc(var(--page-margin) * 1.5);
	padding-bottom: calc(var(--page-margin) * 2);
}

.news-card__content > * {
	max-width: var(--page-content-width);
	margin-left: auto;
	margin-right: auto;
	padding-left: calc(var(--page-margin) * 1.5);
	padding-right: calc(var(--page-margin) * 1.5);
	box-sizing: content-box;
}

@media (width < 768px) {
	.news-card__content {
		padding-top: calc(var(--page-margin) * 0.5);
	}
}
