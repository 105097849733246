/**
 * A button element that looks like text with a symbol before it.
 * Can contain an svg element.
 */

.symbol-button {
	display: inline-flex;
	align-items: center;
	vertical-align: top;
	appearance: none;
	border: 0;
	padding: 0;

	background: transparent;
	font-size: 1em;
	line-height: 1.2;
	white-space: nowrap;
}

a.symbol-button,
button.symbol-button {
	cursor: pointer;
	color: var(--color-link);
}

a.symbol-button:hover,
button.symbol-button:hover {
	background: transparent;
	box-shadow: none;
	color: var(--color-link);
}

a.symbol-button:hover span,
button.symbol-button:hover span {
	text-decoration: underline;
}

.symbol-button:focus {
	box-shadow: none;
}

a.symbol-button:active,
button.symbol-button:active {
	color: var(--color-link--active);
}

.symbol-button svg,
.symbol-button img {
	vertical-align: middle;

	width: 1.2rem;
	height: 1.2rem;
	margin-right: 0.2rem;
}

.symbol-button span {
	/* Position adjustment is needed due to the position of the glyphs
	 * in the Franklin Gothic webfont */
	position: relative;
	top: 0.05rem;
}

.symbol-button--large {
	line-height: 1.6rem;
}

.symbol-button--large svg,
.symbol-button--large img {
	width: 1.6rem;
	height: 1.6rem;
	margin-right: 0.5rem;
}

.symbol-button--reverse {
	text-align: right;
	flex-direction: row-reverse;
}

.symbol-button--reverse svg,
.symbol-button--reverse img {
	order: -1;
	margin-left: 0.2rem;
	margin-right: 0;
}

.symbol-button--reverse.symbol-button--large svg,
.symbol-button--reverse.symbol-button--large img {
	margin-left: 0.5rem;
}
