.scrollable-list {
	position: relative;

	--button-size: 3rem;
	--button-margin: 0.55rem;
	--arrow-width: 0.5rem;
	--arrow-height: 1.1rem;
}

.scrollable-list__action {
	position: absolute;
	top: 50%;
	z-index: 4;
	transform: translateY(-50%);

	appearance: none;
	cursor: pointer;

	width: var(--button-size);
	height: var(--button-size);
	white-space: nowrap;
	text-indent: 10rem;
	background: none;
	border: 0;
	margin: 0;
	padding: 0;
	overflow: hidden;

	background-color: var(--color-blue);
	border-radius: 50%;
	box-shadow: var(--shadow-medium);

	transition-property: color, box-shadow, border-color, background-color,
		opacity;
	transition-duration: 100ms, 100ms, 100ms, 150ms, 120ms;
	transition-timing-function: ease-out;
}

@media (max-width: 575px) {
	.scrollable-list__action {
		--button-size: 2.5rem;
		--arrow-width: 0.4rem;
		--arrow-height: 0.9rem;
	}
}

.scrollable-list__action:not(:disabled):hover {
	border-color: #164dad;
	background-color: #164dad;
}

.scrollable-list__action:disabled {
	opacity: 0;
}

.scrollable-list__action::before {
	content: '';
	width: var(--arrow-width);
	height: var(--arrow-height);
	background: url('../../assets/arrow-white.svg') center no-repeat;
	background-size: var(--arrow-width) var(--arrow-height);
	position: absolute;
	top: calc(50% - var(--arrow-height) / 2);
	right: calc(50% - var(--arrow-width) / 2);
}

.scrollable-list__action--prev {
	left: -0.25rem;
}

.scrollable-list__action--next {
	right: -0.25rem;
}

.scrollable-list__action--prev::before {
	transform: rotate(180deg);
}

/* .scrollable-list__action--next::before {
} */
