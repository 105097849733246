.validation {
	font-size: 0.75rem;
	color: var(--color-gray);
	display: block;
	text-align: left;
}

.validation--error {
	color: var(--color-red);
	margin-top: 0.25rem;
}

.js .validation:not(.validation--error):empty {
	display: none;
}

/* Small adjustment to move validation closer to confirm checkboxes */
.confirm + .validation {
	margin-top: -0.6rem;
}
