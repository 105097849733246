.js .file-input {
	border: 1px solid var(--color-thin-line);
	position: relative;
	display: inline-block;
	border-radius: var(--input-border-radius);
	padding: 0.3rem;
	overflow: hidden;
	max-width: 100%;
}

.js .file-input:hover {
	background-color: var(--input-background-color--hover);
	border-color: var(--input-border-color--hover);
}

.js .file-input--is-focused {
	border-color: var(--input-border-color--focus);
}

.js .file-input__field {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.js .file-input__label,
.js .file-input__label::before {
	/*padding: 0.3rem;*/
}

.js .file-input__label {
	color: var(--color-gray);
	padding-right: 0.5rem;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 18rem;
}

.js .file-input--has-file .file-input__label {
	color: #000;
}

.no-js .file-input__label {
	display: none;
}

.js .file-input__label::before {
	content: '+';
	display: inline-block;
	color: #fff;

	/* Same styling as buttons */
	border-color: var(--color-blue);
	background-color: var(--color-blue);
	border-radius: calc(var(--input-border-radius) - 0.1rem);
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0);
	transition-property: color, box-shadow, border-color, background-color;
	transition-duration: 100ms, 100ms, 100ms, 150ms;
	transition-timing-function: ease-out;

	width: 1.35rem;
	text-align: center;
	margin-right: 0.4rem;
	padding: 0.1rem 0;
}

.js .file-input__field:hover + .file-input__label::before {
	border-color: #164dad;
	background-color: #164dad;
}

.js .file-input__field:focus + .file-input__label::before {
	box-shadow: 0 0.15rem 0.8rem rgba(0, 0, 0, 0.16);

	outline: 0;
	box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.04);
	border-color: var(--input-border-color--focus);
}

.js .file-input.file-input--is-valid {
	border-color: var(--input-border-color--valid);
	background-color: var(--input-background-color--valid);
}

.js .file-input.file-input--is-invalid {
	border-color: var(--input-border-color--invalid);
	background-color: var(--input-background-color--invalid);
}
