.mini-cart {
	position: absolute;
	top: 100%;
	left: var(--page-margin);
	right: var(--page-margin);
	text-align: right;
	pointer-events: none;
}

.mini-cart a {
	text-decoration: none;
}

.mini-cart__block {
	display: inline-block;
	color: var(--color-red);
	background-color: var(--color-pale-red);

	font-size: 0.8rem;
	padding: 0.6rem 0.8rem 0.5rem;
	border: 1px solid var(--color-thin-line);
	border-radius: 0 0 0.15rem 0.15rem;

	pointer-events: auto;
}

.js.tabbed a.mini-cart__block:focus {
	outline: 0;
	box-shadow: var(--input-prominent-focus-inner) 0 0 0 0.05rem, var(--input-prominent-focus) 0 0 0 0.2rem;
}

.mini-cart__heading::before {
	display: inline-block;
	content: '';
	background: url('../../assets/heart.svg');
	background-repeat: no-repeat;
	background-position: center center;
	width: 0.75rem;
	height: 0.65rem;
	margin-right: 0.5rem;
	margin-left: 0.05rem;
	vertical-align: -0.05rem;
}

.mini-cart__contains {
	display: inline-block;
	background: var(--color-red);
	color: #fff;
	border-radius: 2rem;
	width: 1.2rem;
	height: 1.2rem;
	text-align: center;
	font-size: 0.65rem;
	padding-top: 0.2rem;
	padding-bottom: 0.2rem;
	margin-left: 1.5rem;
}

@media (width < 768px) {
	.mini-cart__block {
		padding: 0.25rem 0.5rem 0.3rem;
	}
	.mini-cart__contains {
		width: 1rem;
		height: 1rem;
		padding-top: 0.1rem;
		padding-bottom: 0.1rem;
		margin-left: 0.25rem;
	}
}
