.steps {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.steps {
	max-width: 100%;
	width: 100%;
}

.steps > .steps__step {
	max-width: 100%;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.steps > .steps__step--animated {
	transition: width 250ms ease-out;
}

.steps > .steps__step:not(.steps__step--wide) {
	width: var(--page-content-max-width);
}

.steps > .steps__step.steps__step--wide[aria-expanded='false'] {
	width: var(--page-content-max-width);
}
