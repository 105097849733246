@media (width < 992px) {
	.card-product {
		flex-direction: column;
		--page-content-width: 30rem;
	}

	.card-product__image {
	}

	.card-product__image {
		order: 2;
	}
}

@media (width >= 992px) {
	.card-product {
		display: grid;
		grid-template-columns: auto 45%;
	}
}

@media (width >= 1200px) {
	.card-product {
		grid-template-columns: auto 35%;
	}
}
