.payment-option {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 2.3rem;
}

.payment-option__text {
}

.payment-option__logo > img {
	display: block;
}

@media (width < 576px) {
	.payment-option__logo {
		max-width: 33%;
	}

	.payment-option__logo > img {
		max-width: 100%;
		height: auto;
	}
}
