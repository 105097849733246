body {
	font-family: 'Franklin Gothic URW', sans-serif;
	line-height: 1.4;
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
strong,
b,
th {
	font-weight: 600;
}

/**
 * Vertical flow of typography
 */

p,
ul,
ol,
table,
figure,
blockquote,
dl,
h1,
h2,
h3,
h4 {
	margin-top: 0;
	margin-bottom: 0;
}

p:not(:last-child),
ul:not(:last-child),
ol:not(:last-child),
table:not(:last-child),
figure:not(:last-child),
blockquote:not(:last-child),
dl:not(:last-child),
.u-vertical-flow:not(:last-child) {
	margin-bottom: var(--paragraph-margin);
}

.u-vertical-flow--top:not(:first-child) {
	margin-top: var(--paragraph-margin);
}

/* Vertical flow of elements that can appear in content */
.content .radio-set:not(:last-child) {
	margin-bottom: var(--paragraph-margin);
}
.content .page-section:not(:last-child) {
	margin-bottom: 2rem;
}

/**
 * Headings
 */

h1,
.h1 {
	font-size: 3.2rem;
	line-height: 1.05;
	letter-spacing: -0.02em;
	text-transform: none;
}

h1:not(:last-child),
.h1:not(:last-child) {
	margin-bottom: 0.75rem;
}

h2,
.h2 {
	font-size: 2.5rem;
	line-height: 1.1;
	letter-spacing: 0;
	text-transform: none;
}

h2:not(:first-child),
.h2:not(:first-child) {
	margin-top: 2rem;
}

h2:not(:last-child),
.h2:not(:last-child) {
	margin-bottom: 0.75rem;
}

h3,
.h3 {
	font-size: 2rem;
	font-weight: 400;
	line-height: 1.1;
	letter-spacing: 0;
	text-transform: none;
}

h3:not(:first-child),
.h3:not(:first-child) {
	margin-top: 2rem;
}

h3:not(:last-child),
.h3:not(:last-child) {
	margin-bottom: 0.75rem;
}

h4,
.h4 {
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.03rem;
	text-transform: uppercase;
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
}

h3 + h4,
.h3 + .h4,
h3 + .h4,
.h3 + h4,
.h3 + h3 {
	margin-top: 0;
}

.heading + h3,
.heading + .h3 {
	margin-top: 0.75rem;
}

h1 a,
h2 a,
h3 a,
.h1 a,
.h2 a,
.h3 a {
	display: inline-block;
	color: inherit;
	text-decoration: none;
}

:is(h1, .h1, h2, .h2 h3, .h3, h4, .h4, h5, .h5, h6, .h6, .heading)
	+ :is(h1, .h1, h2, .h2 h3, .h3, h4, .h4, h5, .h5, h6, .h6) {
	margin-top: 0;
}

@media (width < 576px) {
	h1,
	.h1 {
		font-size: 2rem;
	}

	h2,
	.h2 {
		font-size: 1.65rem;
	}

	h3,
	.h3 {
		font-size: 1.35rem;
	}
}

@media (width < 400px) {
	h1,
	.h1 {
		font-size: 1.6rem;
	}

	h2,
	.h2 {
		font-size: 1.35rem;
	}

	h3,
	.h3 {
		font-size: 1.2rem;
	}

	body {
		font-size: 0.9rem;
	}
}

/**
 * Divider
 */

hr {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border: 0;
	border-top: 1px solid var(--color-thin-line);
}

/**
 * Links in content
 */

.content a:not(:hover) {
	text-decoration: none;
}

/**
 * Lists
 */

.content ul:not(:first-child),
.content ol:not(:first-child) {
	margin-top: var(--paragraph-margin);
}

.content li {
	margin-left: 1.5rem;
}

.content li + li {
	margin-top: var(--paragraph-margin);
}

.content ul ul {
	padding-left: 0;
}

/**
 * Blockquote
 */

blockquote {
	margin-left: 0;
	margin-right: 0;
	background-color: var(--color-pale-blue);
	padding: 1rem 1.5rem;
}

blockquote::before {
	content: '”';
	display: block;
	font-size: 4rem;
	font-weight: 600;
	line-height: 1;
	margin-left: -0.15rem;
	margin-bottom: -1.6rem;
}

blockquote.blockquote--mark-left {
	padding-left: 3.5rem;
	position: relative;
}

blockquote.blockquote--mark-left::before {
	position: absolute;
	left: 1.4rem;
}

blockquote footer {
	font-size: 0.8rem;
	text-align: right;
}

blockquote p + footer {
	margin-top: -0.5rem;
}

blockquote.blockquote--large {
	font-size: 1.3rem;
}

/**
 * Figures
 */

figure {
	margin-left: 0;
	margin-right: 0;
}

figure > img,
figure > noscript > img {
	max-width: 100%;
	height: auto;
	display: block;
}

figure > blockquote {
	margin-bottom: 0; /* margin is applied on the figure element */
}

figcaption {
	color: #7e7e7e;
	font-size: 0.8rem;
}

figure figcaption {
	padding-top: 0.8rem;
}

@media (width < 576px) {
	figure figcaption {
		padding-top: 0.4rem;
	}
}

/* Prevent floats from escaping .content */
.content::after {
	content: '';
	display: table;
	clear: both;
}

/* Align right */
.alignright {
	float: right;
}

.content .alignright {
	margin-right: var(--page-overlap-x);
	margin-left: 1rem;
	margin-top: 0;
	margin-bottom: 1rem;
	padding-left: 0;
	padding-right: 0;
}

figure.alignright img {
	width: 100%;
}

figure.alignright {
	max-width: 18rem;
}

.content figure.alignright figcaption {
	padding-right: calc(var(--page-overlap-x) * -2);
}

@media (width < 1200px) {
	figure.alignright {
		max-width: 11rem;
	}
}

@media (width < 576px) {
	figure.alignright {
		max-width: 40vw;
	}

	.content figure.alignright {
		margin-right: 0;
	}
}

.content > figure:not(:first-child),
.content > .wp-block-image:not(:first-child),
.content > .wp-block-embed:not(:first-child) {
	margin-top: 2rem;
}

.content > figure:not(:last-child),
.content > .wp-block-image:not(:last-child),
.content > .wp-block-embed:not(:last-child) {
	margin-bottom: 2rem;
}

/* Handle images wrapped in wp-block-image */
@supports (max-width: unset) {
	.content > .wp-block-image {
		max-width: unset;
		padding-left: unset;
		padding-right: unset;
	}

	.content > .wp-block-image figure:not(.alignleft):not(.alignright) {
		margin-left: auto;
		margin-right: auto;
	}

	.content
		> .wp-block-image
		figure:not(.alignleft):not(.alignright):not(.aligncenter) {
		max-width: var(--page-content-width);
	}
}

/* Align center and full width */
.content figure.aligncenter,
.content .fullwidth {
	max-width: 100%;
	background-color: #fff;
	padding-left: 0;
	padding-right: 0;
	position: relative;
	z-index: 30; /* to position over the scroll context menu */
}

.content figure.aligncenter::before,
.content figure.aligncenter::after,
.content .fullwidth::before,
.content .fullwidth::after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	height: 1.8rem;
	pointer-events: none;
}

.content figure.aligncenter::before,
.content .fullwidth::before {
	top: -1.8rem;
	background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.content figure.aligncenter::after,
.content .fullwidth::after {
	bottom: -1.8rem;
	background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.content figure.aligncenter figcaption {
	padding-bottom: 0.2rem;
}

/* Align center */
.content figure.aligncenter img {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

@media (width >= 576px) {
	.content figure.aligncenter {
		max-width: calc(
			var(--page-content-width) + var(--image-align-center-extra-width)
		);
	}
}

@media (width < 576px) {
	.content figure.aligncenter figcaption {
		padding-left: var(--page-margin);
	}
}

/* Full width */
.content .fullwidth {
	max-width: 100%;
}

.content .fullwidth figcaption {
	padding-left: var(--page-margin);
}

@media (width >= 768px) {
	.page-card--offset .content .fullwidth {
		/* cancel out page-card--offset's padding-left */
		margin-left: -8rem;
		max-width: none;
		width: calc(100% + 8rem);
	}

	.page-card--offset .content .fullwidth img {
		width: 100%;
	}
}

/* Figure with overlapping image and quote */
.content figure.figure--overlap {
}

.content figure.figure--overlap > blockquote {
	width: 95%;
	margin-left: auto;
	margin-top: -5%;
}

@media (width >= 576px) {
	.content figure.figure--overlap > img,
	.content figure.figure--overlap > noscript > img {
		width: 95%;
		margin-left: 0;
	}

	.content figure.figure--overlap > blockquote {
		width: 80%;
	}
}

@media (width < 576px) {
	.content figure.figure--overlap > blockquote {
		margin-right: calc(var(--page-margin) * -1);
	}
}

/* Embeds with fixed aspect ratio */

.wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper,
.wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper,
.wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper,
.wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper,
.wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper,
.wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper,
.wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper,
.wp-block-embed.wp-embed-aspect-4-5 .wp-block-embed__wrapper,
.wp-block-embed.is-type-video:not(.wp-has-aspect-ratio)
	.wp-block-embed__wrapper {
	position: relative;
}

.wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before,
.wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before,
.wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before,
.wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before,
.wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before,
.wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper::before,
.wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before,
.wp-block-embed.wp-embed-aspect-4-5 .wp-block-embed__wrapper::before,
.wp-block-embed.is-type-video:not(.wp-has-aspect-ratio)
	.wp-block-embed__wrapper::before {
	content: '';
	display: block;
	padding-top: 50%;
}

.wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper iframe,
.wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper iframe,
.wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper iframe,
.wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper iframe,
.wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper iframe,
.wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper iframe,
.wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper iframe,
.wp-block-embed.wp-embed-aspect-4-5 .wp-block-embed__wrapper iframe,
.wp-block-embed.is-type-video:not(.wp-has-aspect-ratio)
	.wp-block-embed__wrapper
	iframe {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
	padding-top: calc(9 / 21 * 100%);
}

.wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
	padding-top: calc(9 / 18 * 100%);
}

.wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before,
.wp-block-embed.is-type-video:not(.wp-has-aspect-ratio)
	.wp-block-embed__wrapper::before {
	padding-top: calc(9 / 16 * 100%);
}

.wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
	padding-top: calc(3 / 4 * 100%);
}

.wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
	padding-top: calc(1 / 1 * 100%);
}

.wp-block-embed.wp-embed-aspect-9-6 .wp-block-embed__wrapper::before {
	padding-top: calc(6 / 9 * 100%);
}

.wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
	padding-top: calc(2 / 1 * 100%);
}

.wp-block-embed.wp-embed-aspect-4-5 .wp-block-embed__wrapper::before {
	padding-top: calc(5 / 4 * 100%);
}

/** Gallery block */

.wp-block-gallery {
	--columns-count: 1;
}

@media (width >= 575px) {
	.wp-block-gallery {
		--columns-count: 3;
	}

	.wp-block-gallery.columns-2 {
		--columns-count: 2;
	}

	.wp-block-gallery.columns-3 {
		--columns-count: 3;
	}
}

.wp-block-gallery .blocks-gallery-grid {
	padding-left: 0;
	display: grid;
	grid-template-columns: repeat(var(--columns-count), minmax(0, 1fr));
	column-gap: 1rem;
}

.wp-block-gallery .blocks-gallery-grid li {
	display: block;
	margin-left: 0;
}

.wp-block-gallery .blocks-gallery-grid li + li {
	margin-top: 0;
}

.wp-block-gallery .blocks-gallery-item img {
	display: block;
}

@supports (object-fit: cover) {
	.wp-block-gallery .blocks-gallery-item img {
		object-fit: cover;
	}
}

@supports (aspect-ratio: 4 / 3) {
	.wp-block-gallery .blocks-gallery-item img {
		aspect-ratio: 4 / 3;
	}
}

@supports not (aspect-ratio: 4 / 3) {
	.wp-block-gallery .blocks-gallery-item img {
		height: 75%;
	}
}
