.recommend {
	color: var(--color-text);
	border: 1px solid var(--color-thin-line);
	background-color: var(--color-pale-blue);
	border-radius: var(--input-border-radius);
	display: flex;
	align-items: center;
	font-size: 0.8rem;
}

.recommend:not(:first-child) {
	margin-top: var(--form-spacing-y);
}

.recommend:not(:last-child) {
	margin-bottom: var(--form-spacing-y);
}

.radio-set + .recommend,
.radio-set + .recommend ~ .recommend {
	margin-top: 1rem;
}

.recommend--block-flow {
	margin-top: 0.75rem;
	max-width: 22rem;
}

.recommend__heading {
	border-bottom: 0;
	margin-bottom: 0.65rem;
}

.recommend__content {
	flex: 1;
	padding: 0.6rem 1rem;
}

.recommend__amount {
	margin: 0.5rem;
	width: 11rem;
}

.recommend__content > p:not(:last-child) {
	margin-bottom: 0;
}

.recommend em {
	font-style: normal;
	color: var(--color-blue);
}

@media (width < 576px) {
	.recommend {
		flex-direction: column;
		align-items: flex-start;
	}

	.recommend__amount {
		margin-top: 0;
		margin-left: 2.25rem;
	}
}
