.support-main {
}

.support-main__box {
	box-shadow: var(--shadow-medium);
}

.support-main__title {
	margin: 0 var(--page-margin);
}

.support-main__title:not(:first-child) {
	margin-top: calc(var(--page-margin) * 0.75);
}

.support-main__content {
	padding: 0.5rem var(--page-margin) var(--page-margin);
	display: flex;
	align-items: flex-end;
}

.support-main__text {
	flex: 1;
	margin-right: 1rem;
}

@media (width < 576px) {
	.support-main__title,
	.support-main__content {
		--page-margin: 1rem;
		text-align: center;
	}

	.support-main__content {
		flex-direction: column;
		align-items: center;
	}

	.support-main__text {
		margin-right: 0;
	}

	.support-main__action {
		margin-top: 0.75rem;
	}
}
