.share {
	border-top: 1px solid var(--color-thin-line);
	margin-top: 2.25rem;
	padding-top: 0.75rem;
}

.share__social-links a > img {
	margin-right: 0.35rem;
	position: relative;
	top: -0.05rem;
	width: 0.85rem;
	height: 0.85rem;
}

/* Very specific to overwrite .symbol-button img:only-child */
.share__social-links a.symbol-button > img:only-child {
	margin-right: 0.35rem;
}

.share ul {
	padding-left: 0;
}

.share li {
	display: block;
	margin-left: 0;
}

.share li + li {
	margin-top: 0;
}

.share__heading {
	margin-bottom: 0;
}

.share__social-links,
ul.share__social-links {
	display: flex;
	flex-wrap: wrap;
	font-size: 0.8rem;
	margin-top: 0.5rem;
}

.share__social-links li:not(:last-child) {
	margin-right: 1.25rem;
}
