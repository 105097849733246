.category-selector {
	color: #fff;
	background-color: var(--color-blue);
	border-radius: 0.15rem;
	box-shadow: 0 0.15rem 0.3rem rgba(0, 0, 0, 0.16);
	height: 2.6rem;
	display: flex;
	align-items: center;
	padding-left: 1rem;
	padding-right: 1rem;
	position: relative;
}

input[type='checkbox'].category-selector__checkbox:checked {
	border-color: #fff;
}

.category-selector__categories > *:not(:last-child) {
	margin-right: 0.5rem;
}

.category-selector__category {
	display: inline-block;
	color: #fff;
	text-decoration: none;
}

.category-selector__category:active {
	color: #fff;
}

/* Reflects the style of input[type="checkbox"] defined in globals/forms.css */
.category-selector__category::before {
	content: '';
	display: inline-block;

	width: 0.85rem;
	height: 0.85rem;
	margin-right: 0.25rem;
	vertical-align: -0.15rem;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 0.15rem;
	border-color: var(--color-blue);
	background-color: var(--input-background-color);
}
.category-selector__category--active::before {
	background-image: url('../../assets/checkmark.svg');
}
.category-selector__category--active:focus::before {
	box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.04);
	border-color: var(--input-border-color--focus);
}

@media (width >= 576px) {
	.category-selector__categories > *:not(:last-child) {
		margin-right: 1.5rem;
	}

	.category-selector__category::before {
		width: 1.1rem;
		height: 1.1rem;
		vertical-align: -0.25rem;
		margin-right: 0.4rem;
		background-size: 1.1rem 1.1rem;
	}
}

@media (width >= 768px) {
	.category-selector {
		justify-content: center;
	}

	.category-selector__heading {
		/*text-transform: uppercase;
		font-size: 0.8rem:*/
		position: absolute;
		left: 1rem;
	}
}

@media (width >= 576px) and (width < 768px) {
	.category-selector {
		justify-content: space-between;
	}
}

@media (width < 576px) {
	.category-selector {
		flex-direction: column;
		font-size: 0.8rem;
		height: auto;
		padding-top: 0.6rem;
		padding-bottom: 0.65rem;
	}

	.category-selector__heading {
		margin-bottom: 0.3rem;
	}
}
