.people-list {
}

.people-list__person {
	display: block;
}

li.people-list__person {
	margin-left: 0;
}

li + li.people-list__person {
	margin-left: 0;
	margin-top: 0;
}

.people-list__image {
	display: block;
	max-width: 100%;
	height: auto;
}

.people-list__name,
.people-list__name:not(:first-child) {
	font-size: 1.3rem;
	margin-top: 1.2rem;
	margin-bottom: 0.5rem;
}

.people-list a[href^='mailto:'] {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	vertical-align: top;
}

@media (width < 576px) {
	.people-list__person {
		margin-bottom: 1.5rem;
	}
}

@media (width >= 576px) {
	.people-list__person {
		margin-bottom: 2rem;
	}
}
