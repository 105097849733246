.social-links {
	font-size: 0.85rem;
	display: flex;
	flex-wrap: wrap;
}

.social-links__link {
	text-decoration: none;
	white-space: nowrap;
}

.social-links__link:not(:last-child) {
	margin-right: 0.75rem;
}

.social-links__link > img {
	margin-right: 0.25rem;
	vertical-align: -0.2rem;
	position: relative;
	top: -0.05rem;
	width: 0.85rem;
	height: 0.85rem;
}
