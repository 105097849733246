.waiting-for-signature {
	padding-top: 3.5rem;
	padding-bottom: 3.5rem;
	font-size: 1.1rem;
	text-align: center;
}

.waiting-for-signature__symbol {
	margin-bottom: 1rem;
}

.waiting-for-signature__message {
	max-width: 19rem;
	margin-left: auto;
	margin-right: auto;
}

.waiting-for-signature__message--qr {
	max-width: 24rem;
}

.waiting-for-signature__qr {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
	width: 8rem;
	height: auto;
}

.waiting-for-signature__button {
	margin-bottom: 1rem;
}

.waiting-for-signature__qr:not(:last-child) {
	margin-bottom: 1rem;
}

.waiting-for-signature__loading {
	display: block;
	margin-top: 0.25rem;
	font-size: 0.85rem;
}
