.preamble {
	text-align: center;
	margin-bottom: 2rem;
}

.preamble::after {
	content: '';
	display: block;
	border-bottom: 1px solid var(--color-thin-line);
	margin-top: 2rem;
}

@media (width >= 768px) {
	.preamble > p {
		width: 85%;
		max-width: var(--page-content-width);
		margin-left: auto;
		margin-right: auto;
	}
}

.preamble--no-line::after {
	border-color: transparent;
	margin-top: 1rem;
}

@media (width < 576px) {
	.preamble--no-line::after {
		display: none;
	}
}
