.navigation {
	background-color: rgba(0, 0, 0, 0.25);

	font-size: 1rem;
	height: 100vh;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 200;

	transition: opacity 200ms ease-out;
	opacity: 0;
}

.navigation__controls,
.navigation__block {
	width: 26rem;
	max-width: calc(100vw - var(--page-margin));
}

.navigation__controls {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;

	font-size: 0.85rem; /* Mirror header font-size */

	height: var(--header-height);
	box-shadow: 0 1px 0 var(--color-thin-line);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0 var(--page-margin);

	background-color: #fff0;
	transition: background-color 0 linear;
}

.navigation--open .navigation__controls {
	background-color: #fff;
	transition-delay: 500ms;
}

.navigation__block {
	background-color: #fff;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;

	padding-top: calc(var(--header-height) + 0.05rem);

	transition: transform 500ms cubic-bezier(0, 0.66, 0, 1);
	transform: translateX(100%);
}

.navigation--open .navigation__block {
	transform: translateX(0);
}

.navigation__wrap {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}

.navigation__menu-button {
	appearance: none;
	border: 0;
	padding: 0;
	margin: 0;
	background: transparent;
	margin-left: 1.5rem;
	cursor: pointer;
}

.navigation__menu-button svg {
	display: block;
}

.navigation:not(.navigation--open) {
	pointer-events: none;
}

@media (width >= 992px) {
	.navigation {
		--grid-gutter: 2rem;
	}
}

.navigation--open {
	opacity: 1;
}

@media (width < 576px) {
	.navigation__wrap {
		--page-margin: 1rem;
		/* Add extra spacing because 100vh is larger than the actual viewport height on iOS */
		padding-bottom: 100px;
	}

	.navigation__menu-button {
		margin-left: 1rem;
	}
}
