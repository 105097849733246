/**
 * These are utility classes, used to not have to have special text
 align adjustments in other classes. Not very BEM-like and might be
 restructured in the future.
 */

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

@media (min-width: 576px) {
	.text-left--sm {
		text-align: left;
	}

	.text-center--sm {
		text-align: center;
	}

	.text-right--sm {
		text-align: right;
	}
}

@media (min-width: 768px) {
	.text-left--md {
		text-align: left;
	}

	.text-center--md {
		text-align: center;
	}

	.text-right--md {
		text-align: right;
	}
}

@media (min-width: 992px) {
	.text-left--lg {
		text-align: left;
	}

	.text-center--lg {
		text-align: center;
	}

	.text-right--lg {
		text-align: right;
	}
}

@media (min-width: 1200px) {
	.text-left--xl {
		text-align: left;
	}

	.text-center--xl {
		text-align: center;
	}

	.text-right--xl {
		text-align: right;
	}
}
