.order-summary {
	border-top: 1px solid var(--color-thin-line);
	font-size: 0.85rem;
}

.order-summary__receipt {
	padding: var(--page-margin) calc(var(--page-margin) * 1.5);
}

.order-summary__info {
	padding: var(--page-margin) calc(var(--page-margin) * 1.5);
	background-color: var(--color-pale-green);
}

@media (width >= 768px) {
	.order-summary__info {
		padding-left: var(--grid-gutter);
	}
}

.order-summary__list dt {
	display: block;
	margin-bottom: 0.5rem;
}

.order-summary__list dd {
	margin-left: 0;
}

.order-summary__list dd:not(:last-child) {
	margin-bottom: 1.25rem;
}

@media (width >= 576px) {
	.order-summary__list {
		column-count: 2;
		column-gap: var(--grid-gutter);
	}

	.order-summary__list dt {
		break-after: avoid-column;
	}
}

.order-summary__amount {
	font-size: 1.3rem;
}
