.radio-set {
	display: flex;
	width: 100%;
}

.radio-set__option {
	padding-right: 1rem;
	border-radius: 0.2rem;
	color: var(--color-blue);
	display: flex;
	align-items: flex-start;
}

.radio-set__option > input {
	flex-shrink: 0;
	margin-top: 0.3rem;
}

.radio-set__option--vertical {
	flex-direction: column;
	justify-items: flex-start;
}

@supports selector(:has(a, b)) {
	.js.tabbed .radio-set__option:has(input:focus) {
		box-shadow: var(--input-prominent-focus-inner) 0 0 0 0.05rem,
			var(--input-prominent-focus) 0 0 0 0.2rem;
	}

	.js.tabbed .radio-set__option input:focus {
		box-shadow: none;
	}
}

.radio-set__content {
	color: var(--color-black);
	overflow: hidden; /* to allow animating height */

	margin-top: 0.2rem;
	margin-left: 1.1rem;
}

.radio-set__content p:last-of-type {
	margin-bottom: 0;
}

.radio-set__content .radio-set:not(:first-child) {
	margin-top: var(--paragraph-margin);
}

.radio-set__label {
	vertical-align: middle;
	font-size: 1.15rem;
	line-height: 1.65rem;
}

.radio-set__label--nowrap {
	white-space: nowrap;
}

@media (576px <= width < 768px) {
	.radio-set__label {
		font-size: 1em;
	}
}

.radio-set__option--small .radio-set__label {
	font-size: 0.9rem;
}

/**
 * Modifier: Outlined
 */

.radio-set--outlined .radio-set__option {
	transition-property: border-color, background-color;
	transition-duration: 100ms, 150ms;
	transition-timing-function: ease-out;
	border: 1px solid var(--input-border-color);
	background-color: var(--input-background-color);
	padding-top: 0.8rem;
	padding-left: 1rem;
	padding-bottom: 0.8rem;
}

.radio-set--outlined .radio-set__option:hover {
	border: 1px solid var(--input-border-color--hover);
	background-color: var(--input-background-color--hover);
}

@supports selector(:has(a, b)) {
	.radio-set--outlined .radio-set__option:has(.radio-set__content):hover {
		background-color: var(--color-background);
	}
}

/**
 * Highlighted option
 *
 * This class is applied in radio-set.js if the block has the radio-set--highlight-checked selector.
 */

.radio-set--highlight-checked.radio-set--outlined .radio-set__option {
	transition-property: color, background-color, border-color, box-shadow;
	transition-duration: 100ms, 150ms, 150ms, 150ms;
	transition-timing-function: ease-out;
	box-shadow: rgba(0, 0, 0, 0) 0 1px 6px;
}

.radio-set--highlight-checked.radio-set--outlined .radio-set__option--checked {
	color: #fff;
	background-color: var(--color-blue);
	border-color: var(--color-blue);
	box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
}

.radio-set--highlight-checked.radio-set--outlined
	.radio-set__option--checked
	input {
	border-color: #eee;
}

/**
 * Modifier: Horizontal
 *
 * Note that the radio-set is only horizontal in slightly wider viewports.
 */

@media (width >= 650px) {
	.radio-set--horizontal .radio-set__option {
		align-items: center;
	}

	.radio-set--horizontal .radio-set__option input {
		margin-top: 0;
		margin-bottom: 0.15rem;
	}

	.radio-set--horizontal .radio-set__option:not(:last-child) {
		margin-right: var(--form-spacing-x);
	}
}

@media (width < 650px) {
	.radio-set--horizontal {
		flex-direction: column;
	}

	.radio-set--horizontal .radio-set__option:not(:last-child) {
		margin-bottom: calc(var(--form-spacing-y) / 2);
	}
}

.radio-set--horizontal.radio-set--even .radio-set__option {
	flex-grow: 1;
	flex-shrink: 1;
}

/**
 * Modifier: Vertical
 */

.radio-set--vertical {
	flex-direction: column;
}

.radio-set--vertical .radio-set__option:not(:last-child) {
	margin-bottom: calc(var(--form-spacing-y) / 2);
}

/**
 * Modifier: Grid
 */

.radio-set--grid {
	display: grid;
	--radio-set-columns: 1;
	grid-template-columns: repeat(var(--radio-set-columns), minmax(0, 1fr));
	column-gap: var(--form-spacing-x);
	row-gap: 0.25rem;
}

@media (min-width: 440px) {
	.radio-set--grid {
		--radio-set-columns: 2;
		row-gap: var(--form-spacing-y);
	}
}

@media (min-width: 1200px) {
	.radio-set--grid {
		--radio-set-columns: 4;
	}
}

/**
 * Modifier: Images
 */

.radio-set--images {
	/* Prevent jitter when transitioning */
	transform: translate3d(0, 0, 0);
}

.radio-set--images .radio-set__option {
	flex: 1;
	overflow: hidden;
	border-radius: 0.2rem;
	position: relative;
	background-color: #000;
	/* Required margin instead of padding */
	padding-right: 0;
}
.radio-set--images .radio-set__option:not(:last-child) {
	margin-right: 1rem;
}

@media (width < 576px) {
	.radio-set--images {
		flex-wrap: wrap;
	}
	.radio-set--images .radio-set__option {
		flex: 0;
		flex-basis: calc(50% - 0.5rem / 2);
		margin-bottom: 0.5rem;
	}
	.radio-set--images .radio-set__option:not(:last-child) {
		margin-right: 0.5rem;
	}
	.radio-set--images .radio-set__option:nth-child(even) {
		margin-right: 0;
	}
}

.radio-set--images .radio-set__image {
	width: 100%;
	height: auto;
}

.radio-set--images .radio-set__option::before {
	content: '';
	background-color: rgba(0, 0, 0, 0);
	position: absolute;
	top: -1px;
	left: -1px;
	right: -1px;
	bottom: -1px;
	transition: background-color 150ms ease-out;
}

.radio-set--images .radio-set__option.radio-set__option--checked::before {
	background-color: rgba(0, 0, 0, 0.33);
}

.radio-set--images input {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	z-index: 2;
}

.radio-set--images .radio-set__label {
	position: absolute;
	top: 0;
	left: 150%;
}

/**
 * Modifier: Dense
 */

.radio-set--dense {
	--form-spacing-x: 0.5rem;
}

.radio-set--dense.radio-set--outlined .radio-set__option {
	padding-top: 0.5rem;
	padding-left: 0.55rem;
	padding-right: 0.55rem;
	padding-bottom: 0.5rem;
}

.radio-set--dense
	.radio-set__option:not(.radio-set__option--small)
	.radio-set__label {
	font-size: 1rem;
}

/**
 * Temporary fix for radio set layout. To be removed when feature/steps-form is merged.
 */
@media (max-width: 1196px) and (min-width: 650px) {
	.radio-set--campaign {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 0.5rem;
	}

	.radio-set--campaign.radio-set--horizontal
		.radio-set__option:not(:last-child) {
		margin-right: 0;
	}
}
