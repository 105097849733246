/**
 * This style is mirrored in typography.css for the h4 element
 */

.heading {
	display: inline-block;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.03rem;
	line-height: 1.5;
	text-transform: uppercase;
	border-bottom: 1px solid var(--color-thin-line);
}

.heading,
.heading a {
	color: inherit;
}

.heading--no-underline {
	border-bottom: 0;
}

/**
 * If the heading contains a span element, the border-bottom and display
 * is applied there instead. This is kind of hack-y but allows for usage
 * of .heading on h1, h2, h3 etc elements in .page-card__content, where
 * children needs to be block-level elements.
 */

.heading > span,
.heading > label {
	display: inline-block;
	border-bottom: 1px solid var(--color-thin-line);
}

.heading--no-underline > span,
.heading--no-underline > label {
	border-bottom: 0;
}

.page-card__content > .heading {
	display: block;
	border-bottom: 0;
}
