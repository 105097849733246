.contact-list {
	margin-bottom: 1rem;
}

.contact-list__item {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}

.contact-list__item--highlight {
	background-color: var(--color-pale-green);
}

.contact-list__item--highlight-alt {
	background-color: var(--color-light-blue);
}

.contact-list__item--highlight,
.contact-list__item--highlight-alt {
	margin-top: 0;
	padding: 1rem;
}

.contact-list__heading:first-child {
	margin-top: 0;
}

.contact-list__primary-info {
	font-size: 1.3rem;
	color: var(--color-blue);
	line-height: 1;
}

.contact-list a[href^='mailto:'] {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	vertical-align: top;
}

.contact-list__trim {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: -0.45rem;
}

.contact-list__divider {
	width: 100%;
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
}

@media (width >= 576px) {
	.contact-list {
		line-height: 1.8;
		margin-bottom: 1.5rem;
	}

	.contact-list__item {
		margin-top: 1.25rem;
		margin-bottom: 0.75rem;
	}

	.contact-list__item--highlight,
	.contact-list__item--highlight-alt {
		position: relative;
		left: -1rem;
	}

	.contact-list__trim {
		margin-bottom: -0.6rem;
	}
}

@media (width < 576px) {
	.contact-list {
		margin-bottom: 0.1rem;
	}
	.contact-list__divider {
		display: none;
	}
}
