.cart-product {
	display: grid;
	grid-template-columns: auto minmax(0, 1fr);
	gap: var(--form-spacing-x);

	font-size: 0.8rem;
}

.cart-product__heading {
	font-size: 1rem;
}

.cart-product__heading:not(:last-child) {
	margin-bottom: 0.35rem;
}

.cart-product__description,
p.cart-product__description {
	margin-bottom: 0.35rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.cart-product__description:not(:last-child) {
	margin-bottom: 0.5rem;
}

.cart-product__specification {
	margin-top: 0.35rem;
	width: 100%;
	border-collapse: collapse;
}

.cart-product__specification th,
.cart-product__specification td {
	padding: 0;
	vertical-align: top;
}

.cart-product__specification tr + tr th,
.cart-product__specification tr + tr td {
	padding-top: 0;
}

.cart-product__specification th {
	text-align: left;
	width: 1px;
	padding-right: 0.3rem;

	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.03rem;
	text-transform: uppercase;
}

.cart-product__specification:not(:last-child) {
	margin-bottom: 0.5rem;
}

.cart-product__actions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 0.25rem;
}

.cart-product__actions .button {
	text-align: center;
}

@media (width >= 768px) {
	.cart-product__details {
		flex: 1;
		min-width: 0;
	}

	.cart-product__image {
		width: 7rem;
		height: 7rem;
	}
}

@media (width < 768px) {
	.cart-product__image {
		width: 3.5rem;
		height: 3.5rem;
		border-radius: 0.25rem;
	}

	.cart-product__actions {
		font-size: 0.7rem;
	}

	.cart-product__actions .button {
		font-size: 0.7rem;
		padding: 0.2rem 0.5rem;
	}
}
