.arrow-link {
	text-decoration: none;
}

.arrow-link::after {
	content: '';
	display: inline-block;
	width: 0.4rem;
	height: 0.6rem;
	background: url('../../assets/arrow-small.svg') no-repeat center;
	vertical-align: 0em;
	margin-left: 0.25rem;
}
