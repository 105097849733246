.loading {
	color: #555;
	display: inline-block;
}

.loading::before {
	content: '';
	width: 0.85em;
	height: 0.85em;
	display: inline-block;
	background: url('../../assets/loading.svg') no-repeat;
	background-size: 0.85em 0.85em;
	vertical-align: -0.1em;
	animation: loading-animation 1000ms linear infinite;
}

.loading__label {
	margin-left: 0.5rem;
}

.loading--margin-left {
	margin-left: 0.65rem;
}

.loading--negative {
	color: #fff;
}

.loading--negative::before {
	background-image: url('../../assets/loading-negative.svg');
}

@keyframes loading-animation {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
