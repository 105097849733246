.visually-hidden {
	position: absolute !important;
	height: 1px;
	width: 1px;
	margin: 0;
	padding: 0;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
}
