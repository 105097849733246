.card-icon {
	display: block;
}

.card-icon * {
	-webkit-user-drag: none;
	user-select: none;
}

/**
 * PDF
 */

.card-icon--pdf {
	width: 4.25rem;
	height: 6rem;
	position: relative;
}

.card-icon--pdf .card-icon__card {
	width: 4.25rem;
	height: 6rem;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 10px rgba(0, 0, 0, 0.1);
}

.card-icon--pdf::after {
	content: 'PDF';
	position: absolute;
	top: 4.1rem;
	left: -0.6rem;
	background-color: var(--color-blue);
	color: #fff;
	font-size: 0.7rem;
	padding: 0.1rem 0.3rem;
	border-radius: 0.15rem;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.card-icon--pdf .card-icon__image {
	position: absolute;
	top: 0.25rem;
	left: 0.25rem;
	width: 3.75rem;
	height: 2.35rem;
}

.card-icon--pdf .card-icon__heading {
	position: absolute;
	top: 2.6rem;
	left: 0.5rem;
	width: 3.2rem;
	height: auto;
}

/**
 * Folded
 */

.card-icon--folded {
	width: 7.75rem;
	height: 7.75rem;
	position: relative;

	perspective: 15rem;
	perspective-origin: 50% 50%;
	transform-style: preserve-3d;
}

.card-icon--folded .card-icon__heading {
	position: absolute;
	top: 1.35rem;
	left: 0.15rem;
	width: 4rem;
}

.card-icon--folded .card-icon__page-1 img,
.card-icon--folded .card-icon__page-2 img,
.card-icon--folded .card-icon__page-3 img {
	max-width: 100%;
	height: auto;
}

.card-icon--folded .card-icon__page-1,
.card-icon--folded .card-icon__page-2,
.card-icon--folded .card-icon__page-3,
.card-icon--folded::after {
	display: none;
	position: absolute;
	top: 0.9rem;
	left: 50%;

	width: 4.25rem;
	height: 6rem;

	transform-origin: 0% 50%;
	transition-property: transform, filter, box-shadow, opacity;
	transition-duration: 1000ms;
	transition-timing-function: cubic-bezier(0.7, 0, 0.1, 1);

	backface-visibility: hidden;

	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0), 0 4px 10px rgba(0, 0, 0, 0);
}

/* Shadow */
.card-icon--folded::after {
	display: block;
	content: '';

	top: 5rem;
	left: 5.5rem;
	height: 3rem;
	width: 2rem;
	background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

	opacity: 0.05;
	transform: translateX(0.8rem) scaleX(1) scaleY(1) translateY(0.2rem)
		translateZ(-5rem) rotateX(-30deg) rotateX(90deg) rotateY(-10deg);
}

.box-example--active .card-icon--folded::after {
	opacity: 0;
	transform: translateX(-5rem) scaleX(3) scaleY(2.1) translateY(-1rem)
		translateZ(-2rem) rotateX(-5deg) rotateX(0deg) rotateY(0);
}

.card-icon--folded .card-icon__page-1,
.card-icon--folded .card-icon__page-2,
.card-icon--folded .card-icon__page-3 {
	display: block;
	overflow: hidden;
	will-change: transform, box-shadow;
}

.card-icon--folded .card-icon__page-1 {
	transform: translateX(-50%) translateZ(0) rotateX(-30deg) rotateY(-10deg);
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 0, 0, 0);
	z-index: 4;
}

.card-icon--folded .card-icon__page-2 {
	transform: translateX(-50%) translateZ(0) rotateX(-30deg) rotateY(170deg)
		translateX(-100%);
	filter: brightness(0.8);
	z-index: 3;
}

.card-icon--folded .card-icon__page-3 {
	filter: brightness(0.9);
	transform: translateX(-50%) translateZ(0) rotateX(-30deg) rotateY(20deg);
	z-index: 2;
}

.box-example--active .card-icon--folded .card-icon__page-1,
.box-example--active .card-icon--folded .card-icon__page-2,
.box-example--active .card-icon--folded .card-icon__page-3 {
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 10px rgba(0, 0, 0, 0.1);
}

.box-example--active .card-icon--folded .card-icon__page-1 {
	transform: translateX(0%) translateZ(-1rem) rotateX(-5deg) rotateY(-170deg);
}

.box-example--active .card-icon--folded .card-icon__page-2 {
	transform: translateX(0%) translateZ(-1rem) rotateX(-5deg) rotateY(10deg)
		translateX(-100%);
	filter: brightness(1);
}

.box-example--active .card-icon--folded .card-icon__page-3 {
	transform: translateX(0%) translateZ(-1rem) rotateX(-5deg) rotateY(-20deg);
	filter: brightness(1);
}
