.partners {
	text-align: center;
}

.partners .partners__heading:not(:last-child) {
	margin-bottom: 1rem;
}

.partners__list {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.partners__partner {
	text-decoration: none;
	position: relative;
	flex-grow: 0;
	margin: 1rem 2rem;
}

.partners__partner .partners__logo {
	display: block;
}

@media (width >= 992px) {
	.partners .partners__heading {
		margin-bottom: 2rem;
	}
}

/* Adjustments for different widths. This is dependent on size and order of the
   logos and will likely need adjustments if the logos are changed. */

@media (width < 992px) {
	.partners__partner {
		margin-left: 1.5rem;
		margin-right: 1.5rem;
	}
}

@media (width < 692px) {
	.partners__partner {
		margin-left: 1rem;
		margin-right: 1rem;
	}
}

@media (width < 660px) {
	.partners__list {
		justify-content: space-around;
	}

	.partners__partner {
		margin-left: 3vw;
		margin-right: 3vw;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.partners__partner > img {
		max-height: 7vw;
		width: auto;
	}
}

@media (min-width: 1100px) {
	.partners__partner {
		margin-left: 3.5rem;
		margin-right: 3.5rem;
	}
}
