/* Set box sizing */
* {
	box-sizing: border-box;
}
*::before,
*::after {
	box-sizing: inherit;
}

html {
	font-size: 20px; /* <- 1rem */
}

/* scroll-behavior and scroll-padding-top breaks automated acceptance tests */
html:not(.acceptance-test) {
	scroll-padding-top: calc(var(--header-height) + 1rem);
	scroll-behavior: smooth;
}

@media screen and (width >= 1800px) {
	html {
		font-size: 1.111vw;
	}
}

@media screen and (width >= 2071px) {
	html {
		font-size: 23px;
	}
}

body {
	margin: 0;
	overflow-x: hidden;
	width: 100vw;
}

a {
	color: var(--color-link);
}

a:active {
	color: var(--color-link--active);
}

/* Utility for hiding elements when the user doesn't have javascript active */
.no-js [data-hide-noscript] {
	display: none;
}

.js :focus {
	outline: 0;
}

.js.tabbed :focus {
	outline: 0.15rem solid var(--input-prominent-focus);
	outline-offset: 0.05rem;
	transition: none;
}

.u-block {
	display: block;
}
