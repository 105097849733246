.image {
	position: relative;
}

.image__image {
	position: relative;
	z-index: 2;
	display: block;
	width: 100%;
	height: auto;
}

.image__label {
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	z-index: 10;

	color: #fff;
	background-color: var(--color-blue);
	border-radius: 0.1rem;
	padding: 0.2rem 0.45rem 0.1rem;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 0.7rem;
	letter-spacing: 0.05rem;
	-webkit-font-smooth: antialiased;
}

.image__label--right {
	left: auto;
	right: 0.5rem;
}

@media (width < 576px) {
	.image__label {
		top: 0.3rem;
		left: 0.3rem;
		padding: 0.2rem 0.3rem 0.1rem;
		font-size: 0.6rem;
	}

	.image__label--right {
		left: auto;
	}
}

@media (width >= 576px) {
	.image__label--large {
		top: 0.95rem;
		left: 0.95rem;
		padding: 0.3rem 0.6rem 0.25rem;
	}

	.image__label--large.image__label--right {
		left: auto;
		right: 0.95rem;
	}
}

.image__caption {
	color: #fff;
	padding: 0.85rem 1.35rem 0.85rem;
}

@supports (object-fit: cover) {
	.image--cover {
	}

	.image--cover-3-4::before,
	.image--cover-4-3::before,
	.image--cover-16-9::before,
	.image--cover-1-1::before {
		content: '';
		display: block;
		height: 0;
		width: 100%;
	}
	.image--cover-4-3::before {
		padding-top: 75%;
	}
	.image--cover-16-9::before {
		padding-top: 56.25%;
	}
	.image--cover-1-1::before {
		padding-top: 100%;
	}

	.image--cover-3-4:before {
		padding-top: 133%;
	}

	.image--cover .image__image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.image--zoom {
	overflow: hidden;
}

.image--zoom img {
	transition: transform 800ms cubic-bezier(0.3, 0, 0.15, 1);
}

a.image-zoom:hover img,
a:hover .image--zoom img {
	transform: scale(1.03);
}

@media (width < 992px) {
	.image__caption {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

@media (width < 576px) {
	.image__caption {
		color: var(--color-gray);
		background-color: #fafafa;
		padding: 0.45rem 0.65rem;
	}
}

@media (width >= 576px) {
	.image__caption {
		background-image: linear-gradient(
			20deg,
			rgba(0, 0, 0, 0.75) 0%,
			rgba(0, 0, 0, 0.66) 10%,
			rgba(0, 0, 0, 0) 35%
		);
		box-sizing: content-box;

		font-size: 0.85rem;
		max-width: 14rem;
		padding-top: 6rem;
		padding-right: 8rem;

		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 8;
	}
}
