.breadcrumbs {
	display: flex;
	font-size: 0.8rem;
}

.breadcrumbs a:not(:last-child) {
	flex-shrink: 0;
}

.breadcrumbs a {
	text-decoration: none;
	overflow: hidden;
	text-overflow: ellipsis;
}

.breadcrumbs a:not(:first-child)::before {
	content: '';
	display: inline-block;
	width: 0.4rem;
	height: 0.6rem;
	vertical-align: -0.05rem;
	margin-left: 0.5rem;
	margin-right: 0.4rem;

	background-color: currentColor;
	mask-image: url('../../assets/arrow-small.svg');
	mask-size: cover;
}
