/**
 * Navigation menu for use in the fold-out navigation.
 */

.nav-menu {
	line-height: 1.1;
}

.nav-menu a {
	text-decoration: none;
}

/**
 * Group
 */

.nav-menu__group {
	border-bottom: 1px solid var(--color-thin-line);
	position: relative;
	z-index: 2;
	overflow: hidden;
	background-color: #edf3fd00;
	transition: background-color 250ms linear;
}

.nav-menu__group > .nav-menu__group {
	border-bottom: 0;
}

.nav-menu__group--open {
	transition-duration: 100ms;
	background-color: var(--color-light-blue);
	z-index: 1;
}

.nav-menu__group ul {
	padding-left: 0;
}

/* Second level */
.nav-menu__group .nav-menu__group {
	margin-top: -0.5rem;
	padding-bottom: 1.25rem;
}

.nav-menu__group .nav-menu__group .nav-menu__item > a {
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: calc(var(--page-margin) * 2);
}

/**
 * Item
 */

.nav-menu__item {
	display: flex;
}

.nav-menu__item > a {
	flex: 1;
	padding: 1.5rem var(--page-margin);
}

/**
 * Button
 */

.nav-menu__button {
	appearance: none;
	border: 0;
	margin: 0;
	background: transparent;
	cursor: pointer;

	padding: 1.2rem var(--page-margin);
	width: 4.75rem;

	position: relative;
	overflow: hidden;
	white-space: nowrap;
	text-indent: 10rem;
}

.nav-menu__button::after {
	content: '';
	width: 0.5rem;
	height: 1.1rem;
	background: url('../../assets/arrow.svg') center no-repeat;
	background-size: 0.5rem 1.1rem;
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translate(-50%, -50%) rotate(90deg);
	transform-origin: 50% 45%;
	transition: transform 200ms ease-out;
}

.nav-menu__group--open > .nav-menu__item > .nav-menu__button::after {
	transform: translate(-50%, -50%) rotate(-90deg);
}

@media (max-width: 767px) {
	.nav-menu__button {
		width: 1.25rem;
		box-sizing: content-box;
	}

	.nav-menu__button::after {
		right: 45%;
	}
}

.js.tabbed .nav-menu__item > a:focus,
.js.tabbed .nav-menu__button:focus {
	outline: 0;
	box-shadow: inset 0 0 0 0.05rem var(--input-prominent-focus-inner),
		inset 0 0 0 0.2rem var(--input-prominent-focus);
}
