.form-error,
.woocommerce-error,
.hf-message.hf-message-warning {
	color: var(--color-red);
	border: 1px solid var(--color-thin-line);
	background-color: var(--color-pale-red);
	border-radius: var(--input-border-radius);
	font-size: 0.8rem;
	padding: 0.6rem;
	margin-top: 1rem;
	text-align: left;
}

.form-error a,
.woocommerce-error a,
.hf-message.hf-message-warning a {
	color: var(--color-red);
}

.form-error::before,
.woocommerce-error li::before,
.hf-message.hf-message-warning::before {
	display: inline-block;
	content: '';
	background: url('../../../assets/invalid.svg');
	background-size: 2rem 2rem;
	background-position: center;
	width: 1rem;
	height: 1rem;
	margin-right: 0.4rem;
	vertical-align: -0.25rem;
}

/* Special selectors for WooCommerce */

.woocommerce-error {
	padding-left: 1rem;
	padding-right: 1rem;
}

.woocommerce-error li {
	display: block;
	margin-left: 0;
}
.woocommerce-error li + li {
	margin-top: 0;
}

.woocommerce-error li:not(:last-child) {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid var(--color-thin-line);
	margin-left: -1rem;
	padding-left: 1rem;
	margin-right: -1rem;
	padding-right: 1rem;
	padding-bottom: 0.6rem;
	margin-bottom: 0.6rem;
}
