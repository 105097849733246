/**
 * Comparison of packages
 */

/* Show desktop or mobile layout */

@media (width < 768px) {
	[data-business-packages] .packages--desktop {
		display: none;
	}
}
@media (width >= 768px) {
	[data-business-packages] .packages--mobile {
		display: none;
	}
}

.packages {
	padding-left: 0;
	display: flex;
	border-bottom: 1px solid var(--color-thin-line);
}

.packages:not(:last-child) {
	margin-bottom: 0;
}

.packages__package {
	display: block;
	width: 25%;
	border-top: 0.3rem solid var(--color-pale-green);
	position: relative;
	overflow: hidden;
}

.packages__package::before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	width: 0.7rem;
	height: 0.6rem;
	background-image: url('data:image/svg+xml;utf-8,<svg width="14" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M.19098-1.5h13.61804L7 12.11803.19098-1.5z" stroke="%23003798" fill="%23003798" stroke-linejoin="round"/></svg>');
	background-size: 0.7rem 0.6rem;
	background-repeat: no-repeat;

	transition: transform 150ms ease-out, opacity 150ms ease-out;
	transform: translateY(-0.7rem);
	opacity: 0;
}

.packages__package:not(:last-child) {
	border-right: 1px solid var(--color-thin-line);
}

.packages__heading {
	border: 0;
	border-bottom: 1px solid var(--color-thin-line);
	text-align: center;
	padding-top: 1rem;
	padding-bottom: 1.4rem;
}

/* Reset button styles */
button.packages__heading {
	cursor: pointer;
	width: 100%;
	color: inherit;
	background-color: #fff;
}

button.packages__heading:focus {
	outline: 0;
	box-shadow: inset 0 0 0 0.1rem var(--input-border-color--focus);
	border-color: var(--color-thin-line);
}

.packages__name {
	font-size: 1.5rem;
	margin-bottom: 0;
}

.packages__name:not(:first-child) {
	margin-top: 0.5rem;
}

.packages__list {
	padding-left: 0;
	font-size: 0.85rem;
	list-style: none;
}

.packages__list-item {
	display: block;
	padding: 0.6rem 1rem;
	padding-left: 1.5rem;
}

.packages__list-item::before {
	content: '';
	display: inline-block;
	width: 1.2rem;
	height: 0.8rem;
	background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 6H10" stroke="%23ccc"/></svg>');

	background-size: 0.8rem 0.8rem;
	background-repeat: no-repeat;
	background-position: top left;
	margin-left: -1.2rem;
	vertical-align: -0.2rem;
}

.packages__list-item--check::before {
	background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewbox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path stroke="%23178972" fill="none" d="M.5 5.5l4 4 7-7"/></svg>');
	animation: pop-in 350ms cubic-bezier(0.25, 2.2, 0.5, 1);
}

@keyframes pop-in {
	0% {
		transform: scale(0.5);
	}
	100% {
		transform: scale(1);
	}
}

/* Mobile version */

.packages--mobile {
	flex-direction: column;
}

.packages--mobile .packages__list-item:nth-child(odd) {
	background-color: #f7fdf7;
}

.packages--mobile .packages__list-item small {
	display: none;
}

.packages--mobile .packages__list-item--divider {
	position: relative;
	padding-right: 4.5rem;
}

.packages--mobile .packages__list-item--divider small {
	display: block;
	position: absolute;
	top: 0.5rem;
	right: 1rem;

	color: var(--color-green);
	font-size: 0.75rem;
	margin-left: 0.5rem;

	transition: inherit;
}

/* Alternate package styles: dim and highlight */

.packages__package {
	transition-property: color, border-top-color, box-shadow;
	transition-duration: 150ms;
	transition-timing-function: ease-out;
}

.packages__package--dim {
	color: rgba(0, 0, 0, 0.37);
}

.packages__package--highlight {
	border-top-color: var(--color-blue);
	box-shadow: var(--shadow-medium);
	position: relative;
	z-index: 2;
}

.packages__package--highlight:not(:last-child) {
	border-right: 1px solid rgba(0, 0, 0, 0.13);
}

.packages__package--highlight::before {
	transform: translateY(0);
	opacity: 1;
}

/* Alternate package list item styles: dim and highlight */

.packages__list-item {
	transition-property: color, border-color, background-color;
	transition-duration: 150ms;
	transition-timing-function: ease-out;
}

.packages__list-item--dim,
.packages__list-item--dim.packages__list-item--divider small {
	color: rgba(0, 0, 0, 0.37);
}

.packages--mobile .packages__list-item--dim:nth-child(odd) {
	background-color: #fff;
}

.packages--mobile .packages__list-item--dim {
	border-color: #e5e5e5;
}

.packages--mobile .packages__list-item--highlight + .packages__list-item--dim {
	border-color: #a7c7bf;
}

/* Dim checkmark */
.packages__package--dim .packages__list-item::before,
.packages__list-item--dim::before {
	background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 5.5H8.5" stroke="black" stroke-opacity="0.30"/></svg>');
}
