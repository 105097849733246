.christmas-2022 {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	padding-right: var(--page-margin);
	overflow: hidden;
}

.christmas-2022__inner {
	height: auto;
	display: block;
	width: 26rem;
	max-width: 90%;
	position: relative;
}

.christmas-2022__inner::before {
	content: '';
	display: block;
	width: 100%;
	height: 0;
	padding-top: 100%;
}

@media (max-width: 767px) {
	.christmas-2022 {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 1rem;
	}

	.christmas-2022__inner {
		max-width: 70%;
	}
}

.christmas-2022__before,
.christmas-2022__after,
.christmas-2022__heart {
	position: absolute;
	height: auto;
}

.christmas-2022__before {
	top: 21%;
	left: 0;
	width: 50%;
	transform: rotate(-2deg);
}

.christmas-2022__after {
	bottom: 0;
	right: 8%;
	width: 50%;
	transform: rotate(2deg);
}

.christmas-2022__heart {
	top: 0;
	right: 0;
	width: 70%;
}

.christmas-2022__card > img,
.christmas-2022__heart > img {
	display: block;
	width: 100%;
	height: auto;
}

.christmas-2022__heart > svg {
	animation: heartbeat 1500ms linear infinite;
}

@keyframes heartbeat {
	0% {
		transform: scale(1);
	}
	10% {
		transform: scale(1.06);
	}
	20% {
		transform: scale(1.03);
	}
	30% {
		transform: scale(1.05);
	}
	40% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}
