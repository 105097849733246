.cta {
	background-color: var(--color-beige);
	position: sticky;
	bottom: 0;
	z-index: 99;
	display: flex;
	justify-content: center;
	--padding-y: 1.5rem;
	padding: var(--padding-y) var(--page-margin)
		calc(var(--padding-y) + env(safe-area-inset-bottom));
	transition: transform 0.2s linear;
}

.cta--hide {
	transform: translateY(100%);
}

.cta__link {
	white-space: nowrap;
	background-color: var(--color-light-green);
	border-color: var(--color-light-green);
	color: var(--color-white);
	font-family: 'HCo Gotham', sans-serif;
	text-transform: uppercase;
	font-weight: 700;
	position: relative;
}

.cta__link:not(:disabled):hover {
	background-color: var(--color-light-green);
	border-color: var(--color-light-green);
}

.cta__link::before {
	content: '';
	display: inline-block;
	background-image: url('../../assets/heart-white.svg');
	background-repeat: no-repeat;
	height: 0.9rem;
	width: 0.9rem;
	vertical-align: -0.2rem;
	margin-right: 0.6rem;
}

.cta + .footer {
	margin-top: 0;
}

@media (max-width: 575px), (max-height: 625px) {
	.cta {
		--padding-y: 1rem;
	}
}
