.shortcuts {
	display: flex;
	gap: 0.5rem;
}

.shortcuts__shortcut {
	flex: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;

	border: 1px solid var(--color-thin-line);
	padding: 0.85rem 0.5rem 1.2rem;
	border-radius: 0.3rem;

	text-decoration: none;
	text-align: center;
	font-size: 0.8rem;
	white-space: nowrap;
}

.shortcuts__shortcut svg {
	margin-bottom: 0.15rem;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 400px) {
	.shortcuts {
		flex-direction: column;
	}

	.shortcuts__shortcut {
		flex-direction: row;
		padding-top: 0.3rem;
		padding-bottom: 0.3rem;
		gap: 0.4rem;
	}
}
