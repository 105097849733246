.social-section {
}

.social-section__icons {
	margin-top: 2.5rem;
}

.social-section__icon {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (width < 576px) {
	.social-section {
		--grid-row-margin: 1.5rem;
	}

	.social-section__icon .button {
		margin-top: 1rem;
	}
}

@media (width > 576px) {
	.social-section__icon .button {
		margin-top: 1.5rem;
	}
}
