/**
 * Page wrap
 *
 *  - Limit page width
 *  - Apply page padding
 *  - Center everything
 */

.page-wrap {
	width: 100%;
	max-width: var(--page-width);
	margin-left: auto;
	margin-right: auto;
	padding-left: var(--page-margin);
	padding-right: var(--page-margin);
}

.page-wrap--wide {
	max-width: 100%;
}

.page-wrap--narrow {
	max-width: 48rem;
}

@media (width >= 576px) and (width < 992px) {
	.page-wrap--md-narrow {
		max-width: 40rem;
	}
}

.page-wrap--center {
	max-width: 40rem;
	text-align: center;
}

/* Element inside .page-wrap that should extend to the edges of the screen */
.page-wrap__expand {
	display: block;
	margin-left: calc(var(--page-margin) * -1);
	width: 100vw;
	margin-top: 2rem;
}

img.page-wrap__expand {
	height: auto;
}

.page-wrap__expand--flush {
	margin-top: 0;
}

.page-card__content > .page-wrap__expand {
	width: 100%;
	padding-left: 0;
	padding-right: 0;
}

/**
 * Adjustment to make this work with Gutenberg blocks
 * https://github.com/OperationSmileSverige/Frontend/issues/17
 */
.page-intro--blank
	+ .page-wrap
	> .page-cardwrapper
	img.page-wrap__expand:first-child {
	margin-top: 1rem;
}

@media (width >= 1320px) {
	.page-wrap__expand {
		margin-left: calc(
			((var(--page-width) - var(--page-margin) * 2) - 100vw) / 2
		);
	}
}

@media (width <= 575px) {
	.page-wrap--full-mobile {
		padding-left: 0;
		padding-right: 0;
	}
}
