.foldable + .foldable {
	margin-top: 0.9rem;
}

.foldable__content {
}

.foldable__heading {
	color: var(--color-blue);
	background-color: #fff;
	margin: 0;
	font-size: 1.25rem;
	transition: background-color 200ms ease-out, border-radius 200ms ease-out,
		box-shadow 200ms ease-out;
	position: relative;
	border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
	border: 1px solid var(--color-thin-line);
}

.foldable__heading:not(:last-child) {
	margin-bottom: 0;
}

.foldable__heading,
.foldable__body {
	padding: 0.75rem 1.3rem 0.8rem;
}

.foldable__heading::after {
	content: '';
	width: 0.5rem;
	height: 1.1rem;
	background: url('../../assets/arrow.svg') center no-repeat;
	background-size: 0.5rem 1.1rem;
	position: absolute;
	top: 0.9rem;
	right: 1rem;
	transform: rotate(90deg);
	transform-origin: 50% 45%;
	transition: transform 200ms ease-out;
}

.no-js .foldable__heading::after {
	display: none;
}

.js .foldable__heading {
	cursor: pointer;
	padding-right: 2rem;
}

.js .foldable__heading:focus {
	outline: 0;
}

.js .foldable--expanded .foldable__heading {
	border-radius: 0.2rem 0.2rem 0 0;
}

.js.tabbed .foldable__heading:focus {
	box-shadow: var(--input-prominent-focus-inner) 0 0 0 0.05rem,
		var(--input-prominent-focus) 0 0 0 0.2rem;
}

.foldable__body {
	border-radius: 0 0 0.2rem 0.2rem;
	border: 1px solid var(--color-thin-line);
	border-top: 0;
}

/**
 * Expanded
 */

.foldable--expanded .foldable__heading,
.no-js .foldable .foldable__heading {
	background-color: var(--color-pale-green);
}

.foldable--expanded .foldable__heading::after {
	transform: rotate(-90deg);
}

.foldable--expanded .foldable__body {
	display: block;
}

/**
 * Mobile adjustments
 */

@media (width < 576px) {
	.foldable + .foldable {
		margin-top: 0.3rem;
	}

	.foldable__heading,
	.foldable__body {
		padding: 0.55rem 0.7rem 0.6rem;
	}

	.foldable__heading {
		font-size: 1rem;
	}

	.foldable__heading::after {
		top: 0.55rem;
	}
}
