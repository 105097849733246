.price {
	margin-bottom: 1rem; /* Apply margin because this is intended to be part of content flow */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.price__heading {
	display: block;
}

.price__heading:not(:last-child) {
	margin-bottom: 0;
}

.price__amount {
	font-size: 1.3rem;
}

.price__input {
	margin-top: 0.5rem;
}

.price__input > input {
	width: 6.5rem;
}

.price__input + .price__recommend {
	margin-top: 0.5rem;
}

.price .price__recommend {
	min-width: 5rem;
}

.price__arrow::before {
	left: 3.75rem;
}
