/**
 * Hides an element if Javascript is available on load. The data-hide-before-load
 * attribute is removed onload with javascript.
 */

.js [data-hide-before-load] {
	display: none;
}

/**
 * Hide data-hide-before-load elements within forms, when javascript is not active,
 * to only show the form elements within the first tab (radio-set).
 */
.no-js form [data-hide-before-load] {
	display: none;
}
