.support .support__heading {
	margin-bottom: 1rem;
}

.support__box {
	display: block;
	padding-bottom: 1.5rem;
}

a.support__box {
	color: inherit;
	text-decoration: inherit;
}

.support__box > *:not(.support__image-wrapper) {
	padding-left: 1rem;
	padding-right: 1rem;
}

.support__image-wrapper {
}

.support__image {
	width: 100%;
	height: auto;
}

.support__title {
	font-size: 1.25rem;
	font-weight: normal;
	margin-top: 1.25rem;
	margin-bottom: 0.75rem;
}

.support__content {
	text-align: center;
}

@media (width < 576px) {
	.support__description {
		display: none;
	}
}

@media (width < 992px) {
	.support__box {
		box-shadow: inset 0 0 0 1px var(--color-thin-line);
	}
}

@media (width >= 992px) {
	.support {
		text-align: center;
	}

	.support__box {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
	}

	.support .support__heading {
		margin-bottom: 2rem;
	}

	.support .support__heading--close {
		margin-bottom: 0.5rem;
	}

	.support__box--highlight {
		box-shadow: 0 3px 36px rgba(0, 0, 0, 0.16);
	}
}

@media (width >= 1200px) {
	.support__title {
		margin-top: 1.75rem;
	}
}

@supports (object-fit: cover) {
	.support__image-wrapper {
		height: 0;
		padding-top: 66%;
		position: relative;
	}

	.support__image-wrapper .support__image {
		display: block;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
	}

	@media (width < 576px) {
		.support__image-wrapper {
			padding-top: 73%;
		}
	}

	@media (width >= 992px) {
		.support__box--highlight .support__image-wrapper {
			padding-top: calc(66% + 1rem);
		}

		.support__box:not(.support__box--highlight) {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}

		.support__box--highlight {
			padding-bottom: 2rem;
		}
	}

	@media (width >= 1200px) {
		.support .support__box {
			padding-bottom: 2.25rem;
		}

		.support .support__box--highlight {
			padding-bottom: 3rem;
		}
	}

	@media (576px <= width < 992px) {
		.support__box > .support__image-wrapper {
			padding-top: 35%;
		}
	}

	@media (576px <= width < 768px) {
		.support__box > .support__image-wrapper {
			padding-top: 45%;
		}
	}
}

@media (576px <= width < 992px) {
	.support__box {
		display: flex;
		align-items: center;
		padding-bottom: 0;
	}

	.support__image-wrapper,
	.support__content {
		flex: 1 1 50%;
	}

	.support__content {
		margin-bottom: 1.5rem;
	}
}
