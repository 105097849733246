@use 'sass:math';

.grid {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin-bottom: calc(var(--grid-row-margin) * -1);
}

.grid--increase-row-spacing {
	--grid-row-margin: 2rem;
}

.grid--debug {
	background-color: #eee;
}

.grid--debug .grid__col {
	background-color: #ffc;
}

.grid--debug .grid__col .grid__col {
	background-color: #fcc;
}

.grid--debug .grid__col .grid__col .grid__col {
	background-color: #cff;
}

.grid__col {
	margin-bottom: var(--grid-row-margin);
	width: 100%;
}

.grid--flush:not(.grid__col) {
	margin-bottom: unset;
}

.grid--flush > * {
	--grid-row-margin: 0;
}

.grid--align-top {
	align-items: flex-start;
}

.grid--align-center {
	align-items: center;
}

.grid--align-bottom {
	align-items: flex-end;
}

.grid__col--align-top {
	align-self: flex-start;
}

.grid__col--align-center {
	align-self: center;
}

.grid__col--align-bottom {
	align-self: flex-end;
}

.grid__col--padded {
	padding-left: 1rem;
	padding-right: 1rem;
}

$maxcolumns: 5;
$mediaqueries: (
	'sm': '(min-width: 576px)',
	'md': '(min-width: 768px)',
	'lg': '(min-width: 992px)',
	'xl': '(min-width: 1200px)',
);

@function col-gutter($span, $columns) {
	@return 'var(--grid-gutter) * #{math.div($columns - $span, $columns)}';
}

@function col-width($span, $columns) {
	@return 'calc(#{math.div($span, $columns) * 100%} - #{col-gutter($span, $columns)})';
}

@mixin make-grid($columns, $key: '') {
	@if $key != '' {
		$key: '#{$key}-';
	}

	@for $i from 1 through $columns - 1 {
		.grid__col--#{$key}#{$i}-#{$columns} {
			width: #{col-width($i, $columns)};
		}
	}

	.grid__col--#{$key}#{$columns}-#{$columns} {
		width: 100%;
	}

	@if $key != '' {
		.grid__col--#{$key}1-1 {
			width: 100%;
		}

		.grid__col--#{$key}align-top {
			align-self: flex-start;
		}

		.grid__col--#{$key}align-center {
			align-self: center;
		}

		.grid__col--#{$key}align-bottom {
			align-self: flex-end;
		}

		.grid__col--#{$key}padded {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		@for $i from 1 through 10 {
			.grid__col--#{$key}order-#{$i} {
				order: $i;
			}
		}
	}
}

@mixin make-mq-grid($columns, $key, $mq) {
	@media #{$mq} {
		@include make-grid($columns, $key);
	}
}

@for $i from 2 through $maxcolumns {
	@include make-grid($i);
}

@each $key, $mq in $mediaqueries {
	@for $i from 2 through $maxcolumns {
		@include make-mq-grid($i, $key, $mq);
	}
}