.overlapped-images {
	width: 95%;
	max-width: 30rem;
	margin-left: auto;
	margin-right: auto;
}

.overlapped-image__container {
	position: relative;
	height: 0;
	padding-top: 84%;
}

.overlapped-images__image {
	width: 54%;
	height: auto;
}

.overlapped-images__image > img,
.overlapped-images__image > noscript > img {
	width: 100%;
	height: auto;
}

.overlapped-images__image:first-child {
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 20;
}

.overlapped-images__image:last-child {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 30;
}

.overlapped-images__image--shadowed {
	box-shadow: 0 0.15rem 1.5rem rgba(3, 0, 35, 0.35);
}

@media (width < 992px) {
	.overlapped-images {
		width: 75%;
		max-width: 24rem;
	}
}

@media (width < 576px) {
	.overlapped-image__container {
		padding-top: 81%;
	}
}
