/**
 * Scrolls sideways on mobile
 */

@media (width < 576px) {
	.scrollable {
		overflow-x: auto;
		overflow-y: hidden;
		margin-left: calc(var(--page-margin) * -1);
		margin-right: calc(var(--page-margin) * -1);
		padding-left: var(--page-margin);
		padding-right: var(--page-margin);
		padding-bottom: 0.65rem;
		-webkit-overflow-scrolling: touch;
		scroll-snap-type: x mandatory;
		scroll-padding: var(--page-margin);
	}

	.scrollable__slider {
		display: flex;
		flex-wrap: nowrap;
		width: fit-content;
	}

	.scrollable__item {
		vertical-align: top;
		margin-right: var(--grid-gutter);
		width: 66vw;
		min-width: 10.5rem;
		flex-shrink: 0;
		scroll-snap-align: start;
	}

	.scrollable__item--small {
		width: 50vw;
	}

	.scrollable__item--large {
		width: 82vw;
	}

	.scrollable__item > * {
		white-space: normal;
	}
}
