.cart {
}

.cart__section {
	position: relative;
}

.cart__section:not(:first-child) {
	padding-top: calc(var(--page-margin) * 0.5);
	border-top: 1px solid var(--color-thin-line);
}

.cart__section:not(:last-child) {
	padding-bottom: calc(var(--page-margin) * 0.5);
}

.cart__section--sum {
	display: flex;
}

.cart__sum-label {
	min-width: calc((100% - var(--page-content-width)) / 2);
	padding-right: 0.5rem;
}

.cart__sum {
	flex: 1;
}

@media (width < 1200px) {
	.cart__section--product {
		border-top: 1px solid var(--color-thin-line);
	}
}

/* non-standard breakpoint to accomodate action button width in cart-product */
@media (width < 860px) {
	.cart__section--sum {
		flex-direction: column;
	}

	.cart__sum-label {
		font-size: 0.75rem;
		font-weight: 600;
		letter-spacing: 0.03rem;
		text-transform: uppercase;
		margin-bottom: 0.25rem;
	}
}
@media (width < 768px) {
	.cart__section:not(:first-child) {
		padding-top: var(--page-margin);
	}

	.cart__section:not(:last-child) {
		padding-bottom: var(--page-margin);
	}
}
