.christmas-campaign {
	color: var(--color-beige);
	background-color: var(--color-christmas-2023);
	position: relative;
}

.page-section + .page-section.christmas-campaign {
	margin-top: 2rem;
}

.page-section.christmas-campaign + .page-navigation {
	margin-top: 0;
}

.christmas-campaign__inner {
	display: flex;
	align-items: center;
}

.christmas-campaign .button {
	color: #fff;
	background-color: var(--color-blue);
	border-color: var(--color-blue);
}

.christmas-campaign .button:hover {
	color: #fff;
	background-color: var(--color-blue);
	border-color: var(--color-blue);
}

.christmas-campaign__content,
.christmas-campaign__image {
	position: relative;
	z-index: 2;
}

@media (width >= 768px) {
	.christmas-campaign__inner {
		flex-direction: row-reverse;
	}

	.christmas-campaign__image {
		flex: 1;
		width: 45%;
		padding-right: 2rem;
		padding-bottom: 1rem;
	}

	.christmas-campaign__heading {
		font-size: 3.75rem;
		font-weight: normal;
		line-height: 0.95;
	}

	.christmas-campaign__content {
		padding: 2rem 3rem;
		max-width: 34rem;
	}
}

@media (width >= 768px) and (width < 1280px) {
	.christmas-campaign__heading {
		font-size: 5.859375vw;
	}

	.christmas-campaign__content {
		width: 60%;
	}
}

@media (width < 768px) {
	.christmas-campaign__inner {
		flex-direction: column;
	}

	.christmas-campaign__image {
		width: 95%;
		padding: 0.5rem var(--page-margin) 0;
	}

	.christmas-campaign__heading {
		width: 100%;
		max-width: 10em;
		margin-left: auto;
		margin-right: auto;
	}

	.christmas-campaign__content {
		padding-left: var(--page-margin);
		padding-right: var(--page-margin);

		text-align: center;
	}

	.christmas-campaign__button-set {
		justify-content: center;
		width: 100%;
	}
}
