.contact-form {
	border-radius: 4px;
	border: 1px solid var(--input-border-color);
	display: flex;
}

.contact-form__form {
	padding: 3rem 4rem;
}

.contact-form__contact {
	background-color: var(--color-pale-blue);
	display: flex;
	align-items: center;
	padding: 1.7rem 2rem 1.4rem;
}

.contact-form__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	font-size: 0.9rem;
}

.contact-form__image {
	height: 8.75rem;
	width: 8.75rem;
	display: block;
	object-fit: cover;
	border-radius: 50%;
	margin-bottom: 2rem;
}

.contact-form__contact-details {
	display: flex;
	flex-direction: column;
}

.contact-form__name {
	font-weight: 700;
	font-size: 1rem;
}

.contact-form__name:not(:last-child) {
	margin-bottom: 0.3rem;
}

.contact-form__title:not(:last-child) {
	margin-bottom: 0;
}

.contact-form__phone {
	text-decoration: none;
	color: #000;
}

.contact-form__form .hf-message.hf-message-success {
	color: var(--color-text);
	border: 1px solid var(--color-thin-line);
	background-color: var(--color-pale-blue);
	border-radius: var(--input-border-radius);
	font-size: 0.8rem;
	padding: 0.6rem;
	margin-top: 1rem;
}

@media (max-width: 900px) {
	.contact-form {
		display: flex;
		flex-direction: column;
	}

	.contact-form__form {
		padding: 1.5rem;
	}

	.contact-form__contact {
		padding: 1.7rem 1.5rem 1.4rem;
	}

	.contact-form__content {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		text-align: left;
	}

	.contact-form__image {
		height: 4.4rem;
		width: 4.4rem;
		margin-bottom: 0;
		margin-right: 0.8rem;
	}
}

@media (max-width: 460px) {
	.contact-form__email {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 7.5rem;
	}
}

@media (max-width: 375px) {
	.contact-form__form {
		padding: 0.5rem;
	}
}
