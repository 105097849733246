.amount {
	position: relative;
	display: inline-block;
}

.amount .amount__input.u-show-validation:valid,
.amount .amount__input.u-show-validation:invalid {
	background-image: none;
}

.amount__input,
.amount__currency {
	/* Align baseline */
	line-height: 1.5rem;
}

.amount__input {
	font-size: 1.25rem;
	padding-right: 2.4rem;
	width: 100%;
}

.amount__input::-webkit-outer-spin-button,
.amount__input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.amount__input[type='number'] {
	-moz-appearance: textfield;
}

.amount__currency {
	border-left: 1px solid var(--input-border-color);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	padding: var(--input-padding-y) 0.7rem var(--input-padding-y) 0.6rem;
	transition: border-color 100ms ease-out;
	font-size: 1rem;
	user-select: none;
	pointer-events: none;
}

.amount__input:focus + .amount__currency {
	border-color: var(--input-border-color--focus);
}

.amount__input:hover + .amount__currency {
	border-color: var(--input-border-color--hover);
}

.amount.amount--valid::after,
.amount.amount--invalid::after {
	content: '';
	width: 2.2rem;
	height: 2.2rem;
	position: absolute;
	top: 0.2rem;
	right: 2.2rem;
	pointer-events: none;
}

.amount.amount--valid::after {
	background-image: url('../../../assets/valid.svg');
}

.amount.amount--invalid::after {
	background-image: url('../../../assets/invalid.svg');
}

.amount.amount--valid .amount__input {
	background-color: var(--input-background-color--valid);
}

.amount.amount--valid .amount__input:hover {
	background-color: var(--input-background-color--valid-hover);
}

.amount.amount--valid .amount__input,
.amount.amount--valid .amount__currency {
	border-color: var(--input-border-color--valid);
}

.amount.amount--invalid .amount__input {
	background-color: var(--input-background-color--invalid);
}

.amount.amount--invalid .amount__input:hover {
	background-color: var(--input-background-color--invalid-hover);
}

.amount.amount--invalid .amount__input,
.amount.amount--invalid .amount__currency {
	border-color: var(--input-border-color--invalid);
}

/**
 * Hover
 */

.amount.amount--valid .amount__input:hover {
	border-color: var(--input-border-color--valid-hover);
}

.amount.amount--valid .amount__input:hover + .amount__currency {
	border-color: var(--input-border-color--valid-hover);
}

.amount.amount--invalid .amount__input:hover + .amount__currency {
	border-color: var(--input-border-color--invalid-hover);
}
