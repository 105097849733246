/**
 * Break section within an article, for use with .fullwidth and .grid
 */

.article-break,
.fullwidth.article-break {
	background-color: var(--color-pale-green);
}

.article-break:not(:first-child),
.fullwidth.article-break:not(:first-child) {
	margin-top: 3rem;
}

.article-break:not(:last-child),
.fullwidth.article-break:not(:last-child) {
	margin-bottom: 3rem;
}

.article-break__image {
	margin-top: var(--page-overlap-y);
}

@media (width < 768px) {
	.article-break__content {
		padding-left: 1rem;
	}

	.article-break__image {
		margin-bottom: var(--page-overlap-y);
	}
}

@media (width >= 768px) {
	.article-break__image {
		width: calc(100% - 2rem);
	}
}

@media (width < 1200px) {
	.article-break__content {
		padding-right: 1rem;
	}
}

@media (width >= 1200px) {
	.article-break__content {
		padding-right: 5rem;
	}
}

.article-break--inner-spacing,
.fullwidth.article-break--inner-spacing {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.article-break--inner-spacing-bottom,
.fullwidth.article-break--inner-spacing-bottom {
	margin-top: 0;
	margin-bottom: 0;
	padding-bottom: 2rem;
}
