.signature {
	display: flex;
}

.signature__image {
	flex-shrink: 0;
	height: 6.55rem;
	width: 6.55rem;
	margin-right: 1.5rem;
}

.signature__image img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.signature__content {
	display: flex;
	flex-direction: column;
	justify-content: end;
}

.signature__signature {
	height: 2.5rem;
	width: auto;
}

.signature__signature img {
	height: 100%;
	width: 12rem;
	max-width: 100%;
	object-fit: contain;
	object-position: top left;
}

.signature__text p:not(:last-child) {
	margin-bottom: 0;
}

.content > .signature:not(:first-child) {
	margin-top: 1.5rem;
}

.content > .signature:not(:last-child) {
	margin-bottom: 1.5rem;
}
