/**
 * The bank selector is mostly build with the grid and radio-set classes.
 * The bank-selector classes apply some small overrides of these.
 */

.bank-selector {
}

.bank-selector__logo {
	max-width: 100%;
	height: auto;
}

.bank-selector.grid {
	--grid-row-margin: 0.45rem;
}

@media (width < 576px) {
	.bank-selector {
		--grid-gutter: 0.5rem;
	}
}
