.confirm {
	display: flex;
	align-items: flex-start;
	margin-bottom: 1rem;
	text-align: left;
}

.confirm__input {
	flex-shrink: 0;
	flex-grow: 0;
	margin-top: 0.1rem;
}

input.confirm__input {
	margin-right: 0.5rem;
}

/* When following a fieldset, remove some top margin */
@media (width >= 768px) {
	fieldset + .confirm {
		margin-top: -1rem;
	}
}

.confirm__label a:not(:hover) {
	text-decoration: underline;
}
