.page-navigation:not(.page-navigation--no-background) {
	background-color: var(--color-pale-blue);
	padding-top: 2rem;
	padding-bottom: calc(2rem + var(--page-overlap-y) * -1);
}

.page-navigation__inner {
	display: flex;
	gap: var(--grid-gutter);
}

.page-navigation__inner > * {
	flex: 1;
	flex-shrink: 0;
}

@media (width < 768px) {
	.page-navigation__inner {
		flex-direction: column;
	}
}

.page-navigation__heading {
	text-align: center;
}

.page-navigation__heading:not(:last-child) {
	margin-bottom: 1.5rem;
}

.page-navigation__header {
	/* should be used together with page-wrap */
	text-align: center;
	max-width: 46rem;
}

.page-navigation__header:not(:last-child) {
	margin-bottom: 1.5rem;
}

.page-navigation__actions {
	text-align: center;
}

.page-navigation__actions:not(:first-child) {
	margin-top: 1.75rem;
}
