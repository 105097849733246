.checkmark-list {
	font-size: 0.85rem;
	list-style: none;
}

:not(.content) > .checkmark-list {
	padding-left: 0;
}

.checkmark-list li + li {
	margin-top: 0; /* reset margin from typography.css */
}

.checkmark-list__item {
	display: block;
	padding: 0;
	padding-left: 1.05rem;
	white-space: normal;
}

/* To overwrite .content li */
.checkmark-list .checkmark-list__item {
	margin-left: 0;
}

.checkmark-list__item:not(:last-child) {
	margin-bottom: 0.25rem;
}

.checkmark-list__item::before {
	content: '';
	display: inline-block;
	width: 1rem;
	height: 0.8rem;
	background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.333 8L6 12.667 14.667 4" stroke="%23178972" stroke-width="1.714"/></svg>');
	background-size: 0.8rem 0.8rem;
	background-repeat: no-repeat;
	background-position: center left;
	margin-left: -1rem;
	margin-bottom: -0.1rem;
}
