.custom-amount {
	display: flex;
	justify-content: end;
	margin-top: 0.35rem;
	margin-bottom: var(--form-spacing-y);
}

.custom-amount__amount {
	width: 100%;
}

@media (min-width: 440px) {
	.custom-amount__amount {
		width: calc(50% - var(--form-spacing-x) * 2 / 4);
	}
}

@media (min-width: 1200px) {
	.custom-amount__amount {
		width: calc(25% - var(--form-spacing-x) * 3 / 4);
	}
}
