.tabbed-area {
	--outer-border: 1px solid var(--color-thin-line-solid);
	--outer-radius: var(--input-border-radius);
}

.tabbed-area--in-step {
	--outer-border: 0;
	--outer-radius: 0;
}

/**
 * Tabs
 */

.tabbed-area__tabs {
	display: flex;
	background-color: var(--color-light-blue);
	white-space: nowrap;
	--tab-bottom-shadow: inset var(--color-thin-line-solid) 0 -1px 0;
	box-shadow: var(--tab-bottom-shadow);

	border: var(--outer-border);
	border-bottom: 0;
	border-top-left-radius: var(--outer-radius);
	border-top-right-radius: var(--outer-radius);
	overflow-x: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabbed-area__tabs::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tabbed-area__tabs {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.tabbed-area--in-step .tabbed-area__tabs:not(:first-child) {
	border-top: 1px solid var(--color-thin-line-solid);
}

.tabbed-area__tab {
	padding: 1rem;
	padding-right: 1.2rem;
	transition-property: border-color, background-color;
	transition-duration: 100ms, 150ms;
	transition-timing-function: ease-out;
	color: var(--color-blue);
	position: relative;
	z-index: 1;
}

.tabbed-area__tab:first-child {
	border-top-left-radius: calc(var(--outer-radius) - 0.05rem);
}

.tabbed-area__tab {
	box-shadow: -1px 0 0 var(--color-thin-line-solid);
}

.tabbed-area__tab--active:last-child {
	box-shadow: -1px 0 0 var(--color-thin-line-solid),
		1px 0 0 var(--color-thin-line-solid), #fff 0 1px 0;
}

.tabbed-area__tab:not(:first-child) {
	margin-left: 1px;
}

.tabbed-area__tab--active {
	background-color: #fff;
	box-shadow: -1px 0 0 var(--color-thin-line-solid), #fff 0 1px 0;
}

.tabbed-area__tab:not(.tabbed-area__tab--active):hover {
	background-color: var(--input-background-color--hover);
	box-shadow: 0 0 0 1px var(--input-border-color--hover),
		var(--tab-bottom-shadow);
	z-index: 2;
}

/**
 * Content
 */

.tabbed-area__content {
	padding: 1rem;
	overflow: hidden;
	border-top: var(--outer-border);
	border-left: var(--outer-border);
	border-right: var(--outer-border);
	border-top-left-radius: var(--outer-radius);
	border-top-right-radius: var(--outer-radius);
}

.tabbed-area__tabs + .tabbed-area__content {
	border-top: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.tabbed-area__content:last-child {
	border-bottom: var(--outer-border);
	border-bottom-left-radius: var(--outer-radius);
	border-bottom-right-radius: var(--outer-radius);
}

/**
 * Content section
 */

.tabbed-area__content-section {
	margin: -1rem;
	padding: 1rem;
	width: calc(100% + 2rem);
}

.tabbed-area__content-section.grid {
	margin-bottom: 0;
	padding-bottom: 0;
}

.tabbed-area__content-section.grid:last-child {
	/* Reset negative margin-bottom required by the grid for proper spacing */
	margin-bottom: calc(var(--grid-row-margin) * -1);
}

.tabbed-area__content-section + .tabbed-area__content-section {
	margin-top: 0;
	border-top: 1px solid var(--color-thin-line-solid);
}

.tabbed-area__content-section:not(:last-child) {
	margin-bottom: 0;
}

.tabbed-area__content-section--alternate {
	background-color: var(--color-pale-blue);
}

@media (width < 576px) {
	.tabbed-area__tabs {
		font-size: 0.75rem;
	}

	.tabbed-area__tab {
		font-size: 0.85rem;
		padding: 0.6rem 0.4rem;
	}

	.tabbed-area__tab input[type='checkbox'],
	.tabbed-area__tab input[type='radio'] {
		margin-right: 0.2rem;
		width: 0.65rem;
		height: 0.65rem;
		vertical-align: -0.1rem;
		background-size: 0.25rem 0.25rem;
	}

	.tabbed-area__content {
		padding: 0.5rem;
		--grid-row-margin: 0.45rem;
		--grid-gutter: 0.5rem;
	}

	.tabbed-area__content-section {
		margin: -0.5rem;
		padding: 0.5rem;
		width: calc(100% + 1rem);
	}
}
@media (width < 470px) {
	.tabbed-area__tab {
		font-size: 0.8rem;
	}
}

@media (width < 440px) {
	.tabbed-area__tab {
		font-size: 0.75rem;
	}
}

@media (width < 400px) {
	.tabbed-area__tab {
		flex: 1;
		font-size: 0.7rem;
	}

	.tabbed-area__tab:last-child {
		border-top-right-radius: calc(var(--input-border-radius) - 0.05rem);
	}
}

/* Change labels for small screens if needed */
@media (width >= 400px) {
	.tabbed-area__tab-label--lt-400 {
		display: none;
	}
}

@media (width < 400px) {
	.tabbed-area__tab-label--gte-400 {
		display: none;
	}
}
