.notification {
	background-color: var(--color-green);
	color: #fff;
	padding: 1rem;
	border-radius: var(--input-border-radius);
	width: 13.6rem;
}

.notification--fixed {
	position: fixed;
	right: 1rem;
	bottom: 0;
	z-index: 90;
	border-radius: var(--input-border-radius) var(--input-border-radius) 0 0;
	box-shadow: var(--shadow-medium);
}

.notification__symbol {
	float: right;
	width: 1.1rem;
	height: 1.1rem;
	margin-top: -0.05rem;
}

/* The additional specificity is needed to override h1 margin */
.notification .notification__heading {
	margin-bottom: 0.75rem;
	border-color: rgba(255, 255, 255, 0.67);
}
